import { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BeatLoader } from "react-spinners";

import Select from "../components/Select";
import { generateAndDownloadFile } from "../utils/downloadUtils";
import { toast } from "react-toastify";

export default function DownloadDetectionsDialog({ open, setOpen, uploadDetails, allImagesDetectionDetails }) {
  const classes = useStyles();

  const [selectedFileFormat, setSelectedFileFormat] = useState("");
  const [pagesString, setPagesString] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProcessingStatus, setDownloadProcessingStatus] = useState("");

  const downloadButtonOnClick = async (event) => {
    if (selectedFileFormat === "") {
      toast("Please select a file format.");
      return;
    }

    setIsDownloading(true);

    const f = generateAndDownloadFile(
      uploadDetails?.id,
      uploadDetails?.filename.split(".")[0],
      allImagesDetectionDetails,
      pagesString,
      selectedFileFormat,
      setDownloadProcessingStatus,
      (error) => {
        setDownloadProcessingStatus("");
        toast(error);
      }
    );

    setDownloadProcessingStatus("Finalizing...");

    setTimeout(() => {
      setIsDownloading(false);
      setDownloadProcessingStatus("");
    }, 2000);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        if (isDownloading) {
          return;
        }

        setOpen(false);
      }}
    >
      <DialogTitle>Configure Download</DialogTitle>

      <DialogContent>
        <Box
          width="100%"
          maxWidth="md"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <Box width="30%">
            <Typography variant="h6">Pages:</Typography>
          </Box>

          <TextField
            className={classes.dialogInputTextField}
            type="text"
            value={pagesString}
            onChange={(event) => setPagesString(event.target.value)}
          />
        </Box>

        <Box
          width="100%"
          maxWidth="md"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box width="30%">
            <Typography variant="h6">File Format:</Typography>
          </Box>

          <Box width="50%">
            <Select
              selectedValue={selectedFileFormat}
              setSelectedValue={setSelectedFileFormat}
              allValues={downloadFileFormatOptions}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Box height={100}>
          <Box height={50} mb={2}>
            {downloadProcessingStatus}
          </Box>

          <Button variant="contained" color="primary" onClick={downloadButtonOnClick} disabled={isDownloading}>
            {isDownloading ? <BeatLoader color="blue" loading={true} size={14} /> : "Download"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const downloadFileFormatOptions = [".txt", ".json", ".pdf (Transparent Text)", ".pdf (Overlayed Text)"];

const useStyles = makeStyles((theme) => ({
  dialogInputTextField: {
    width: "50%",
  },
  dialogActions: {
    "&&": {
      justifyContent: "center",
    },
  },
}));
