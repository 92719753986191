import { Tooltip } from "@mui/material";

export default function MuiButtonWithTooltip({
  tooltipTitle,
  buttonComponent,
}) {
  return (
    <Tooltip title={tooltipTitle}>
      <span>{buttonComponent}</span>
    </Tooltip>
  );
}
