import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Box, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

export default function UserManual() {
  const classes = useStyles();

  const [isExpandAllEnabled, setIsExpandAllEnabled] = useState(false);
  const [isCollapseAllEnabled, setIsCollapseAllEnabled] = useState(true);
  const [sectionsExpanded, setSectionsExpanded] = useState({
    registration: false,
    loggingIn: false,
    uploadingANewFileForOCR: false,
    theHistoryPage: false,
    mergingUploads: false,
    viewingAndEditingOCRResults: false,
    downloadingOCRResults: false,
    applicationSettings: false,
    resettingYourPassword: false,
  });

  const handleExpandAllButtonClick = (event) => {
    setSectionsExpanded({
      registration: true,
      loggingIn: true,
      uploadingANewFileForOCR: true,
      theHistoryPage: true,
      mergingUploads: true,
      viewingAndEditingOCRResults: true,
      downloadingOCRResults: true,
      applicationSettings: true,
      resettingYourPassword: true,
    });
  };

  const handleCollapseAllButtonClick = (event) => {
    setSectionsExpanded({
      registration: false,
      loggingIn: false,
      uploadingANewFileForOCR: false,
      theHistoryPage: false,
      mergingUploads: false,
      viewingAndEditingOCRResults: false,
      downloadingOCRResults: false,
      applicationSettings: false,
      resettingYourPassword: false,
    });
  };

  useEffect(() => {
    document.title = "Lipikar - User Manual";
  }, []);

  useEffect(() => {
    let numExpandedSections = 0;
    const numTotalSections = Object.keys(sectionsExpanded).length;

    for (const key in sectionsExpanded) {
      numExpandedSections += sectionsExpanded[key];
    }

    setIsExpandAllEnabled(numExpandedSections < numTotalSections);
    setIsCollapseAllEnabled(numExpandedSections > 0);
  }, [sectionsExpanded]);

  return (
    <div className={classes.pageContainer}>
      <Container className={classes.container}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
          <Typography variant="h3" className={classes.heading} style={{ fontWeight: "bold", textAlign: "center" }}>
            User Manual
          </Typography>
        </Box>

        <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mb={3}>
          <Box mr={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExpandAllButtonClick}
              disabled={!isExpandAllEnabled}
            >
              Expand All
            </Button>
          </Box>

          <Box mr={2}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCollapseAllButtonClick}
              disabled={!isCollapseAllEnabled}
            >
              Collapse All
            </Button>
          </Box>
        </Box>

        <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.registration}
            onChange={(event, expanded) => {
              const updatedSectionsExpanded = { ...sectionsExpanded };
              updatedSectionsExpanded.registration = expanded;
              setSectionsExpanded(updatedSectionsExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Registration</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>The Registration Page looks like the following:</Typography>
              <img src="/static/1. Registration.png" className={classes.contentImage} />
              <Typography component={Link} to={"/auth/register"}>
                Go to the Registration Page.
              </Typography>
              <Typography>
                To register a new account, please provide the required details and click the "Register" button.
                <br />
                If the registration was successful, you will be automatically redirected to the Login Page in about 5
                seconds. A toast will appear at the top left to notify you of the successful registration.
                <br />
                If there was an error in the registration process, a toast will display the error at the top left.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.loggingIn}
            onChange={(event, expanded) => {
              const updatedSectionsExpanded = { ...sectionsExpanded };
              updatedSectionsExpanded.loggingIn = expanded;
              setSectionsExpanded(updatedSectionsExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Logging In</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>The Login Page looks like the following:</Typography>
              <img src="/static/2. Login.png" className={classes.contentImage} />
              <Typography component={Link} to={"/auth/login"}>
                Go to the Login Page.
              </Typography>
              <Typography>
                To log into your account, please enter the required credentials and click the "Login" button.
                <br />
                If the login is successful, you will be redirected to the New Upload Page.
                <br />
                If there was an error in the login process, a toast will display the error at the top left.
                <br />
                Newly registered accounts do not have Login permission by default. This permission is authorized by an
                admin manually after the Registration process. If you do not have Login permission, you will see an
                error in the top left that notifies of this.
                <br />
                To obtain login permission, please contact an admin.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.uploadingANewFileForOCR}
            onChange={(event, expanded) => {
              const updatedSectionsExpanded = { ...sectionsExpanded };
              updatedSectionsExpanded.uploadingANewFileForOCR = expanded;
              setSectionsExpanded(updatedSectionsExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Uploading a New File for OCR</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>The New Upload Page looks like the following:</Typography>
              <img src="/static/3. New Upload.png" className={classes.contentImage} />
              <Typography component={Link} to={"/upload-new"}>
                Go to the New Upload Page.
              </Typography>
              <Typography>
                By default, new accounts do not have Compute permission. If this is the case with your account, you will
                see a red text on the screen that notifies you of this. Compute permissions are authorized by admins. To
                obtain Compute permission, please contact an admin.
                <br />
                To Upload a new file for OCR, select the file by clicking on the "No File Chosen" text or drag and drop
                a file into the box.
                <br />
                The accepted file formats are jpg, jpeg, and pdf.
                <br />
                Then, select the required Language and Parser and click the "Go!" button.
                <br />
                The newly generated OCR request will get added to the OCR queue. The processing progress can be viewed
                from the History Page.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.theHistoryPage}
            onChange={(event, expanded) => {
              const updatedSectionsExpanded = { ...sectionsExpanded };
              updatedSectionsExpanded.theHistoryPage = expanded;
              setSectionsExpanded(updatedSectionsExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">The History Page</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>The History Page looks like the following:</Typography>
              <img src="/static/4. History.png" className={classes.contentImage} />
              <Typography component={Link} to={"/history/1"}>
                Go to the History Page.
              </Typography>
              <Typography>
                The History Page shows all of your uploads, including the uploads that are currently being processed.
                <br />
                To view or edit the OCR results of an upload, click on the Date and Time of the upload.
                <br />
                To view the uploaded file of an upload, click on the filename.
                <br />
                The filename can be changed by clicking on the Pen Icon next to the filename.
                <br />
                The uploads being displayed can be sorted by each of the fields by clicking on the Sort Icon next to a
                particular field name.
                <br />
                To refresh the displayed list of uploads, click the Refresh Icon above the list.
                <br />
                The History Page also offers 2 other functions: Delete and Merge Uploads.
                <br />
                To delete one or more selected uploads, press the Trash Can Icon.
                <br />
                To merge two or more selected uploads, press the Merge Icon. More on this in a later section.
                <br />
                History page also allows the user to create a dataset by selecting the files and “export” (at top right
                corner).
                <br />
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.mergingUploads}
            onChange={(event, expanded) => {
              const updatedSectionsExpanded = { ...sectionsExpanded };
              updatedSectionsExpanded.mergingUploads = expanded;
              setSectionsExpanded(updatedSectionsExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Merging Uploads</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                After selecting two or more uploads and clicking on the Merge Icon, a new dialog box will open which
                will look like this:
              </Typography>
              <img src="/static/5. Merge.png" className={classes.contentImage} />
              <Typography>
                To provide a name for the Merged Uploads, use the Text Box at the top.
                <br />
                All the initially selected uploads will show here.
                <br />
                To remove an upload, click the Delete Icon corresponding to the upload.
                <br />
                The relative order of the uploads can be changed by pressing the Up Arrow or Down Arrow Icons
                corresponding to an upload. The order will persist in the Merged Upload.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.viewingAndEditingOCRResults}
            onChange={(event, expanded) => {
              const updatedSectionsExpanded = { ...sectionsExpanded };
              updatedSectionsExpanded.viewingAndEditingOCRResults = expanded;
              setSectionsExpanded(updatedSectionsExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Viewing and Editing OCR Results</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                OCR Results can be viewed and edited on the Labelling Page, which looks like the following:
              </Typography>
              <img src="/static/6. Labelling.png" className={classes.contentImage} />
              <Typography>
                This page can be accessed by clicking on the Date and Time corresponding to an upload in the History
                Page.
              </Typography>
              <Typography>
                The “page image” and the bounding boxes are shown on the left and the recognized texts are shown on the
                right.
                <br />
                To select a bbox, click on it.
                <br />
                To deselect the selected bbox, click elsewhere in the image.
                <br />
                A bbox can be moved by draging it.
                <br />
                A bbox can be resized by first selecting it and then dragging a corner or an edge.
                <br />
                To add a new bbox, click the "Add Bbox" button in the bottom left. The application will go into the Add
                Bbox Mode. Move your pointer to over the image and you will see an overlay for the bbox to be added.
                Click anywhere on the image to add the new bbox. If you don't want to add a new bbox, simply click the
                cancel button.
                <br />
                To remove the selected bbox, click the "Remove Selected Bbox" button. \<br />
                It is possible to Re-Run the OCR process for a single bbox. To do so, select the required bbox and then
                press the "Re-Run OCR for Bbox" button.
                <br />
                To perform Undo and Redo, use the buttons to the right of the "Re-Run OCR for Bbox" button.
                <br />
                At the bottom right corner there is an option for Bbox editing by keyboard (ctrl+Arrow keys).
                <br />
                It has three modes: Move, Rotate, and Scale.
                <br />
                Also, LIPIKAR provides editing option for line and words repositioning by the eight buttons above the
                text column. <br />
                To save the changes made, use the "Save" button.
                <br />
                To discard the changes made, use the "Reset To Saved" button.
                <br />
                To reset the OCR results to the original output of the system, click the "Reset To Original" button.
                <br />
                To download the OCR results, click the "Download" button which will open up a new Dialog Box. More on
                this in a later section.
                <br />
                <br />
                The Labelling Page also offers a bunch of Keyboard shortcuts for making the User Interface more
                convenient. These are as follows:
                <br />
                Save: Ctrl + S <br />
                Reset To Saved: Ctrl + R <br />
                Reset To Original: Ctrl + T<br />
                Download: Ctrl + D<br />
                {/* Add New Bbox: Ctrl + Shift + A<br /> */}
                Remove Selected Bbox: Ctrl + D
                <br />
                Change Transformation Mode: Ctrl + M <br />
                Undo: Ctrl + Z<br />
                Redo: Ctrl + Y<br />
                Go to the Previous Page: Pg Up
                <br />
                Go to the Next Page: Pg Dn
                <br />
                Currently, there is no shortcut for Re Running OCR for the Selected Bbox. <br />
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            defaultExpanded={true}
            expanded={sectionsExpanded.downloadingOCRResults}
            onChange={(event, expanded) => {
              const updatedSectionsExpanded = { ...sectionsExpanded };
              updatedSectionsExpanded.downloadingOCRResults = expanded;
              setSectionsExpanded(updatedSectionsExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Downloading OCR Results</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                After clicking on the "Download" button on the Labelling Page, a new Dialog Box will appear which looks
                like the following:
              </Typography>
              <img src="/static/7. Download.png" className={classes.contentImage} />
              <Typography>
                The Pages Input can be used to select the pages whose OCR results are to be downloaded.
                <br />
                Use "a-b" to specify a range of pages.
                <br />
                Use "a" or "b" or "c" to specify a single page.
                <br />
                Seperate the various ranges or single pages using commas (",").
                <br />
                To specify all pages, leave the input empty.
                <br />
                <br />
                There are two file formats in which the OCR Results can be downloaded.
                <br />
                txt: Only the recognized texts are downloaded in a plain text file. json: The bounding boxes as well as
                the corresponding texts are downloaded in a json file.
                <br />
                <br />
                After selecting the required pages and file format, hit the "Download" button.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.applicationSettings}
            onChange={(event, expanded) => {
              const updatedSectionsExpanded = { ...sectionsExpanded };
              updatedSectionsExpanded.applicationSettings = expanded;
              setSectionsExpanded(updatedSectionsExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Application Settings</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>The Settings Page looks like the following:</Typography>
              <img src="/static/8. Settings.png" className={classes.contentImage} />
              <Typography component={Link} to={"/settings"}>
                Go to the Settings Page.
              </Typography>
              <Typography>
                The User can change their password by clicking on the "Reset Password" button. More on this in a later
                section.
                <br />
                To Log Out of the application, click the "Logout" button.
                <br />
                The application also offers an option to adjust the stroke width of the bounding boxes displayed on the
                Labelling Page. The stroke width is a certain fraction of the dimensions of the current image shown in
                the Labelling Page. This factor can be adjusted by using the slider.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.resettingYourPassword}
            onChange={(event, expanded) => {
              const updatedSectionsExpanded = { ...sectionsExpanded };
              updatedSectionsExpanded.resettingYourPassword = expanded;
              setSectionsExpanded(updatedSectionsExpanded);
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Resetting your Password</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>The Reset Password Page looks like the following:</Typography>
              <img src="/static/9. Reset Password.png" className={classes.contentImage} />
              <Typography component={Link} to={"/auth/reset-password"}>
                Go to the Reset Password Page.
              </Typography>
              <Typography>
                To reset your password, first enter your current password.
                <br />
                Then enter a new password, confirm it, and click the "Reset Password" button.
                <br />
                If there is an error, it will display in the top left of the screen.
                <br />
                If the password was reset successful, a toast will show in the top left of the screen to notify you of
                this and then you will be redirected to the Login Page.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 25,
    marginBottom: 100,
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  contentImage: {
    maxWidth: "50%",
    border: "1px solid #000",
    margin: 20,
  },
  accordion: {
    width: "100%",
  },
  accordionDetails: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));
