import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, Checkbox, TextField, IconButton, LinearProgress, Tooltip, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  QuestionMark as QuestionMarkIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { ClipLoader, BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

import apiClient from "../api/apiServices";
import validators from "../api/validators";
import { parseUploadProcessingStatusForFrontend } from "../api/apiUtils";
import CancelUploadConfirmDialog from "./CancelUploadConfirmDialog";

const processingStatusCodes = [0, 1, 2, 3];
const cannotViewResultStatusCodes = [0, 1, 2, 3, 4, 6];

const getBgColorForIndexAndStatusCode = (index, statusCode) => {
  if (statusCode === 6) {
    return "#FFCCCB";
  }

  if (statusCode === 7) {
    return "#FFFFE0";
  }

  if (index % 2 == 0) {
    return "#E9E9E9";
  }

  return "#EEEEEE";
};

const getErrorForViewUnprocessedUpload = (pageTitle, statusCode, numPages) => {
  if (statusCode !== 6 && numPages === 0) {
    return "This upload has 0 pages. It was probably cancelled before the first page was processed.";
  }

  if (pageTitle === "labelling") {
    if (statusCode === 6) {
      return "Labelling cannot be performed as there was an error in processing this upload.";
    }

    if (statusCode !== 5) {
      return "Please wait for the upload to finish processing before moving to the labelling page.";
    }
  }

  if (pageTitle === "file") {
    if (statusCode === 6) {
      return "The file cannot be viewed as there was an error in processing this upload.";
    }

    if (statusCode !== 5) {
      return "The file cannot be viewed while the upload is being processed.";
    }
  }
};

export default function UploadListItem({
  uploadDetails,
  setUploadDetails,
  isSelected,
  checkBoxOnChange,
  isGeneratingPdfForUploadId,
  handleListItemFilenameClick,
  refetchAllUploads,
  index,
  statusUpdateRequestInterval,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const splitFilename = uploadDetails.filename.split(".");

  const [isInRenamingMode, setIsInRenamingMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [filename, setFilename] = useState(
    splitFilename[0]?.startsWith("/static") ? splitFilename[0]?.slice(8) : splitFilename[0]
  );
  const fileExtension = splitFilename[1];
  const [newFilenameValidationError, setNewFilenameValidationError] = useState("");

  const [failedProcessingStatusRefreshAttemptsCount, setFailedProcessingStatusRefreshAttemptsCount] = useState(0);
  const refreshProcessingProgressIntervalIdRef = useRef(null);
  const [processingStatus, setProcessingStatus] = useState(
    parseUploadProcessingStatusForFrontend(uploadDetails.processing_status)
  );

  const [cancelUploadConfirmDialogOpen, setCancelUploadConfirmDialogOpen] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [hasBeenCancelled, setHasBeenCancelled] = useState(false);

  const handleListItemClick = (event) => {
    if (cannotViewResultStatusCodes.includes(processingStatus.statusCode) || uploadDetails.detection_ids.length === 0) {
      toast(
        getErrorForViewUnprocessedUpload("labelling", processingStatus.statusCode, uploadDetails.detection_ids.length)
      );
      return;
    }

    navigate(`/labelling/${uploadDetails.id}`);
  };

  const handleOpenFileButtonClick = (event) => {
    if (cannotViewResultStatusCodes.includes(processingStatus.statusCode) || uploadDetails.detection_ids.length === 0) {
      toast(getErrorForViewUnprocessedUpload("file", processingStatus.statusCode, uploadDetails.detection_ids.length));
      return;
    }

    handleListItemFilenameClick(uploadDetails.id);
  };

  const handleEditButtonClick = (event) => {
    setIsInRenamingMode(true);
  };

  const saveFilename = async () => {
    setIsSaving(true);

    const response = await apiClient.uploads.changeFilename(uploadDetails.id, filename);

    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      setIsSaving(false);
      return;
    }

    response.result.upload.detection_ids = JSON.parse(response.result.upload.detection_ids);

    setUploadDetails(response.result.upload);
    setIsSaving(false);
    setIsInRenamingMode(false);
  };

  const handleSaveButtonClick = (event) => {
    saveFilename();
  };

  const handleFilenameTextFieldKeyDown = (event) => {
    if (event.key === "Enter") {
      saveFilename();
    }
  };

  const cancelUploadProcessing = async (event) => {
    setIsCancelling(true);

    const response = await apiClient.uploads.cancel(uploadDetails.id);
    if (!response.success) {
      console.log(response);
      toast(response.error.message);
      setIsCancelling(false);
      return;
    }

    setIsCancelling(false);
    setCancelUploadConfirmDialogOpen(false);
    setHasBeenCancelled(true);
    if (refreshProcessingProgressIntervalIdRef.current !== null) {
      clearInterval(refreshProcessingProgressIntervalIdRef.current);
      refreshProcessingProgressIntervalIdRef.current = null;
      refetchAllUploads();
    }
    setProcessingStatus({
      statusCode: 7,
      statusString: "cancelled",
    });

    refetchAllUploads();
  };

  const handleCancelButtonClick = (event) => {
    setCancelUploadConfirmDialogOpen(true);
  };

  const refreshProcessingProgress = async () => {
    console.log(`Refreshing: ${uploadDetails.id}`);

    const response = await apiClient.uploads.getSingle(uploadDetails.id, true);
    if (!response.success) {
      if (failedProcessingStatusRefreshAttemptsCount > 3) {
        if (refreshProcessingProgressIntervalIdRef.current !== null) {
          clearInterval(refreshProcessingProgressIntervalIdRef.current);
          refreshProcessingProgressIntervalIdRef.current = null;
        }
        setProcessingStatus({
          ...processingStatus,
          statusCode: 4,
          statusString: "Failed to get processing status. Please refresh the page to try again.",
        });
      }
      setFailedProcessingStatusRefreshAttemptsCount(failedProcessingStatusRefreshAttemptsCount + 1);

      toast(response.error.message);
      console.log(response);
      return;
    }

    const parsedProcessingStatus = parseUploadProcessingStatusForFrontend(response.result.processingStatus);
    setProcessingStatus(parsedProcessingStatus);

    if ([4, 5, 6, 7].includes(parsedProcessingStatus.statusCode) || hasBeenCancelled) {
      if (refreshProcessingProgressIntervalIdRef.current !== null) {
        clearInterval(refreshProcessingProgressIntervalIdRef.current);
        refreshProcessingProgressIntervalIdRef.current = null;
        refetchAllUploads();
      }
    }
  };

  useEffect(() => {
    if (![4, 5, 6, 7].includes(processingStatus.statusCode)) {
      refreshProcessingProgressIntervalIdRef.current = setInterval(
        refreshProcessingProgress,
        statusUpdateRequestInterval
      );
    }

    return () => {
      if (refreshProcessingProgressIntervalIdRef.current !== null) {
        clearInterval(refreshProcessingProgressIntervalIdRef.current);
        refreshProcessingProgressIntervalIdRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    setNewFilenameValidationError(validators.changeUploadFilename(filename));
  }, [filename]);

  return (
    <Box width="100%" mb={2}>
      <CancelUploadConfirmDialog
        open={cancelUploadConfirmDialogOpen}
        onClose={() => setCancelUploadConfirmDialogOpen(false)}
        onAgree={() => cancelUploadProcessing()}
        onDisagree={() => setCancelUploadConfirmDialogOpen(false)}
        uploadId={uploadDetails.id}
        isProcessingAgree={isCancelling}
      />

      <Box
        className={classes.listItemContainer}
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        bgcolor={getBgColorForIndexAndStatusCode(index, processingStatus.statusCode)}
        borderRadius={1}
        p={1}
        onClick={handleListItemClick}
      >
        <Box mr={2}>
          {!processingStatusCodes.includes(processingStatus.statusCode) ? (
            <Checkbox
              checked={isSelected}
              onChange={checkBoxOnChange}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            <ClipLoader color="black" loading={true} size={25} />
          )}
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={2} md={1}>
            <Typography variant="h5" color="primary">
              {uploadDetails.id}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            {isInRenamingMode ? (
              <Box
                display="flex"
                flexDirection="row"
                // sx={{ flexGrow: 1 }}
              >
                <TextField
                  className={classes.listItemFilename}
                  type="text"
                  value={filename}
                  onChange={(event) => setFilename(event.target.value)}
                  onKeyDown={handleFilenameTextFieldKeyDown}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  error={Boolean(newFilenameValidationError)}
                  helperText={newFilenameValidationError}
                />
                <Box>
                  <IconButton
                    color={Boolean(newFilenameValidationError) ? "error" : "primary"}
                    variant="contained"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSaveButtonClick(e);
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row">
                <Typography
                  variant="h5"
                  color="primary"
                  noWrap
                  sx={{
                    maxWidth: "90%",
                    marginRight: 2,
                  }}
                >
                  {`${filename}.${fileExtension}`}
                </Typography>

                {processingStatus.statusCode === 5 ? (
                  <IconButton
                    variant="contained"
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditButtonClick(e);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : null}
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={2}>
            <Typography className={classes.listItemNumDetections} variant="h5" color="primary">
              {cannotViewResultStatusCodes.includes(processingStatus.statusCode)
                ? ""
                : uploadDetails.detection_ids.length}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography className={classes.listItemDate} variant="h6" color="primary">
                {new Date(uploadDetails.created_at).toString().slice(0, 24)}
              </Typography>

              {processingStatusCodes.includes(processingStatus.statusCode) && !hasBeenCancelled ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCancelButtonClick(e);
                  }}
                  sx={{ width: 150 }}
                >
                  Cancel
                </Button>
              ) : !cannotViewResultStatusCodes.includes(processingStatus.statusCode) &&
                uploadDetails.detection_ids.length > 0 ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenFileButtonClick(e);
                  }}
                  sx={{ width: 150 }}
                >
                  {isGeneratingPdfForUploadId === uploadDetails.id ? (
                    <BeatLoader color="white" loading={true} size={10} />
                  ) : uploadDetails.detection_ids.length === 1 ? (
                    "Open Image"
                  ) : (
                    "Open PDF"
                  )}
                </Button>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {![4, 5, 6, 7].includes(processingStatus.statusCode) ? (
        <Box mb={2}>
          <Typography>{processingStatus?.statusString}</Typography>
          <LinearProgress color="primary" variant="determinate" value={processingStatus.pagesProgress} />

          {/* <LinearProgress color="secondary" variant="determinate" value={processingStatus.bboxesProgress} /> */}
        </Box>
      ) : null}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  listItemContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 10,
    borderRadius: 10,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#DEDEDE",
    },
  },
  listItemDate: {
    width: "100%",
  },
  listItemDateDisabled: {
    width: "100%",
    borderRadius: 5,
  },
  listItemFilename: {
    width: "100%",
  },
}));
