import { useState, useEffect } from "react";
import { detections } from "../api/apiEndpoints";
import Select from "../components/Select";
import { Box, TextField, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

export default function MergeUploadsListItem({
  uploadObject,
  onMoveUp,
  onMoveDown,
  onDelete,
  moveUpEnabled,
  moveDownEnabled,
  deleteEnabled,
  index,
}) {
  const classes = useStyles();

  return (
    <Box
      width="95%"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      bgcolor={index % 2 == 0 ? "#EEEEEE" : "DDDDDD"}
      p={1}
      mb={1}
      borderRadius={2}
    >
      <Typography>{uploadObject.filename}</Typography>

      <Box
        width="30%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width="33%">
          {moveUpEnabled ? (
            <IconButton onClick={onMoveUp}>
              <ArrowUpwardIcon color="action" />
            </IconButton>
          ) : null}
        </Box>

        <Box width="33%">
          {moveDownEnabled ? (
            <IconButton onClick={onMoveDown}>
              <ArrowDownwardIcon color="action" />
            </IconButton>
          ) : null}
        </Box>

        <Box width="33%">
          {deleteEnabled ? (
            <IconButton onClick={onDelete}>
              <DeleteIcon color="error" />
            </IconButton>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  inputTextField: {
    width: "80%",
  },
}));
