import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { BeatLoader } from "react-spinners";

export default function CancelUploadConfirmDialog({
  open,
  onClose,
  onAgree,
  onDisagree,
  uploadId,
  isProcessingAgree,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color="primary">
        Upload with Id {uploadId} is being processed...
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="error">
          Do you want to stop further processing of this upload? Results of the
          pages that have been processed will still be visible.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onDisagree}
          autoFocus
          sx={{ width: 150 }}
        >
          Back to History
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={onAgree}
          sx={{ width: 150 }}
        >
          {isProcessingAgree ? (
            <BeatLoader color="red" loading={true} size={10} />
          ) : (
            "Cancel Upload"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
