import JSZip from "jszip";
import { saveAs } from "file-saver";

import apiClient from "../api/apiServices";
import { detectionImageUrlPrefix } from "../api/apiEndpoints";
import { tryParseJsonString } from "./jsUtils";
import { detectionsToPdfBlob } from "./pdfUtils";
import { detection2dIndexBasedComparator, removeKonvaFieldsFromDetections } from "./detectionUtils";
import { decodeUnicodeEscape, convertToUnicodeEscape } from "./stringUtils";
import { mediaBaseUrl } from "../api/apiEndpoints";

function parsePagesString(pagesString, numTotalPages) {
  let parsedPageNumbers = [];
  const errors = [];

  // should only include hyphens and commas
  if (true) {
  }

  if (pagesString === "") {
    // all pages
    parsedPageNumbers = parsedPageNumbers.concat(Array.from(new Array(numTotalPages), (x, i) => i + 1));
    return {
      pages: parsedPageNumbers,
      errors: errors,
    };
  }

  const commaSplitPages = pagesString.split(",");

  commaSplitPages.forEach((pageStr1) => {
    const hyphenSplitPages = pageStr1.split("-");

    if (hyphenSplitPages.length === 1) {
      parsedPageNumbers.push(parseInt(hyphenSplitPages[0]));
    } else if (hyphenSplitPages.length === 2) {
      const lowerbound = parseInt(hyphenSplitPages[0]);
      const upperbound = parseInt(hyphenSplitPages[1]);

      if (lowerbound > upperbound) {
        // error: format like: "5-2"
        console.log("Invalid pages input. Lowerbound of range is greater than upperbound.");
        errors.push("Invalid pages input. Lowerbound of range is greater than upperbound.");
        return;
      }

      parsedPageNumbers = parsedPageNumbers.concat(
        Array.from(new Array(upperbound - lowerbound + 1, (x, i) => i + lowerbound))
      );
    } else {
      // error: format like "2-4-8"
      console.log("Invalid pages input. Format like 'x-y-z'.");
      errors.push("Invalid pages input. Format like 'x-y-z'.");
      return;
    }
  });

  parsedPageNumbers.sort((a, b) => a - b);

  if (parsedPageNumbers[0] < 1 || parsedPageNumbers[0] > numTotalPages) {
    // check if page 0 is out of bounds
    console.log(`Page ${parsedPageNumbers[0]} is out of range.`);
    errors.push(`Page ${parsedPageNumbers[0]} is out of range.`);
  }

  for (let i = 1; i < parsedPageNumbers.length; i++) {
    if (parsedPageNumbers[i] === parsedPageNumbers[i - 1]) {
      // check if any page appeared twice
      console.log(`Page ${parsedPageNumbers[i]} encountered multiple times.`);
      errors.push(`Page ${parsedPageNumbers[i]} encountered multiple times.`);
    }

    if (parsedPageNumbers[i] < 1 || parsedPageNumbers[i] > numTotalPages) {
      // check if any page is out of bounds
      console.log(`Page ${parsedPageNumbers[i]} is out of range.`);
      errors.push(`Page ${parsedPageNumbers[i]} is out of range.`);
    }
  }

  return {
    pages: parsedPageNumbers,
    errors: errors,
  };
}

function generateJSONFileObjectUrl(imagesAndDetections) {
  const modifiedImagesAndDetections = [...imagesAndDetections];
  for (const detectionObject of modifiedImagesAndDetections) {
    for (const detection of detectionObject.detections) {
      detection.text = decodeUnicodeEscape(detection.text);
    }
  }

  const jsonString = JSON.stringify(modifiedImagesAndDetections, null, 4);
  const blob = new Blob([jsonString], { type: "application/json" });
  const href = URL.createObjectURL(blob);

  return href;
}

function generateTxtFileObjectUrl(imagesAndDetections, pageNumbers) {
  let text = "";

  imagesAndDetections.forEach((pageImageAndDetections, index) => {
    text += `---------- Page ${pageNumbers.pages[index]} ----------` + "\n";

    const sortedDetections = pageImageAndDetections.detections;
    sortedDetections.sort(detection2dIndexBasedComparator);

    let lastDetection = null;
    for (const detection of sortedDetections) {
      if (lastDetection !== null && detection.text_bbox.line_index !== lastDetection.text_bbox.line_index) {
        text += "\n";
      } else {
        text += " ";
      }

      text += decodeUnicodeEscape(detection.text);
      lastDetection = detection;
    }

    text += "\n";
  });

  const blob = new Blob([text], { type: "text/plain" });
  const href = URL.createObjectURL(blob);

  return href;
}

async function generateAndDownloadFile(
  uploadId,
  filename,
  allImagesDetectionDetails,
  pagesString,
  selectedFileFormat,
  updateStatusCallback,
  errorCallback
) {
  updateStatusCallback("Parsing pages to download...");
  const pagesToDownload = parsePagesString(pagesString, allImagesDetectionDetails.length);

  if (pagesToDownload.errors.length > 0) {
    pagesToDownload.errors.forEach((error) => {
      errorCallback(error);
    });
    return;
  }

  updateStatusCallback("Filtering selected pages...");
  const selectedPagesImagesAndDetections = allImagesDetectionDetails
    .filter((pageImageAndDetections, index) => pagesToDownload.pages.includes(index + 1))
    .map((pageImageAndDetections) => ({
      ...pageImageAndDetections,
      detections: removeKonvaFieldsFromDetections(pageImageAndDetections.detections),
    }));

  updateStatusCallback("Generating file...");
  let fileObjectUrl;
  let fileExtension;

  if (selectedFileFormat === ".json") {
    fileObjectUrl = generateJSONFileObjectUrl(selectedPagesImagesAndDetections);
    fileExtension = ".json";
  } else if (selectedFileFormat.startsWith(".pdf")) {
    updateStatusCallback("Generating PDF...");

    updateStatusCallback("PDF Generated Successfully. Downloading...");

    const pdfGenerationMode = selectedFileFormat === ".pdf (Transparent Text)" ? "transparentText" : "overlayedText";

    const response = await apiClient.utils.generatePdf(uploadId, pagesToDownload.pages, pdfGenerationMode);
    if (!response.success) {
      console.log(response);
      errorCallback("Failed to Generated PDF.");
      return;
    }

    const generatedPdfUrl = `${mediaBaseUrl}/${response.result.mediaUrl}`;
    window.open(generatedPdfUrl, "_blank");
    updateStatusCallback("");
    return;
  } else {
    fileObjectUrl = generateTxtFileObjectUrl(selectedPagesImagesAndDetections, pagesToDownload);
    fileExtension = ".txt";
  }

  updateStatusCallback("Downloading...");
  const fileLink = document.createElement("a");
  fileLink.href = fileObjectUrl;
  fileLink.download = filename + fileExtension;
  document.body.appendChild(fileLink);
  fileLink.click();

  updateStatusCallback("");
}

async function generateFolderForUpload(uploadId) {
  const getUploadResponse = await apiClient.uploads.getSingle(uploadId);
  if (!getUploadResponse.success) {
    return {};
  }
  const uploadDetails = getUploadResponse.result.upload;
  uploadDetails.processing_status = tryParseJsonString(uploadDetails.processing_status);

  const getDetectionsResponse = await apiClient.detections.getAllForUpload(uploadId);
  if (!getDetectionsResponse.success) {
    return {};
  }
  const allDetectionsForUpload = getDetectionsResponse.result.detections;
  const detectionImages = [];
  for (const detection of allDetectionsForUpload) {
    detection.detections = tryParseJsonString(detection.detections, []);
    detection.original_detections = tryParseJsonString(detection.original_detections, []);

    const detectionImageUrl = detectionImageUrlPrefix + detection.image_filename;
    const getImageResponse = await fetch(detectionImageUrl, {
      method: "GET",
      headers: {},
    });
    // const imageArrayBuffer = await getImageResponse.arrayBuffer();
    // const imageBlob = new Blob([imageArrayBuffer]);
    const imageBlob = await getImageResponse.blob();

    const imageFileExtension = detection.image_filename.split(".").pop();
    detectionImages.push(new File([imageBlob], detection.image_filename, { type: `image/${imageFileExtension}` }));
  }

  const directoryStructure = {
    folderName: uploadId,
    files: [
      {
        folderName: "images",
        files: detectionImages,
      },
      new File([JSON.stringify(allDetectionsForUpload, null, 4)], "detections.json", { type: "application/json" }),
      new File([JSON.stringify(uploadDetails, null, 4)], "uploadDetails.json", { type: "application/json" }),
    ],
  };

  return directoryStructure;
}

async function generateAndDownloadZipFileForMultipleUploads(uploadIds, updateProgressCallback, errorCallback) {
  const allUploadsFolder = [];

  for (let i = 0; i < uploadIds.length; i++) {
    const uploadId = uploadIds[i];
    const uploadFolder = await generateFolderForUpload(uploadId);
    allUploadsFolder.push(uploadFolder);

    updateProgressCallback({
      currentOperation: "fetching",
      currentOperationProgress: (i + 1) / uploadIds.length,
    });
  }

  const addFilesToZip = (folder, files, directoryLevel = 0) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file instanceof File) {
        folder.file(file.name, file);
      } else if (typeof file === "object") {
        const subfolder = folder.folder(file.folderName);
        addFilesToZip(subfolder, file.files, directoryLevel + 1);
      }

      if (directoryLevel === 0) {
        updateProgressCallback({
          currentOperation: "zipping",
          currentOperationProgress: (i + 1) / files.length,
        });
      }
    }
  };

  const zip = new JSZip();
  addFilesToZip(zip, allUploadsFolder);
  const content = await zip.generateAsync({ type: "blob" });
  saveAs(content, `Lipikar Export ${new Date().toISOString()}.zip`);
}

export { generateAndDownloadFile, generateAndDownloadZipFileForMultipleUploads };
