import { useEffect, useState, Fragment } from "react";
import { Rect, Line, Circle } from "react-konva";

import { getPolygonBoundingBox } from "../utils/detectionUtils";

export default function Polygon({ points, setPoints, isSelected, onSelect, isAddingNew, additionalShapeProps }) {
  console.log("re render"); // TODO: This component re-renders unnecessarily

  const alwaysFilled = isAddingNew || isSelected;

  const [localPoints, setLocalPoints] = useState(points);
  const [flattenedPoints, setFlattenedPoints] = useState(points.flat());
  const [polygonBoundingBox, setPolygonBoundingBox] = useState(getPolygonBoundingBox(points));

  const [polygonFill, setPolygonFill] = useState(alwaysFilled ? polygonFillColor : "#00000000");
  const [boundingRectFill, setBoundingRectFill] = useState(alwaysFilled ? boundingRectFillColor : "#00000000");

  useEffect(() => {
    setLocalPoints(points);
    setFlattenedPoints(points.flat());
    setPolygonBoundingBox(getPolygonBoundingBox(points));
  }, [points]);

  useEffect(() => {
    setFlattenedPoints(localPoints.flat());
    setPolygonBoundingBox(getPolygonBoundingBox(localPoints));
  }, [localPoints]);

  return (
    <Fragment>
      <Line
        strokeWidth={additionalShapeProps.strokeWidth / additionalShapeProps.zoomFactor}
        stroke={additionalShapeProps.stroke}
        fill={polygonFill}
        closed={true}
        points={flattenedPoints}
      />
      <Rect
        fill={boundingRectFill}
        x={polygonBoundingBox.x_min}
        y={polygonBoundingBox.y_min}
        width={polygonBoundingBox.width}
        height={polygonBoundingBox.height}
        onClick={onSelect}
        onMouseEnter={() => {
          setPolygonFill(polygonFillColor);
          setBoundingRectFill(boundingRectFillColor);
        }}
        onMouseLeave={() => {
          if (alwaysFilled) {
            return;
          }

          setPolygonFill("#00000000");
          setBoundingRectFill("#00000000");
        }}
      />
      {!isAddingNew &&
        isSelected &&
        localPoints.map((point, index) => (
          <Circle
            x={point[0]}
            y={point[1]}
            radius={10 / additionalShapeProps.zoomFactor}
            fill="red"
            key={index}
            draggable={true}
            onDragMove={(e) => {
              const updatedPoints = [...localPoints];
              updatedPoints[index][0] = e.target.x();
              updatedPoints[index][1] = e.target.y();
              setLocalPoints(updatedPoints);
            }}
            onDragEnd={(e) => {
              const updatedPoints = [...points];
              updatedPoints[index][0] = e.target.x();
              updatedPoints[index][1] = e.target.y();
              setPoints(updatedPoints);
            }}
          />
        ))}
    </Fragment>
  );
}

const polygonFillColor = "#0000ff33";
const boundingRectFillColor = "#ffff0022";
