import { useState, useContext, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Box, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";

import Select from "../components/Select";
import userContext from "../context/userContext";
import apiClient from "../api/apiServices";
import { convertArrOfStringsToString } from "../utils/stringUtils";

const templateImages = {
  // TODO: get this dynamically from the backend as well
  urdu: `/static/Urdu - Template.jpg`,
};

const NewUpload = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user, backendConfig, appSettings } = useContext(userContext);

  const fileInputRef = useRef();

  const [filename, setFilename] = useState(null);
  const [file, setFile] = useState(null);
  const [filepath, setFilepath] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [fileObjectUrl, setFileObjectUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [isLoadingAvailableCredits, setIsLoadingAvailableCredits] = useState(false);
  const [pagesRemaining, setPagesRemaining] = useState(null);

  const [availableTextRecognizers, setAvailableTextRecognizers] = useState([]);
  const [availableTextRecognizersNames, setAvailableTextRecognizersNames] = useState([]);
  const [selectedTextRecognizerName, setSelectedTextRecognizerName] = useState("");

  const [availableDocumentParsers, setAvailableDocumentParsers] = useState([]);
  const [supportedDocumentParsersNames, setSupportedDocumentParsersNames] = useState([]);
  const [selectedDocumentParserName, setSelectedDocumentParserName] = useState("");

  const [isTemplateImageLoaded, setIsTemplateImageLoaded] = useState(false);

  const [processingStatus, setProcessingStatus] = useState("Uploading...");

  const getAvailableCreditsFromApi = async () => {
    setIsLoadingAvailableCredits(true);

    const response = await apiClient.user.getAvailableCredits();
    if (!response.success) {
      console.log(response);
      setIsLoadingAvailableCredits(false);
      return;
    }

    setPagesRemaining(Math.floor(response.result.credits));
    setIsLoadingAvailableCredits(false);
  };

  const handleFileUpload = (event, drop = false) => {
    let uploadedFile;
    if (drop) {
      event.preventDefault();
      uploadedFile = event.dataTransfer.files[0];
    } else {
      uploadedFile = event.target.files[0];
    }

    if (!uploadedFile) {
      return;
    }

    if (!acceptedFileTypes.includes(uploadedFile.type)) {
      toast(`Accepted file types are: ${convertArrOfStringsToString(acceptedFileTypes)}.`);
      return;
    }

    if (uploadedFile.size > fileSizeLimitInMB * 1024 * 1024) {
      toast(`File cannot be larger than ${fileSizeLimitInMB} MB.`);
      return;
    }

    setFilename(uploadedFile.name);
    setFileExtension(uploadedFile.name.split(".").pop());
    setFilepath(uploadedFile);
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = e.target.result;
      setFileObjectUrl(URL.createObjectURL(uploadedFile));
      setFile(fileData);
    };
    reader.readAsDataURL(uploadedFile);
  };

  const goButtonOnClick = async () => {
    setIsUploading(true);

    const selectedTextRecognizer = availableTextRecognizers.find(
      (textRecognizer) => textRecognizer.displayName === selectedTextRecognizerName
    );
    const selectedDocumentParser = availableDocumentParsers.find(
      (documentParser) => documentParser.displayName === selectedDocumentParserName
    );

    const response = await apiClient.uploads.addNew(
      filepath,
      selectedDocumentParser.modelId,
      "no_postprocessor",
      selectedTextRecognizer.modelId
    );

    if (!response.success) {
      console.log(response);
      toast(response.error.message);
      setIsUploading(false);
      return;
    }

    setPagesRemaining(pagesRemaining - 1);
    setIsUploading(false);

    if (appSettings.redirectToHistoryPageOnNewUploadSubmit) {
      navigate("/history/1");
    } else {
      toast("Queued for processing. Please check the history page for status.");
    }
  };

  // const handleLoadTemplateImageButtonClick = (event) => {
  //   setFilepath(templateImages[convert_label_to_key(selectedLanguageAndVersion)]);
  //   setFile(templateImages[convert_label_to_key(selectedLanguageAndVersion)]);
  //   setFilename(templateImages[convert_label_to_key(selectedLanguageAndVersion)].slice(8));
  //   setIsTemplateImageLoaded(true);
  // };

  useEffect(() => {
    document.title = "Upload New";

    getAvailableCreditsFromApi();
  }, []);

  useEffect(() => {
    if (!backendConfig.text_recognizers || !backendConfig.document_parsers) {
      return;
    }

    const textRecognizersNames = backendConfig.text_recognizers.map((textRecognizer) => textRecognizer.displayName);

    setAvailableTextRecognizers(backendConfig.text_recognizers);
    setAvailableTextRecognizersNames(textRecognizersNames);
    setAvailableDocumentParsers(backendConfig.document_parsers);
  }, [backendConfig]);

  useEffect(() => {
    if (!selectedTextRecognizerName) {
      setSupportedDocumentParsersNames([]);
      return;
    }

    const selectedTextRecognizer = availableTextRecognizers.find(
      (textRecognizer) => textRecognizer.displayName === selectedTextRecognizerName
    );

    const requiredDetectionLevel = selectedTextRecognizer.detectionLevel[0];
    const documentParsersWithRequiredDetectionLevel = availableDocumentParsers.filter((documentParser) =>
      documentParser.detectionLevel.includes(requiredDetectionLevel)
    );
    const documentParsersWithRequiredDetectionLevelNames = documentParsersWithRequiredDetectionLevel.map(
      (documentParser) => documentParser.displayName
    );

    setSupportedDocumentParsersNames(documentParsersWithRequiredDetectionLevelNames);

    // TODO: update template image
  }, [selectedTextRecognizerName]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  if (!user.canCompute) {
    return (
      <div className={classes.pageContainer}>
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h5" color="error">
            You do not have compute permission. Please contact an admin for more details.
          </Typography>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.container}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" mb={2}>
          <Typography className={classes.heading} variant="h3" style={{ fontWeight: "bold", textAlign: "center" }}>
            Upload a New File for OCR
          </Typography>
        </Box>

        <Box mb={2}>
          {file ? (
            fileExtension === "pdf" ? (
              <object
                data={file}
                type="application/pdf"
                width={400}
                height={400}
                // onDrop={(e) => handleFileUpload(e, true)}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  sx={{ minWidth: 300 }}
                  onClick={() => window.open(fileObjectUrl, "_blank")}
                >
                  {`File Loaded: ${filename}`}
                </Button>
              </object>
            ) : (
              <img
                style={{
                  display: "block",
                  maxWidth: "50vw",
                  maxHeight: "50vh",
                  width: "auto",
                  height: "auto",
                  marginBottom: "5vh",
                  border: "1px solid black",
                }}
                src={file}
                alt="uploaded image"
                onDrop={(e) => handleFileUpload(e, true)}
                onDragOver={(e) => e.preventDefault()}
              />
            )
          ) : (
            <div
              onDrop={(e) => handleFileUpload(e, true)}
              onDragOver={(e) => e.preventDefault()}
              style={{
                width: "50vw",
                height: "50vh",
                border: "1px dashed black",
                backgroundImage: "linear-gradient(#fae8e1,#f9e4dd)",
                borderRadius: 25,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Drag and drop file here
            </div>
          )}
        </Box>

        <Box width="50%" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" mb={2}>
          <Box>
            <input
              id="image-upload-input"
              type="file"
              accept=".jpg,.jpeg,.pdf"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <label
              for="image-upload-input"
              style={{
                backgroundImage: "linear-gradient(#fae8e1,#f9e4dd)",
                border: "1px dashed black",
                padding: 10,
                borderRadius: 5,
                marginBottom: 15,
              }}
            >
              {isTemplateImageLoaded ? "Choose Another File" : filename ? filename : "Choose File"}
            </label>
          </Box>

          {/* {appSettings.showTemplateImagesOnNewUploadPage &&
          Object.keys(templateImages).includes(convert_label_to_key(selectedLanguageAndVersion)) ? (
            <Button color="primary" variant="outlined" onClick={handleLoadTemplateImageButtonClick}>
              Load Template Image
            </Button>
          ) : null} */}
        </Box>

        <Box width="50%" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" mb={2}>
          <Box width="30%">
            <Select
              placeholder="Select Language"
              selectedValue={selectedTextRecognizerName}
              setSelectedValue={(newValue) => {
                setSelectedTextRecognizerName(newValue);
              }}
              allValues={availableTextRecognizersNames}
            />
          </Box>

          <Box width="30%">
            <Select
              placeholder="Select Parser"
              selectedValue={selectedDocumentParserName}
              setSelectedValue={(newValue) => {
                setSelectedDocumentParserName(newValue);
              }}
              allValues={supportedDocumentParsersNames}
              disabled={!selectedTextRecognizerName}
            />
          </Box>
        </Box>

        <Box mb={2}>
          {isUploading ? (
            <ClipLoader color={"#0000EE"} loading={true} size={35} />
          ) : (
            <Button
              variant="contained"
              size="large"
              disabled={file === null || !selectedTextRecognizerName || !selectedDocumentParserName}
              onClick={goButtonOnClick}
            >
              Start OCR !
            </Button>
          )}
        </Box>

        <Box mb={2}>
          {isUploading ? (
            <Typography variant="h6">{processingStatus}</Typography>
          ) : !isLoadingAvailableCredits && pagesRemaining ? (
            <Typography variant="h6" color={pagesRemaining <= 10 ? "error" : "primary"}>
              {`${pagesRemaining} ${pagesRemaining === 1 ? "Page" : "Pages"} Remaining`}
            </Typography>
          ) : !isLoadingAvailableCredits && pagesRemaining === null ? (
            <Typography variant="h6" color="error">
              Could not available pages quota from the server.
            </Typography>
          ) : null}
        </Box>
      </div>
    </div>
  );
};

export default NewUpload;

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    // width: "100%",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // marginTop: 25,
    // marginBottom: 100,

    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: theme.spacing(10),
    marginTop: 25,
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  inputTextField: {
    width: "80%",
    "&&": {
      marginBottom: 25,
    },
  },
  alternateLinkText: {
    marginTop: 20,
  },
}));

const acceptedFileTypes = ["application/pdf", "image/jpeg"];
const fileSizeLimitInMB = 100;
