import { useRef, useEffect, useState, Fragment } from "react";
import { Rect, Transformer } from "react-konva";

const minWidth = 5;
const minHeight = 5;

export default function Rectangle({ shapeProps, isSelected, onSelect, onChange }) {
  const shapeRef = useRef();
  const trRef = useRef();

  const [fill, setFill] = useState("#00000000");

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <Fragment>
      <Rect
        fill={isSelected ? "#0000ff55" : fill}
        onMouseEnter={() => setFill("#0000ff55")}
        onMouseLeave={() => setFill("#00000000")}
        onClick={onSelect}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        draggable={isSelected}
        onDragEnd={(e) => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            rotation: node.rotation(),
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(minWidth, node.width() * scaleX),
            height: Math.max(minHeight, node.height() * scaleY),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          anchorSize={5}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < minWidth || newBox.height < minHeight) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </Fragment>
  );
}
