import { rgbStringToArray } from "./jsUtils";
import { addExhaustivePropsToBbox } from "./detectionUtils";

function getAverageColorForImageData(imageData) {
  let totalRed = 0;
  let totalGreen = 0;
  let totalBlue = 0;
  for (let i = 0; i < imageData.length; i += 4) {
    totalRed += imageData[i];
    totalGreen += imageData[i + 1];
    totalBlue += imageData[i + 2];
  }
  const avgRed = totalRed / imageData.length;
  const avgGreen = totalGreen / imageData.length;
  const avgBlue = totalBlue / imageData.length;

  return `rgb(${Math.round(avgRed)}, ${Math.round(avgGreen)}, ${Math.round(avgBlue)})`;
}

function getMajorityColorForImageData(imageData) {
  const colorCount = {};

  for (let i = 0; i < imageData.length; i += 4) {
    const color = `rgb(${imageData[i]}, ${imageData[i + 1]}, ${imageData[i + 2]})`;
    if (colorCount[color]) {
      colorCount[color]++;
    } else {
      colorCount[color] = 1;
    }
  }

  let majorityColor;
  let maxCount = 0;

  for (const color in colorCount) {
    if (colorCount[color] > maxCount) {
      maxCount = colorCount[color];
      majorityColor = color;
    }
  }

  return majorityColor;
}

function loadImageAndRemoveDetectedText(imageUrl, detections, mode = "text", bboxColorOverwriteMode = "majority") {
  return new Promise((resolve) => {
    const img = new Image();
    img.setAttribute("crossorigin", "anonymous");
    img.src = imageUrl;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.style.display = "none";
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      if (mode === "text") {
        for (const detection of detections) {
          const x_min = detection.text_bbox.x_min,
            y_min = detection.text_bbox.y_min,
            width = detection.text_bbox.x_max - detection.text_bbox.x_min,
            height = detection.text_bbox.y_max - detection.text_bbox.y_min;

          const bboxImageData = ctx.getImageData(x_min, y_min, width, height).data;

          let bboxFillColor = "rgb(255, 255, 255)";
          // if (bboxColorOverwriteMode === "majority") {
          //   bboxFillColor = getMajorityColorForImageData(bboxImageData);
          // } else if (bboxColorOverwriteMode === "average") {
          //   bboxFillColor = getAverageColorForImageData(bboxImageData);
          // }

          ctx.fillStyle = bboxFillColor;
          ctx.fillRect(x_min, y_min, width, height);
        }
      }

      const modifiedImageDataUrl = canvas.toDataURL("image/png");

      //   document.body.removeChild(canvas);

      resolve({
        image: modifiedImageDataUrl,
        width: img.width,
        height: img.height,
      });
    };
  });
}

function getRotatedRectBbox(bbox) {
  const absCos = Math.abs(Math.cos(-bbox.rotationRadians));
  const absSin = Math.abs(Math.sin(-bbox.rotationRadians));

  const newBboxX =
    bbox.x_min +
    (bbox.width / 2) * Math.cos(-bbox.rotationRadians) +
    (bbox.height / 2) * Math.sin(-bbox.rotationRadians);

  const newBboxY =
    bbox.y_min +
    (bbox.width / 2) * Math.sin(+bbox.rotationRadians) +
    (bbox.height / 2) * Math.cos(-bbox.rotationRadians);

  const newBboxWidth = bbox.width * absCos + bbox.height * absSin;
  const newBboxHeight = bbox.width * absSin + bbox.height * absCos;

  return {
    x_min: newBboxX,
    y_min: newBboxY,
    x_max: newBboxX + newBboxWidth,
    y_max: newBboxY + newBboxHeight,
    width: newBboxWidth,
    height: newBboxHeight,
  };
}

function getBboxCroppedImageFromImage(imageUrl, bbox, outputCanvasRef = null) {
  bbox = addExhaustivePropsToBbox(bbox);

  const image = new Image();
  image.src = imageUrl;
  image.crossorigin = "anonymous"; // TODO: is this safe?

  image.onload = () => {
    const imgManipCanvas = document.getElementById("image-manipulation-main-canvas");
    const imgManipCanvasCtx = imgManipCanvas.getContext("2d");
    const imageManipCanvas2 = document.getElementById("image-manipulation-canvas2");
    const imageManipCanvas2Ctx = imageManipCanvas2.getContext("2d");

    const bboxAfterTransform = getRotatedRectBbox(bbox);

    imgManipCanvas.width = bboxAfterTransform.width;
    imgManipCanvas.height = bboxAfterTransform.height;
    imageManipCanvas2.width = bboxAfterTransform.width;
    imageManipCanvas2.height = bboxAfterTransform.height;

    imgManipCanvasCtx.drawImage(
      image,
      bboxAfterTransform.x_min - bboxAfterTransform.width / 2,
      bboxAfterTransform.y_min - bboxAfterTransform.height / 2,
      bboxAfterTransform.width,
      bboxAfterTransform.height,
      0,
      0,
      bboxAfterTransform.width,
      bboxAfterTransform.height
    );

    imageManipCanvas2Ctx.translate(imgManipCanvas.width / 2, imgManipCanvas.height / 2);
    imageManipCanvas2Ctx.rotate(-bbox.rotationRadians);
    imageManipCanvas2Ctx.drawImage(imgManipCanvas, -imgManipCanvas.width / 2, -imgManipCanvas.height / 2);

    const offsetX = bboxAfterTransform.width / 2 - bbox.width / 2;
    const offsetY = bboxAfterTransform.height / 2 - bbox.height / 2;

    const outputCanvas = outputCanvasRef
      ? outputCanvasRef.current
      : document.getElementById("image-manipulation-canvas3");
    const outputCanvasCtx = outputCanvas.getContext("2d");

    outputCanvasCtx.clearRect(0, 0, outputCanvas.width, outputCanvas.height);

    const scaleHorizontal = outputCanvas.width / imageManipCanvas2.width;
    const scaleVertical = outputCanvas.height / imageManipCanvas2.height;
    const scale = Math.min(scaleHorizontal, scaleVertical);
    // console.log(scaleHorizontal);
    // console.log(scaleVertical);

    outputCanvasCtx.drawImage(
      imageManipCanvas2,
      -offsetX,
      -offsetY,
      imageManipCanvas2.width,
      imageManipCanvas2.height,
      0,
      0,
      imageManipCanvas2.width * scale,
      imageManipCanvas2.height * scale
    );

    // outputCanvas.width = bbox.width;
    // outputCanvas.height = bbox.height;
    // outputCanvasCtx.drawImage(imageManipCanvas2, -offsetX, -offsetY);

    // return outputCanvas.toDataURL("image/png");
  };
}

export { loadImageAndRemoveDetectedText, getBboxCroppedImageFromImage };
