function moveDetectionLeft(detection, amount) {
  detection.x -= amount;
  return detection;
}

function moveDetectionRight(detection, amount) {
  detection.x += amount;
  return detection;
}

function moveDetectionUp(detection, amount) {
  detection.y -= amount;
  return detection;
}

function moveDetectionDown(detection, amount) {
  detection.y += amount;
  return detection;
}

function rotateDetectionLeft(detection, amount) {
  detection.rotation -= amount;
  return detection;
}

function rotateDetectionRight(detection, amount) {
  detection.rotation += amount;
  return detection;
}

function scaleDetectionLeft(detection, amount) {
  detection.width -= amount;
  return detection;
}

function scaleDetectionRight(detection, amount) {
  detection.width += amount;
  return detection;
}

function scaleDetectionUp(detection, amount) {
  detection.height -= amount;
  return detection;
}

function scaleDetectionDown(detection, amount) {
  detection.height += amount;
  return detection;
}

function centroid2D(points) {
  let sumX = 0,
    sumY = 0;
  for (const point of points) {
    sumX += point[0];
    sumY += point[1];
  }

  return [sumX / points.length, sumY / points.length];
}

function distance2D(point1, point2) {
  return Math.sqrt(Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2));
}

function rectContainsRect(parentRect, childRect) {
  return (
    parentRect.xMin <= childRect.xMin &&
    parentRect.yMin <= childRect.yMin &&
    parentRect.xMax >= childRect.xMax &&
    parentRect.yMax >= childRect.yMax
  );
}

function convertFractionStringToFloat(fractionString) {
  const fractionComponents = fractionString.split("/");

  return (parseFloat(fractionComponents[0]) - 1) / parseFloat(fractionComponents[1]);
}

function getRectForRotatedBbox(bbox) {
  const absCos = Math.abs(Math.cos(bbox.rotation));
  const absSin = Math.abs(Math.sin(bbox.rotation));

  const cx =
    bbox.x_min +
    ((bbox.x_max - bbox.x_min) / 2) * Math.cos(bbox.rotation) -
    ((bbox.y_max - bbox.y_min) / 2) * Math.sin(bbox.rotation);
  const cy =
    bbox.y_min +
    ((bbox.x_max - bbox.x_min) / 2) * Math.sin(bbox.rotation) +
    ((bbox.y_max - bbox.y_min) / 2) * Math.cos(bbox.rotation);

  const w = (bbox.x_max - bbox.x_min) * absCos + (bbox.y_max - bbox.y_min) * absSin;
  const h = (bbox.x_max - bbox.x_min) * absSin + (bbox.y_max - bbox.y_min) * absCos;

  return {
    cx: cx,
    cy: cy,
    width: w,
    height: h,
  };
}

export {
  centroid2D,
  distance2D,
  rectContainsRect,
  convertFractionStringToFloat,
  moveDetectionLeft,
  moveDetectionRight,
  moveDetectionUp,
  moveDetectionDown,
  rotateDetectionLeft,
  rotateDetectionRight,
  scaleDetectionLeft,
  scaleDetectionRight,
  scaleDetectionUp,
  scaleDetectionDown,
  getRectForRotatedBbox,
};
