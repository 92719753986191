import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  TextField,
  Typography,
  List,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BeatLoader } from "react-spinners";

import { toast } from "react-toastify";
import MergeUploadsListItem from "./MergeUploadsListItem";
import apiClient from "../api/apiServices";

export default function MergeUploadsDialog({
  open,
  setOpen,
  selectedUploadsIds,
  setSelectedUploadsIds,
  refetchAllUploads,
}) {
  const classes = useStyles();

  const [isLoadingMetadata, setIsLoadingMetadata] = useState(false);
  const [loadingMetadataProcessingStatus, setLoadingMetadataProcessingStatus] = useState("Initializing ...");
  const [loadingMetadataProcessingProgressPercentage, setLoadingMetadataProcessingProgressPercentage] = useState(0.0);

  const [isMerging, setIsMerging] = useState(false);
  const [mergeSelectedUploads, setMergeSelectedUploads] = useState([]);
  const [newUploadFilename, setNewUploadFilename] = useState("Merged Filename");

  const getUploads = async () => {
    setIsLoadingMetadata(true);
    setLoadingMetadataProcessingStatus("Initializing ...");
    setLoadingMetadataProcessingProgressPercentage(0.0);

    const updatedMergeSelectedUploads = [];

    for (let i = 0; i < selectedUploadsIds.length; i++) {
      const getUploadResponse = await apiClient.uploads.getSingle(selectedUploadsIds[i]);
      if (!getUploadResponse.success) {
        console.log(getUploadResponse.error);
        continue;
      }

      updatedMergeSelectedUploads.push(getUploadResponse.result.upload);

      setLoadingMetadataProcessingProgressPercentage(
        100 * parseFloat(((i + 1) / selectedUploadsIds.length).toFixed(4))
      );
    }

    setMergeSelectedUploads(updatedMergeSelectedUploads);

    setLoadingMetadataProcessingStatus("Uploads loaded successfully.");
    setIsLoadingMetadata(false);
  };

  const handleMergeButtonClick = async (event) => {
    setIsMerging(true);

    const uploadIds = mergeSelectedUploads.map((upload) => upload.id);

    const response = await apiClient.uploads.merge(uploadIds, newUploadFilename);

    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      setIsMerging(false);
      return;
    }

    await refetchAllUploads();
    setSelectedUploadsIds([]);

    toast("Merge successful.");
    setIsMerging(false);
    setOpen(false);
  };

  useEffect(() => {
    if (!selectedUploadsIds || selectedUploadsIds.length === 0) {
      return;
    }

    getUploads();
  }, [selectedUploadsIds]);

  if (isLoadingMetadata) {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => {
          if (isMerging) {
            return;
          }
          setOpen(false);
        }}
      >
        <DialogTitle>Merge Uploads</DialogTitle>

        <DialogContent>
          <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box width="100%" display="flex" justifyContent="flex-start">
              <Typography variant="p">{loadingMetadataProcessingStatus}</Typography>
            </Box>

            {loadingMetadataProcessingProgressPercentage === 0.0 ? (
              <BeatLoader color="blue" loading={true} size={14} />
            ) : (
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <LinearProgress
                  sx={{ width: "45vw", marginRight: 2 }}
                  color="primary"
                  variant="determinate"
                  value={loadingMetadataProcessingProgressPercentage}
                />
                <p>
                  <strong>{`${loadingMetadataProcessingProgressPercentage.toFixed(2)} %`}</strong>
                </p>
              </Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Box height={100}>
            <Button variant="contained" color="error" onClick={handleMergeButtonClick} disabled={isMerging}>
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        if (isMerging) {
          return;
        }
        setOpen(false);
      }}
    >
      <DialogTitle>Merge Uploads</DialogTitle>

      <DialogContent>
        <Box
          width="100%"
          maxWidth="md"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <Box width="30%">
            <Typography variant="h6">Filename:</Typography>
          </Box>

          <TextField
            className={classes.dialogInputTextField}
            type="text"
            value={newUploadFilename}
            onChange={(event) => setNewUploadFilename(event.target.value)}
          />
        </Box>

        <Box
          width="100%"
          maxWidth="md"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <List style={{ width: "100%", height: "45vh", overflow: "auto" }}>
            {mergeSelectedUploads.map((upload, index) => (
              <MergeUploadsListItem
                uploadObject={upload}
                onMoveUp={() => {
                  const updatedMergeSelectedUploads = [...mergeSelectedUploads];
                  var temp = updatedMergeSelectedUploads[index - 1];
                  updatedMergeSelectedUploads[index - 1] = updatedMergeSelectedUploads[index];
                  updatedMergeSelectedUploads[index] = temp;
                  setMergeSelectedUploads(updatedMergeSelectedUploads);
                }}
                onMoveDown={() => {
                  const updatedMergeSelectedUploads = [...mergeSelectedUploads];
                  var temp = updatedMergeSelectedUploads[index + 1];
                  updatedMergeSelectedUploads[index + 1] = updatedMergeSelectedUploads[index];
                  updatedMergeSelectedUploads[index] = temp;
                  setMergeSelectedUploads(updatedMergeSelectedUploads);
                }}
                onDelete={() => {
                  const updatedMergeSelectedUploads = [];
                  for (let i = 0; i < mergeSelectedUploads.length; i++) {
                    if (i === index) {
                      continue;
                    }
                    updatedMergeSelectedUploads.push(mergeSelectedUploads[i]);
                  }
                  setMergeSelectedUploads(updatedMergeSelectedUploads);
                }}
                moveUpEnabled={index > 0}
                moveDownEnabled={index < mergeSelectedUploads.length - 1}
                deleteEnabled={mergeSelectedUploads.length > 2}
                index={index}
                key={upload.id}
              />
            ))}
          </List>
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Box height={100}>
          <Button variant="contained" color="primary" onClick={handleMergeButtonClick} disabled={isMerging}>
            {isMerging ? <BeatLoader color="blue" loading={true} size={14} /> : "Merge"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  dialogInputTextField: {
    width: "50%",
  },
  dialogActions: {
    "&&": {
      justifyContent: "center",
    },
  },
}));
