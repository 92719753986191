function getHumanReadableFieldName(fieldName) {
  return fieldName;
}

function parseUploadObjectForFrontend(upload) {}

function parseUploadProcessingStatusForFrontend(processingStatusString) {
  let processingStatus;
  try {
    processingStatus = JSON.parse(processingStatusString);
  } catch (error) {
    console.log(error);
    return {
      statusCode: 4,
      statusString:
        "Failed to get processing status. Please refresh the page to try again.",
      pagesProgress: 0.0,
      bboxesProgress: 0.0,
    };
  }

  switch (processingStatus.statusCode) {
    case 0: {
      return {
        statusCode: processingStatus.statusCode,
        statusString: processingStatus.statusString,
        pagesProgress: 0.0,
        bboxesProgress: 0.0,
      };
    }
    case 1: {
      const statusPagesFractionComponents =
        processingStatus.pagesProgress.split("/");
      const pagesProgress =
        (parseFloat(statusPagesFractionComponents[0]) - 1) /
        parseFloat(statusPagesFractionComponents[1]);

      return {
        statusCode: processingStatus.statusCode,
        statusString: processingStatus.statusString,
        pagesProgress: pagesProgress * 100,
        bboxesProgress: 0.0,
      };
    }
    case 2: {
      const statusPagesFractionComponents =
        processingStatus.pagesProgress.split("/");
      const pagesProgress =
        (parseFloat(statusPagesFractionComponents[0]) - 1) /
        parseFloat(statusPagesFractionComponents[1]);

      return {
        statusCode: processingStatus.statusCode,
        statusString: `Processing page ${statusPagesFractionComponents[0]} of ${statusPagesFractionComponents[1]}`,
        pagesProgress: pagesProgress * 100,
        bboxesProgress: 0.0,
      };
    }
    case 3: {
      const statusPagesFractionComponents =
        processingStatus.pagesProgress.split("/");
      const statusBboxesFractionComponents =
        processingStatus.bboxesProgress.split("/");

      const pagesProgress =
        (parseFloat(statusPagesFractionComponents[0]) - 1) /
          parseFloat(statusPagesFractionComponents[1]) +
        (parseFloat(statusBboxesFractionComponents[0]) - 1) /
          parseFloat(statusBboxesFractionComponents[1]) /
          parseFloat(statusPagesFractionComponents[1]);
      const bboxesProgress =
        (parseFloat(statusBboxesFractionComponents[0]) - 1) /
        parseFloat(statusBboxesFractionComponents[1]);

      return {
        statusCode: processingStatus.statusCode,
        statusString: `Processing page ${statusPagesFractionComponents[0]} of ${statusPagesFractionComponents[1]}: Bbox ${statusBboxesFractionComponents[0]} of ${statusBboxesFractionComponents[1]}`,
        pagesProgress: pagesProgress * 100,
        bboxesProgress: bboxesProgress * 100,
      };
    }
    // case 4: {
    //   break;
    // }
    case 5: {
      return {
        statusCode: processingStatus.statusCode,
        statusString: "Processing Completed.",
        pagesProgress: 100,
        bboxesProgress: 100,
      };
    }
    case 6: {
      return {
        statusCode: processingStatus.statusCode,
        statusString: "Processing Error.",
        pagesProgress: 0,
        bboxesProgress: 0,
      };
    }
    case 7: {
      return {
        statusCode: processingStatus.statusCode,
        statusString: "Upload Cancelled",
      };
    }
    default: {
      return {
        statusCode: processingStatus.statusCode,
        statusString: "Processing Error.",
        pagesProgress: 0,
        bboxesProgress: 0,
      };
    }
  }

  if (processingStatus.statusCode === 5) {
    return {
      statusCode: processingStatus.statusCode,
      statusString: "Processing Completed.",
      pagesProgress: 100,
      bboxesProgress: 100,
    };
  }

  if (processingStatus.statusCode === 6) {
    return {
      statusCode: processingStatus.statusCode,
      statusString: "Processing Error.",
      pagesProgress: 0,
      bboxesProgress: 0,
    };
  }

  if (processingStatus.statusCode === 0) {
    return {
      statusCode: processingStatus.statusCode,
      statusString: processingStatus.statusString,
      pagesProgress: 0,
      bboxesProgress: 0,
    };
  }

  let pagesProgress = 0.0;
  let bboxesProgress = 0.0;
  let updatedStatusString = "";

  if (
    [1, 2, 3, 4].includes(processingStatus.statusCode) &&
    [3, 4].includes(processingStatus.statusCode)
  ) {
    const statusPagesFractionComponents =
      processingStatus.pagesProgress.split("/");
    const statusBboxesFractionComponents =
      processingStatus.bboxesProgress.split("/");

    pagesProgress =
      (parseFloat(statusPagesFractionComponents[0]) - 1) /
        parseFloat(statusPagesFractionComponents[1]) +
      (parseFloat(statusBboxesFractionComponents[0]) - 1) /
        parseFloat(statusBboxesFractionComponents[1]) /
        parseFloat(statusPagesFractionComponents[1]);

    bboxesProgress =
      (parseFloat(statusBboxesFractionComponents[0]) - 1) /
      parseFloat(statusBboxesFractionComponents[1]);

    updatedStatusString = `Processing page ${statusPagesFractionComponents[0]} of ${statusPagesFractionComponents[1]}: Bbox ${statusBboxesFractionComponents[0]} of ${statusBboxesFractionComponents[1]}`;
  } else if ([1, 2, 3, 4].includes(processingStatus.statusCode)) {
    const statusPagesFractionComponents =
      processingStatus.pagesProgress.split("/");
    pagesProgress =
      (parseFloat(statusPagesFractionComponents[0]) - 1) /
      parseFloat(statusPagesFractionComponents[1]);
    updatedStatusString = `Processing page ${statusPagesFractionComponents[0]} of ${statusPagesFractionComponents[1]}`;
  }

  return {
    statusCode: processingStatus.statusCode,
    statusString: updatedStatusString,
    pagesProgress: pagesProgress * 100,
    bboxesProgress: bboxesProgress * 100,
  };
}

const lineLevelDocumentParsers = [
  "tess_line_level",
  "kraken_line_level",
];

export {
  getHumanReadableFieldName,
  parseUploadObjectForFrontend,
  parseUploadProcessingStatusForFrontend,
  lineLevelDocumentParsers,
};
