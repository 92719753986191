import { Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default function CustomSelect({
  placeholder,
  selectedValue,
  setSelectedValue,
  allValues,
  disabled,
}) {
  const classes = useStyles();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Select
      className={classes.selectMain}
      value={selectedValue}
      onChange={handleChange}
      displayEmpty
      renderValue={selectedValue !== "" ? undefined : () => placeholder}
      disabled={disabled}
    >
      {allValues?.map((value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
}

const useStyles = makeStyles((theme) => ({
  selectMain: {
    width: "100%",
  },
}));
