import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import TeamPageCard from "../components/TeamPageCard";

export default function Team() {
  const classes = useStyles();

  useEffect(() => {
    document.title = "Lipikar - Team";
  }, []);

  return (
    <div className={classes.pageContainer}>
      <Container className={classes.container}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" mb={3}>
          <Typography variant="h3" className={classes.heading} style={{ fontWeight: "bold", textAlign: "center" }}>
            The Lipikar Team
          </Typography>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={3}
          p={2}
          pb={5}
          sx={{ backgroundColor: "white", borderRadius: 5 }}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={2}
          >
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Professors
            </Typography>
          </Box>

          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" m={0}>
            <TeamPageCard
              avatarImageSrc="/static/team-members-images/dr_chetan_arora.jpg"
              profileLink="http://www.cse.iitd.ernet.in/~chetan/"
              title="Dr. Chetan Arora"
              descriptionLines={["Professor, IIT Delhi", "Computer Vision, Machine Learning"]}
            />

            <TeamPageCard
              avatarImageSrc="/static/team-members-images/dr_arjun_ghosh.jpg"
              profileLink="https://hss.iitd.ac.in/faculty/arjun"
              title="Dr. Arjun Ghosh"
              descriptionLines={[
                "Professor, IIT Delhi",
                "Digital Humanities, Performance Studies, Technology in Society",
              ]}
            />
          </Box>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={3}
          p={2}
          pb={5}
          sx={{ backgroundColor: "white", borderRadius: 5 }}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={2}
          >
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Developers
            </Typography>
          </Box>

          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" m={0}>
            <TeamPageCard
              avatarImageSrc="/static/team-members-images/abhilaksh_singh_reen.jpg"
              profileLink="https://www.linkedin.com/in/abhilaksh-singh-837901210"
              title="Abhilaksh Singh Reen"
              descriptionLines={[
                "Full Stack Developer",
                "Python Backends, SQL Databases, Redis, Docker, React and Redux",
              ]}
            />
          </Box>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={3}
          p={2}
          pb={5}
          sx={{ backgroundColor: "white", borderRadius: 5 }}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={2}
          >
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Researchers
            </Typography>
          </Box>

          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" mb={2}>
            <TeamPageCard
              avatarImageSrc="/static/team-members-images/dr_arpan_garai.jpg"
              profileLink="https://in.linkedin.com/in/arpan-garai-6a97036a"
              title="Dr. Arpan Garai"
              descriptionLines={["Post Doctoral Fellow", "Document Image Analysis"]}
            />
          </Box>

          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" m={0}>
            <TeamPageCard
              avatarImageSrc="/static/team-members-images/nishit_anand.jpg"
              profileLink="https://www.linkedin.com/in/nishitanand/"
              title="Nishit Anand"
              descriptionLines={["Research Assistant", "Document & Scene-Text OCR, Active Learning"]}
            />

            <TeamPageCard
              avatarImageSrc="/static/team-members-images/shashank_krishna_vempati.jpg"
              profileLink="http://linkedin.com/in/shashank-krishna-vempati"
              title="Shashank K. Vempati"
              descriptionLines={["Masters Student", "Optical Character Recognition"]}
            />
          </Box>
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#eae9e2, #eae3e0, #bdc4bc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 25,
    marginBottom: 100,
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  contentImage: {
    maxWidth: "50%",
    border: "1px solid #000",
    margin: 20,
  },
}));
