const urlParser = new URL(window.location.href);
const urlOrigin = urlParser.origin;

let apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
let mediaBaseUrl = process.env.REACT_APP_MEDIA_BASE_URL;

if (!process.env.REACT_APP_API_BASE_URL) {
  apiBaseUrl = urlOrigin + "/api/ocr";
  console.warn(`Environment variable REACT_APP_API_BASE_URL not defined, will default to ${apiBaseUrl}`);
}

if (!process.env.REACT_APP_MEDIA_BASE_URL) {
  mediaBaseUrl = urlOrigin + "/media";
  console.warn(`Environment variable REACT_APP_MEDIA_BASE_URL not defined, will default to ${mediaBaseUrl}`);
}

const detectionImageUrlPrefix = mediaBaseUrl + "/detection_images/";

const apiEndpoints = {
  config: {
    getBackendConfig: `${apiBaseUrl}/config/`,
  },
  auth: {
    login: `${apiBaseUrl}/auth/login/`,
    logout: `${apiBaseUrl}/auth/logout/`,
    register: `${apiBaseUrl}/auth/register/`,
    obtainToken: `${apiBaseUrl}/auth/token/`,
    refreshToken: `${apiBaseUrl}/auth/token/refresh/`,
    resetPassword: `${apiBaseUrl}/auth/reset-password/`,
  },
  user: {
    credits: `${apiBaseUrl}/user/credits/`,
  },
  organizations: {
    getAll: `${apiBaseUrl}/organizations`,
  },
  uploads: {
    addNew: `${apiBaseUrl}/uploads/?document_parser={{document_parser}}&parsing_postprocessor={{parsing_postprocessor}}&text_recognizer={{text_recognizer}}`,
    processNew: `${apiBaseUrl}/uploads/?id={{id}}`,
    getMultiple: `${apiBaseUrl}/uploads/`,
    getDetail: `${apiBaseUrl}/uploads/?id={{id}}`,
    delete: `${apiBaseUrl}/uploads/`,
    changeFilename: `${apiBaseUrl}/uploads/change-filename/?id={{id}}`,
    merge: `${apiBaseUrl}/uploads/merge/`,
    cancel: `${apiBaseUrl}/uploads/cancel/?id={{id}}`,
    importSingle: `${apiBaseUrl}/uploads/import-single/`,
    history: `${apiBaseUrl}/uploads/history/`,
  },
  detections: {
    getAllForUpload: `${apiBaseUrl}/detections/?uploadId={{uploadId}}`,
    update: `${apiBaseUrl}/detections/?id={{id}}`,
  },
  customOcr: {
    getDetectionsForImageAndBbox: `${apiBaseUrl}/custom-ocr/?detectionId={{detectionId}}&xMin={{xMin}}&yMin={{yMin}}&xMax={{xMax}}&yMax={{yMax}}&rotation={{rotation}}`,
  },
  utils: {
    transliterate: `${apiBaseUrl}/utils/transliterate/`,
    generatePdf: `${apiBaseUrl}/utils/generate-pdf/`,
  },
};

export default apiEndpoints;
export { apiBaseUrl, mediaBaseUrl, detectionImageUrlPrefix };
