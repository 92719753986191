import jsPDF from "jspdf";

import { detectionImageUrlPrefix } from "../api/apiEndpoints";
import { loadImageAndRemoveDetectedText } from "./imageUtils";
import { decodeUnicodeEscape } from "./stringUtils";

async function imageUrlsToPdfBlob(imageUrls) {
  // Getting page size and setting up the PDF
  const img = new Image();
  img.src = imageUrls[0];
  const imgLoaded = new Promise((resolve) => {
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
  });

  const imageSize = await imgLoaded;

  // Construct the pdf object and delete the first page that is automatically created
  const pdf = new jsPDF({
    orientation: imageSize.width > imageSize.height ? "l" : "p",
    unit: "pt",
    format: [imageSize.width, imageSize.height],
  });
  pdf.deletePage(1);

  // Loop through the image URLs and add each one to a new page in the PDF
  imageUrls.forEach((imageUrl, index) => {
    pdf.addPage();
    pdf.addImage(imageUrl, "JPEG", 0, 0, imageSize.width, imageSize.height);
  });

  // Save the PDF as a blob and return it
  const pdfBlob = pdf.output("blob");

  return pdfBlob;
}

function adjustFontSizeToFitText(pdf, text, maxWidth, maxHeight, minFontSize = 8, maxFontSize = 40) {
  let fontSize;
  let textWidth;
  let textHeight;
  let low = minFontSize;
  let high = maxFontSize;

  while (low <= high) {
    fontSize = Math.floor((low + high) / 2);
    pdf.setFontSize(fontSize);
    textWidth = pdf.getStringUnitWidth(text) * fontSize;
    textHeight = fontSize;

    if (textWidth <= maxWidth && textHeight <= maxHeight) {
      low = fontSize + 1;
    } else {
      high = fontSize - 1;
    }
  }

  return fontSize;
}

async function detectionsToPdfBlob(allDetectionsForUpload, output_filename, mode = "text") {
  const modifiedDetectionImages = [];
  let maxWidth = -1,
    maxHeight = -1;
  for (const pageDetection of allDetectionsForUpload) {
    const detectionImageUrl = detectionImageUrlPrefix + pageDetection.image_filename;
    const modifiedImageData = await loadImageAndRemoveDetectedText(detectionImageUrl, pageDetection.detections, mode);

    modifiedDetectionImages.push(modifiedImageData);
    maxWidth = Math.max(maxWidth, modifiedImageData.width);
    maxHeight = Math.max(maxHeight, modifiedImageData.height);
  }

  // Construct the pdf object and delete the first page that is automatically created
  const pdf = new jsPDF({
    orientation: maxWidth > maxHeight ? "l" : "p",
    unit: "pt",
    format: [maxWidth, maxHeight],
  });
  pdf.deletePage(1);

  for (let i = 0; i < allDetectionsForUpload.length; i++) {
    const pageDetection = allDetectionsForUpload[i];
    const modifiedImageData = modifiedDetectionImages[i];

    pdf.addPage();
    pdf.addImage(modifiedImageData.image, "PNG", 0, 0, modifiedImageData.width, modifiedImageData.height);

    for (const detection of pageDetection.detections) {
      const x_min = detection.text_bbox.x_min,
        y_min = detection.text_bbox.y_min,
        width = detection.text_bbox.x_max - detection.text_bbox.x_min,
        height = detection.text_bbox.y_max - detection.text_bbox.y_min;

      const detectionText = decodeUnicodeEscape(detection.text);

      const adjustedFontSize = adjustFontSizeToFitText(pdf, detectionText, width, height, 4, 150);

      console.log(`Font size = ${adjustedFontSize}`);
      console.log(x_min, y_min);
      console.log(`Text = `);
      console.log(detectionText);
      pdf.setFontSize(adjustedFontSize);
      pdf.setFont("helvetica", "normal");

      if (mode === "transparent") {
        pdf.saveGraphicsState();
        pdf.setGState(new pdf.GState({ opacity: 0 }));
      }

      pdf.text(detectionText, x_min, y_min + adjustedFontSize);

      if (mode === "transparent") {
        pdf.restoreGraphicsState();
      }
    }
  }

  pdf.save(output_filename);
}

export { imageUrlsToPdfBlob, detectionsToPdfBlob };
