import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BeatLoader } from "react-spinners";

import { generateAndDownloadZipFileForMultipleUploads } from "../utils/downloadUtils";
import { toUpperFirst } from "../utils/stringUtils";

export default function ExportUploadsDialog({ open, setOpen, selectedUploadsIds, setSelectedUploadsIds }) {
  const classes = useStyles();

  const [isExporting, setIsExporting] = useState(false);
  const [processingStatus, setProcessingStatus] = useState("Initializing ...");
  const [processingProgressPercentage, setProcessingProgressPercentage] = useState(0.0);

  const zipUploadsStatusUpdateCallback = (statusUpdate) => {
    setProcessingStatus(toUpperFirst(statusUpdate.currentOperation) + " ...");
    setProcessingProgressPercentage(100 * statusUpdate.currentOperationProgress);
  };

  const zipUploads = async () => {
    setProcessingStatus("Initializing ...");
    setProcessingProgressPercentage(0.0);
    setIsExporting(true);
    await generateAndDownloadZipFileForMultipleUploads(selectedUploadsIds, zipUploadsStatusUpdateCallback);
    setSelectedUploadsIds([]);
    setIsExporting(false);
    setProcessingStatus("Done!");
  };

  useEffect(() => {
    if (!selectedUploadsIds || selectedUploadsIds.length === 0) {
      return;
    }

    zipUploads();
  }, [selectedUploadsIds]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        if (isExporting) {
          return;
        }
        setOpen(false);
      }}
    >
      <DialogTitle>Export Uploads</DialogTitle>

      <DialogContent>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box width="100%" display="flex" justifyContent="flex-start">
            <Typography variant="p">{processingStatus}</Typography>
          </Box>

          {processingProgressPercentage === 0.0 ? (
            <BeatLoader color="blue" loading={true} size={14} />
          ) : (
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
              <LinearProgress
                sx={{ width: "45vw", marginRight: 2 }}
                color="primary"
                variant="determinate"
                value={processingProgressPercentage}
              />
              <p>
                <strong>{`${processingProgressPercentage.toFixed(2)} %`}</strong>
              </p>
            </Box>
          )}
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Box display="flex" justifyContent="flex-end" width="50%">
          {processingProgressPercentage === 100 ? (
            <Button variant="contained" color="primary" onClick={() => setOpen(false)} sx={{ marginLeft: 2 }}>
              Back to History Page
            </Button>
          ) : null}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    "&&": {
      justifyContent: "flex-end",
    },
  },
}));
