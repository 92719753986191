import { useState, useEffect, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import { IconButton, Typography, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  DeleteForever as DeleteForeverIcon,
  Refresh as RefreshIcon,
  Merge as MergeIcon,
  FileUpload as FileUploadIcon,
  FileDownload as FileDownloadIcon,
  Deselect as DeselectIcon,
} from "@mui/icons-material";
import { ClipLoader } from "react-spinners";

import userContext from "../context/userContext";
import apiClient from "../api/apiServices";
import { mediaBaseUrl } from "../api/apiEndpoints";
import { imageUrlsToPdfBlob } from "../utils/pdfUtils";
import UploadListItem from "../components/UploadListItem";
import MergeUploadsDialog from "../components/MergeUploadsDialog";
import DeleteUploadsDialog from "../components/DeleteUploadsDialog";
import ImportUploadsDialog from "../components/ImportUploadsDialog";
import ExportUploadsDialog from "../components/ExportUploadsDialog";
import UploadListItemHeader from "../components/UploadListItemHeader";
import MuiButtonWithTooltip from "../components/MuiButtonWithTooltip";
import { numberWithCommas } from "../utils/stringUtils";

const numAllowedStatusUpdateRequestsPerSecond = 0.2;
const processedUploadsStatusCodes = [5, 6, 7];

export default function History() {
  const classes = useStyles();
  const { user } = useContext(userContext);
  const { pageNum } = useParams();

  const [currentPageNum, setCurrentPageNum] = useState(parseInt(pageNum, 10));

  const [allUploadsSelected, setAllUploadsSelected] = useState(false);
  const [allUploadsOfUser, setAllUploadsOfUser] = useState([]);
  const [uploadsPageMetadata, setUploadsPageMetadata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isGeneratingPdfForUploadId, setIsGeneratingPdfForUploadId] = useState(-1);
  const [uploadsSortingKey, setUploadsSortingKey] = useState("-created_at");
  const [mergeUploadsDialogOpen, setMergeUploadsDialogOpen] = useState(false);
  const [mergeSelectedUploadIds, setMergeSelectedUploadIds] = useState([]);
  const [statusUpdateRequestInterval, setStatusUpdateRequestInterval] = useState(10000);

  const [numUploadsOfUser, setNumUploadsOfUser] = useState(-1);

  const [deleteUploadsDialogOpen, setDeleteUploadsDialogOpen] = useState(false);
  const [deleteUploadsSelectedUploadIds, setDeleteUploadsSelectedUploadIds] = useState([]);

  const [allPagesSelectedUploadIds, setAllPagesSelectedUploadIds] = useState(new Set());
  const [currentPageSelectedUploadIds, setCurrentPageSelectedUploadIds] = useState(new Set());
  const [selectableUploadsCount, setSelectableUploadsCount] = useState(-1);
  const [getQueryLatestUploadIds, setGetQueryLatestUploadIds] = useState([undefined]);

  const [importUploadsDialogOpen, setImportUploadsDialogOpen] = useState(false);
  const [importUploadsSelectedDirectoryHandle, setImportUploadsSelectedDirectoryHandle] = useState(null);

  const [exportUploadsDialogOpen, setExportUploadsDialogOpen] = useState(false);
  const [exportUploadsSelectedUploadIds, setExportUploadsSelectedUploadIds] = useState([]);

  const sortUploads = (uploads, sortingKey) => {
    switch (sortingKey) {
      case "id": {
        uploads.sort((a, b) => {
          return a.id - b.id;
        });
        break;
      }
      case "-id": {
        uploads.sort((a, b) => {
          return b.id - a.id;
        });
        break;
      }
      case "created_at": {
        uploads.sort((a, b) => {
          const d1 = new Date(a.created_at);
          const d2 = new Date(b.created_at);
          return d1 - d2;
        });
        break;
      }
      case "-created_at": {
        uploads.sort((a, b) => {
          const d1 = new Date(a.created_at);
          const d2 = new Date(b.created_at);
          return d2 - d1;
        });
        break;
      }
      case "filename": {
        uploads.sort((a, b) => {
          if (a.filename > b.filename) {
            return 1;
          }
          if (a.filename < b.filename) {
            return -1;
          }
          return 0;
        });
        break;
      }
      case "-filename": {
        uploads.sort((a, b) => {
          if (a.filename > b.filename) {
            return -1;
          }
          if (a.filename < b.filename) {
            return 1;
          }
          return 0;
        });
        break;
      }
      case "numPages": {
        uploads.sort((a, b) => {
          return b.detection_ids.length - a.detection_ids.length;
        });
        break;
      }
      case "-numPages": {
        uploads.sort((a, b) => {
          return a.detection_ids.length - b.detection_ids.length;
        });
        break;
      }
      default: {
      }
    }

    return uploads;
  };

  const getAllUploadsFromApi = async (targetPageNum = parseInt(pageNum, 10)) => {
    setLoading(true);

    const response = await apiClient.uploads.getHistory(targetPageNum);
    if (!response.success) {
      window.alert("An error occurred while fetching the uploads.");
      console.log(response.error);
      setLoading(false);
      return;
    }

    setUploadsPageMetadata({
      currentPage: response.result.currentPage,
      totalPages: response.result.totalPages,
      currentPageFirstUploadNum: response.result.currentPageFirstUploadNum,
      currentPageLastUploadNum: response.result.currentPageLastUploadNum,
      numTotalUploadsOfUser: response.result.numTotalUploadsOfUser,
    });

    console.log(typeof response.result.currentPage);

    const uploads = response.result.uploads;

    let numProcessedUploads = 0;
    uploads.forEach((upload, index, sourceArray) => {
      try {
        const processingStatus = JSON.parse(upload.processing_status);
        numProcessedUploads += processedUploadsStatusCodes.includes(processingStatus.statusCode);
      } catch (error) {
        console.log(error);
      }

      sourceArray[index].detection_ids = JSON.parse(upload.detection_ids);
    });

    const sortedUploads = sortUploads(uploads, uploadsSortingKey);

    const numUnprocessedUploads = uploads.length - numProcessedUploads;
    if (numUnprocessedUploads !== 0) {
      // setStatusUpdateRequestInterval(1 / (numAllowedStatusUpdateRequestsPerSecond / numUnprocessedUploads));
      setStatusUpdateRequestInterval(5000);
    }

    setAllUploadsOfUser(sortedUploads);
    setAllUploadsSelected(false);
    setSelectableUploadsCount(numProcessedUploads);
    setLoading(false);
  };

  const selectAllCheckboxOnClick = (isChecked) => {
    const updatedSelectedUploadsIds = new Set();
    if (isChecked) {
      for (const upload of allUploadsOfUser) {
        updatedSelectedUploadsIds.add(upload.id);
      }
    }

    setCurrentPageSelectedUploadIds(updatedSelectedUploadsIds);
    setAllUploadsSelected(isChecked);
  };

  const uploadCheckboxOnClick = (uploadId, isChecked) => {
    const updatedSelectedUploadsIds = new Set(currentPageSelectedUploadIds);

    if (isChecked) {
      updatedSelectedUploadsIds.add(uploadId);
    } else {
      updatedSelectedUploadsIds.delete(uploadId);
    }

    setCurrentPageSelectedUploadIds(updatedSelectedUploadsIds);
  };

  const deleteButtonOnClick = async () => {
    if (allPagesSelectedUploadIds.size === 0) {
      window.alert("Please select one or more uploads to delete.");
      return;
    }

    const entityNamePlural = "upload" + (allPagesSelectedUploadIds.size > 1 ? "s" : "");
    if (
      !window.confirm(
        `Are you sure you want to deleted the ${allPagesSelectedUploadIds.size} selected ${entityNamePlural}? This operation cannot be reversed!`
      )
    ) {
      return;
    }

    setDeleteUploadsSelectedUploadIds([...allPagesSelectedUploadIds]);
    setDeleteUploadsDialogOpen(true);
  };

  const handleListItemFilenameClick = async (uploadId) => {
    // Get all the detections for this upload
    const response = await apiClient.detections.getAllForUpload(uploadId, true);
    if (!response.success) {
      window.alert("An error occurred while fetching the file.");
      console.log(response.error);
      return;
    }
    console.log(response);

    // additional parsing
    const imageUrls = response.result.detections.map(
      (detection) => `${mediaBaseUrl}/detection_images/${detection.image_filename}`
    );

    if (imageUrls.length === 1) {
      // single image, just open the media file link
      window.open(imageUrls[0], "_blank");
      return;
    }

    setIsGeneratingPdfForUploadId(uploadId);

    // Else, we have multiple images, need to download them and convert them into a pdf
    // Get the PDF Blob using the Utility function
    const pdfBlob = await imageUrlsToPdfBlob(imageUrls);
    // Create a URL for the PDF blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, "_blank");
    setIsGeneratingPdfForUploadId(-1);
  };

  const handleDeselectAllButtonClick = async () => {
    setAllPagesSelectedUploadIds(new Set());
    setCurrentPageSelectedUploadIds(new Set());
  };

  const handleMergeButtonClick = async (event) => {
    if (allPagesSelectedUploadIds.size < 2) {
      window.alert("Please select at least 2 or more uploads to merge them.");
      return;
    }

    setMergeSelectedUploadIds([...allPagesSelectedUploadIds]);
    setMergeUploadsDialogOpen(true);
  };

  const handleRefreshButtonClick = () => {
    if (
      getQueryLatestUploadIds.length > 1 &&
      !window.confirm("Refreshing will take you back to the first page. Do you want to continue?")
    ) {
      return;
    }

    window.location.reload();
  };

  const handleImportButtonClick = async (event) => {
    try {
      // const directoryHandle = await window.showDirectoryPicker();
      // setImportUploadsSelectedDirectoryHandle(directoryHandle);
      setImportUploadsDialogOpen(true);
    } catch (error) {
      console.error("Error selecting directory:", error);
    }
  };

  const handleExportButtonClick = async (event) => {
    if (allPagesSelectedUploadIds.size === 0) {
      window.alert("Please select one or more uploads to export them.");
      return;
    }

    setExportUploadsSelectedUploadIds([...allPagesSelectedUploadIds]);
    setExportUploadsDialogOpen(true);
  };

  const updateAllPagesSelectedUploadIds = () => {
    const currentPageUploadIds = new Set(allUploadsOfUser.map((upload) => upload.id));
    const currentPageUnselectedUploadIds = new Set(
      [...currentPageUploadIds].filter((value) => !currentPageSelectedUploadIds.has(value))
    );

    let updatedAllPagesSelectedUploadIds = new Set(
      [...allPagesSelectedUploadIds, ...currentPageSelectedUploadIds].filter(
        (value) => !currentPageUnselectedUploadIds.has(value)
      )
    );
    setAllPagesSelectedUploadIds(updatedAllPagesSelectedUploadIds);
  };

  const handlePreviousPageButtonClick = async (event) => {
    setLoading(true);

    await getAllUploadsFromApi(currentPageNum - 1);
    setCurrentPageNum(currentPageNum - 1);

    setLoading(false);
  };

  const handleNextPageButtonClick = async (event) => {
    setLoading(true);

    await getAllUploadsFromApi(currentPageNum + 1);
    setCurrentPageNum(currentPageNum + 1);

    setLoading(false);
  };

  useEffect(() => {
    document.title = "History";

    getAllUploadsFromApi();
  }, []);

  useEffect(() => {
    updateAllPagesSelectedUploadIds();
  }, [currentPageSelectedUploadIds]);

  useEffect(() => {
    const currentPageUploadIds = new Set(allUploadsOfUser.map((upload) => upload.id));
    setCurrentPageSelectedUploadIds(
      new Set([...currentPageUploadIds].filter((value) => allPagesSelectedUploadIds.has(value)))
    );
  }, [allUploadsOfUser]);

  useEffect(() => {
    const updatedUploads = sortUploads([...allUploadsOfUser], uploadsSortingKey);
    setAllUploadsOfUser(updatedUploads);
  }, [uploadsSortingKey]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className={classes.containerMain}>
      <MergeUploadsDialog
        open={mergeUploadsDialogOpen}
        setOpen={setMergeUploadsDialogOpen}
        selectedUploadsIds={mergeSelectedUploadIds}
        setSelectedUploadsIds={setMergeSelectedUploadIds}
        refetchAllUploads={getAllUploadsFromApi}
      />

      <DeleteUploadsDialog
        open={deleteUploadsDialogOpen}
        setOpen={setDeleteUploadsDialogOpen}
        selectedUploadIds={deleteUploadsSelectedUploadIds}
        refetchAllUploads={getAllUploadsFromApi}
      />

      <ImportUploadsDialog
        open={importUploadsDialogOpen}
        setOpen={setImportUploadsDialogOpen}
        selectedDirectoryHandle={importUploadsSelectedDirectoryHandle}
        refetchAllUploads={getAllUploadsFromApi}
      />

      <ExportUploadsDialog
        open={exportUploadsDialogOpen}
        setOpen={setExportUploadsDialogOpen}
        selectedUploadsIds={exportUploadsSelectedUploadIds}
        setSelectedUploadsIds={setExportUploadsSelectedUploadIds}
      />

      <Typography className={classes.heading} variant="h3" style={{ fontWeight: "bold", textAlign: "center" }}>
        Uploads History
      </Typography>

      <div className={classes.controlsContainer}>
        <div>
          {allPagesSelectedUploadIds.size !== 0 ? (
            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
              <MuiButtonWithTooltip
                tooltipTitle="Deselect all selected uploads"
                buttonComponent={
                  <IconButton variant="contained" color="error" onClick={handleDeselectAllButtonClick}>
                    <DeselectIcon fontSize="large" />
                  </IconButton>
                }
              />
              <Typography variant="h6" fontWeight="bold">
                {allPagesSelectedUploadIds.size} Selected
              </Typography>
            </Box>
          ) : null}
        </div>

        <div className={classes.controlsSubContainer}>
          <MuiButtonWithTooltip
            tooltipTitle="Merge selected uploads"
            buttonComponent={
              <IconButton variant="contained" color="secondary" onClick={handleMergeButtonClick}>
                <MergeIcon fontSize="large" />
              </IconButton>
            }
          />
          <MuiButtonWithTooltip
            tooltipTitle="Refresh History"
            buttonComponent={
              <IconButton variant="contained" color="primary" onClick={handleRefreshButtonClick}>
                <RefreshIcon fontSize="large" />
              </IconButton>
            }
          />
          <MuiButtonWithTooltip
            tooltipTitle="Delete selected uploads."
            buttonComponent={
              <IconButton variant="contained" color="error" onClick={deleteButtonOnClick}>
                <DeleteForeverIcon fontSize="large" />
              </IconButton>
            }
          />
          <MuiButtonWithTooltip
            tooltipTitle="Upload and import uploads from a zip file"
            buttonComponent={
              <IconButton variant="contained" color="primary" onClick={handleImportButtonClick}>
                <FileUploadIcon fontSize="large" />
              </IconButton>
            }
          />
          <MuiButtonWithTooltip
            tooltipTitle="Export and download selected uploads as a zip file"
            buttonComponent={
              <IconButton variant="contained" color="primary" onClick={handleExportButtonClick}>
                <FileDownloadIcon fontSize="large" />
              </IconButton>
            }
          />

          <MuiButtonWithTooltip
            tooltipTitle={
              loading || uploadsPageMetadata === null
                ? "Loading... "
                : uploadsPageMetadata.currentPage === 1
                ? "This is the first page."
                : ""
            }
            buttonComponent={
              <Button
                variant="contained"
                onClick={handlePreviousPageButtonClick}
                disabled={loading || uploadsPageMetadata === null || uploadsPageMetadata.currentPage === 1}
                sx={{
                  marginLeft: 1,
                  marginRight: 1,
                }}
              >
                Prev
              </Button>
            }
          />

          <MuiButtonWithTooltip
            tooltipTitle={
              loading || uploadsPageMetadata === null
                ? "Loading... "
                : uploadsPageMetadata.currentPage === uploadsPageMetadata.totalPages
                ? "No more uploads."
                : ""
            }
            buttonComponent={
              <Button
                variant="contained"
                onClick={handleNextPageButtonClick}
                disabled={
                  loading ||
                  uploadsPageMetadata === null ||
                  uploadsPageMetadata.currentPage === uploadsPageMetadata.totalPages
                }
              >
                Next
              </Button>
            }
          />
        </div>
      </div>

      {uploadsPageMetadata !== null ? (
        <div className={classes.pageMetaTextContainer}>
          <Typography variant="p">
            {`Page ${numberWithCommas(uploadsPageMetadata.currentPage)} of ${numberWithCommas(
              uploadsPageMetadata.totalPages
            )}`}
            {" | "}
            {`Uploads ${numberWithCommas(uploadsPageMetadata.currentPageFirstUploadNum)}-${numberWithCommas(
              uploadsPageMetadata.currentPageLastUploadNum
            )} of ${numberWithCommas(uploadsPageMetadata.numTotalUploadsOfUser)}`}
          </Typography>
        </div>
      ) : null}

      <div className={classes.listContainer}>
        <ClipLoader color="blue" loading={loading} size={35} />

        <UploadListItemHeader
          allUploadsSelected={selectableUploadsCount === currentPageSelectedUploadIds.size}
          selectAllCheckboxOnClick={selectAllCheckboxOnClick}
          uploadsSortingKey={uploadsSortingKey}
          setUploadsSortingKey={setUploadsSortingKey}
        />

        {allUploadsOfUser.map((upload, index) => (
          <UploadListItem
            uploadDetails={upload}
            setUploadDetails={(updatedUploadDetails) => {
              const updatedAllUploadsOfUser = [...allUploadsOfUser];
              updatedAllUploadsOfUser[index] = updatedUploadDetails;
              setAllUploadsOfUser(updatedAllUploadsOfUser);
            }}
            isSelected={currentPageSelectedUploadIds.has(upload.id)}
            isGeneratingPdfForUploadId={isGeneratingPdfForUploadId}
            handleListItemFilenameClick={handleListItemFilenameClick}
            checkBoxOnChange={(e) => uploadCheckboxOnClick(upload.id, e.target.checked)}
            refetchAllUploads={getAllUploadsFromApi}
            index={index}
            key={upload.id}
            statusUpdateRequestInterval={statusUpdateRequestInterval}
          />
        ))}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  containerMain: {
    width: "100vw",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "2vh",
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  controlsContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "2vh",
  },
  controlsSubContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  pageMetaTextContainer: {
    width: "75%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "2vh",
  },
  listContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));
