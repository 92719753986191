function tryParseJsonString(jsonString, errorReturnValue = {}) {
  try {
    const parsedJSON = JSON.parse(jsonString);
    return parsedJSON;
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return errorReturnValue;
  }
}

function rgbStringToArray(rgbString) {
  const cleanString = rgbString.slice(4, -1);

  const values = cleanString.split(",").map((value) => parseInt(value.trim()));

  return values;
}

async function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export { tryParseJsonString, rgbStringToArray, delay };
