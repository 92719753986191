import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

import apiEndpoints from "./apiEndpoints";
import { saveToLocalStorage, loadFromLocalStorage, deleteFromLocalStorage } from "../localStorage/localStorage";

const badResponse = {
  success: false,
  error: {
    errorCode: 0,
    message: "Server error.",
  },
};

class apiServicesClient {
  constructor() {
    this.localStorage = {
      user: loadFromLocalStorage("user"),
      tokens: loadFromLocalStorage("tokens"),
    };

    window.addEventListener("userChanged", () => {
      this.localStorage.user = loadFromLocalStorage("user");
      this.localStorage.tokens = loadFromLocalStorage("tokens");
    });

    this.failedAccessTokenRefreshCount = 0;

    this.refreshAccessToken = async () => {
      console.log("---> Refreshing Access Token");

      if (this.failedAccessTokenRefreshCount > 2) {
        console.error("\tFailed to refresh the access token twice.");
        return false;
      }

      if (!this.localStorage.tokens?.refresh) {
        console.error("\tLocal storage had no refresh token.");
        return false;
      }

      const requestBody = {
        refresh: this.localStorage.tokens.refresh,
      };

      const response = await fetch(apiEndpoints.auth.refreshToken, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status !== 200) {
        console.error("\tFailed to refresh access token.");

        if (response.status === 403) {
          toast("Your login permission has been revoked.");
          console.error("\tUser does not have login permission.");
          deleteFromLocalStorage("user");
          deleteFromLocalStorage("tokens");
          window.dispatchEvent(new Event("userChanged"));
          return false;
        }

        this.failedAccessTokenRefreshCount++;
        return false;
      }

      const responseData = await response.json();

      const accessTokenPayload = jwt_decode(responseData.access);

      this.localStorage.tokens.access = responseData.access;
      this.localStorage.user = accessTokenPayload.user;

      saveToLocalStorage("user", this.localStorage.user);
      saveToLocalStorage("tokens", this.localStorage.tokens);
      window.dispatchEvent(new Event("userChanged"));

      this.failedAccessTokenRefreshCount = 0;

      console.log("\tAccess token refreshed successfully.");

      return true;
    };

    this.parseResponse = async (response, retryFunction = undefined) => {
      const responseData = await response.json();

      console.log("Response data");
      console.log(responseData);

      if (retryFunction === undefined) {
        return responseData;
      }

      if (response.status === 401) {
        if (await this.refreshAccessToken()) {
          return await retryFunction();
        }
      }

      return responseData;
    };

    this.config = {
      getBackendConfig: async () => {
        try {
          const response = await fetch(apiEndpoints.config.getBackendConfig, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
          });

          return await this.parseResponse(response, () => this.config.getBackendConfig());
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
    };

    this.user = {
      register: async (email, password, username, fullName, phoneNumber, organization) => {
        try {
          const requestBody = {
            email: email,
            password: password,
            username: username,
            full_name: fullName,
            phone_number: phoneNumber,
            organization: organization,
          };

          const response = await fetch(apiEndpoints.auth.register, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response);
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      login: async (emailOrUsername, password) => {
        try {
          const requestBody = {
            // email_or_username: emailOrUsername,
            username: emailOrUsername,
            password: password,
          };

          const response = await fetch(apiEndpoints.auth.login, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });

          const responseData = await response.json();

          const returnResponse = {};

          if (response.status !== 200) {
            returnResponse.success = false;
            returnResponse.error = {};
            returnResponse.error.message = responseData.detail;
            return returnResponse;
          }

          if (response.status === 403) {
            returnResponse.success = false;
            returnResponse.error = {};
            returnResponse.error.message = responseData.detail;
            return returnResponse;
          }

          const accessTokenPayload = jwt_decode(responseData.access);

          returnResponse.success = true;
          returnResponse.result = {};
          returnResponse.result.user = accessTokenPayload.user;
          returnResponse.result.tokens = responseData;

          return returnResponse;
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      logout: async () => {
        try {
          const requestBody = {
            refresh: this.localStorage.tokens.refresh,
          };

          const response = await fetch(apiEndpoints.auth.logout, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () => this.user.logout());
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      resetPassword: async (username, currentPassword, newPassword) => {
        try {
          const requestBody = {
            username: username,
            currentPassword: currentPassword,
            newPassword: newPassword,
          };

          const response = await fetch(apiEndpoints.auth.resetPassword, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () =>
            this.user.resetPassword(username, currentPassword, newPassword)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      getAvailableCredits: async () => {
        const response = await fetch(apiEndpoints.user.credits, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.localStorage.tokens.access}`,
          },
        });

        return await this.parseResponse(response, () => this.user.getAvailableCredits());
      },
    };

    this.uploads = {
      addNew: async (file, document_parser, parsing_postprocessor, text_recognizer) => {
        const formData = new FormData();
        formData.append("file", file);

        const endpoint = apiEndpoints.uploads.addNew
          .replace("{{document_parser}}", document_parser)
          .replace("{{parsing_postprocessor}}", parsing_postprocessor)
          .replace("{{text_recognizer}}", text_recognizer);

        const response = await fetch(endpoint, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.localStorage.tokens.access}`,
          },
          body: formData,
        });

        return await this.parseResponse(response, () =>
          this.uploads.addNew(file, document_parser, parsing_postprocessor, text_recognizer)
        );
      },
      processNew: async (uploadId) => {
        try {
          const response = await fetch(apiEndpoints.uploads.processNew.replace("{{id}}", uploadId), {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
          });

          return await this.parseResponse(response, () => this.uploads.processNew(uploadId));
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      getMultiple: async (latest_upload_id = undefined) => {
        let endpoint = apiEndpoints.uploads.getMultiple;
        if (latest_upload_id) {
          endpoint += `?latest_upload_id=${latest_upload_id}`;
        }

        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.localStorage.tokens.access}`,
          },
        });

        return await this.parseResponse(response, () => this.uploads.getMultiple(latest_upload_id));
      },
      getSingle: async (id, processingStatusOnly = false) => {
        let endpoint = apiEndpoints.uploads.getDetail.replace("{{id}}", id);
        if (processingStatusOnly) {
          endpoint += "&processing_status_only=1";
        }

        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.localStorage.tokens.access}`,
          },
        });

        return await this.parseResponse(response, () => this.uploads.getSingle(id, processingStatusOnly));
      },
      delete: async (uploadIds) => {
        const requestBody = {
          uploadIds: uploadIds,
        };

        const response = await fetch(apiEndpoints.uploads.delete, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.localStorage.tokens.access}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        return await this.parseResponse(response, () => this.uploads.delete(uploadIds));
      },
      changeFilename: async (uploadId, newFilename) => {
        try {
          const requestBody = {
            filename: newFilename,
          };

          const response = await fetch(apiEndpoints.uploads.changeFilename.replace("{{id}}", uploadId), {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () => this.uploads.changeFilename(uploadId, newFilename));
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      merge: async (uploadIds, filename) => {
        try {
          const requestBody = {
            uploadIds: uploadIds,
            filename: filename,
          };

          const response = await fetch(apiEndpoints.uploads.merge, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () => this.uploads.merge(uploadIds, filename));
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      cancel: async (uploadId) => {
        try {
          const response = await fetch(apiEndpoints.uploads.cancel.replace("{{id}}", uploadId), {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
          });

          return await this.parseResponse(response, () => this.uploads.cancel(uploadId));
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      importSingle: async (detectionsJsonFile, uploadDetailsJsonFile, imageFiles) => {
        // console.log("detections.json");
        // console.log(detectionsJsonFile);
        // console.log("uploadDetails.json");
        // console.log(uploadDetailsJsonFile);
        // console.log("imageFiles");
        // console.log(imageFiles);

        const formData = new FormData();
        formData.append("detections.json", detectionsJsonFile);
        if (uploadDetailsJsonFile) {
          formData.append("uploadDetails.json", uploadDetailsJsonFile);
        }
        for (const imageFile of imageFiles) {
          formData.append(imageFile.name, imageFile);
        }

        const response = await fetch(apiEndpoints.uploads.importSingle, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.localStorage.tokens.access}`,
          },
          body: formData,
        });

        return await this.parseResponse(response, () =>
          this.uploads.importSingle(detectionsJsonFile, uploadDetailsJsonFile, imageFiles)
        );
      },
      getHistory: async (pageNum = 1, numUploadsPerPage = undefined) => {
        let endpoint = apiEndpoints.uploads.history;
        endpoint += `?page_num=${pageNum}`;
        if (numUploadsPerPage) {
          endpoint += `&num_uploads_per_page=${numUploadsPerPage}`;
        }

        console.log(pageNum);
        console.log(endpoint);

        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.localStorage.tokens.access}`,
          },
        });

        return await this.parseResponse(response, () => this.uploads.getHistory(pageNum, numUploadsPerPage));
      },
    };

    this.detections = {
      getAllForUpload: async (uploadId, filenamesOnly = false) => {
        let endpoint = apiEndpoints.detections.getAllForUpload.replace("{{uploadId}}", uploadId);
        if (filenamesOnly) {
          endpoint += "&filenamesOnly=1";
        }

        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.localStorage.tokens.access}`,
          },
        });

        return await this.parseResponse(response, () => this.detections.getAllForUpload(uploadId));
      },
      update: async (detectionId, detections) => {
        try {
          const requestBody = {
            detections: detections,
          };

          const response = await fetch(apiEndpoints.detections.update.replace("{{id}}", detectionId), {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () => this.detections.update(detectionId, detections));
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
    };

    this.customOcr = {
      getDetectionsForImageAndBbox: async (detectionId, xMin, yMin, xMax, yMax, rotation) => {
        const endpoint = apiEndpoints.customOcr.getDetectionsForImageAndBbox
          .replace("{{detectionId}}", detectionId)
          .replace("{{xMin}}", xMin)
          .replace("{{yMin}}", yMin)
          .replace("{{xMax}}", xMax)
          .replace("{{yMax}}", yMax)
          .replace("{{rotation}}", rotation);

        try {
          const response = await fetch(endpoint, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
          });

          return await this.parseResponse(response, () =>
            this.customOcr.getDetectionsForImageAndBbox(detectionId, xMin, yMin, xMax, yMax)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
    };

    this.utils = {
      transliterate: async (sourceTexts, sourceLanguage, targetLanguage) => {
        try {
          const requestBody = {
            sourceTexts: sourceTexts,
            sourceLanguage: sourceLanguage,
            targetLanguage: targetLanguage,
          };

          const response = await fetch(apiEndpoints.utils.transliterate, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () =>
            this.utils.transliterate(sourceTexts, sourceLanguage, targetLanguage)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
      generatePdf: async (uploadId, pageNumbers, pdfGenerationMode) => {
        try {
          const requestBody = {
            uploadId: uploadId,
            pageNumbers: pageNumbers,
            pdfGenerationMode: pdfGenerationMode,
          };

          const response = await fetch(apiEndpoints.utils.generatePdf, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.localStorage.tokens.access}`,
            },
            body: JSON.stringify(requestBody),
          });

          return await this.parseResponse(response, () =>
            this.utils.generatePdf(uploadId, pageNumbers, pdfGenerationMode)
          );
        } catch (error) {
          console.log(error);
          return badResponse;
        }
      },
    };
  }
}

const apiClient = new apiServicesClient();

export default apiClient;
