import { useState, useContext, useEffect } from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Navigate, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";

import userContext from "../context/userContext";
import { deleteFromLocalStorage } from "../localStorage/localStorage";
import apiClient from "../api/apiServices";
import validators from "../api/validators";
import PasswordInput from "../components/PasswordInput";

const ResetPassword = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user } = useContext(userContext);

  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState(undefined);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const resetPasswordButtonOnClick = async (event) => {
    event.preventDefault();

    if (Object.keys(validationErrors).length > 0) {
      setDisplayValidationErrors(true);
      return;
    }

    setLoading(true);

    const response = await apiClient.user.resetPassword(
      user.username,
      currentPassword,
      newPassword
    );

    if (!response.success) {
      if (response.error.message === "Invalid credentials.") {
        setCurrentPasswordError("Current Password is Invalid.");
      } else {
        toast(response.error.message);
        setCurrentPasswordError(undefined);
      }

      setLoading(false);
      return;
    }

    setCurrentPasswordError(undefined);

    toast("Password reset successfully. Redirecting to login page.");
    setLoading(false);

    setTimeout(() => {
      deleteFromLocalStorage("user");
      window.dispatchEvent(new Event("userChanged"));
      deleteFromLocalStorage("tokens");
      navigate("/auth/login");
    }, 5000);
  };

  useEffect(() => {
    document.title = "Lipikar - Reset Password";
  }, []);

  useEffect(() => {
    setCurrentPasswordError(undefined);
  }, [currentPassword]);

  useEffect(() => {
    setValidationErrors(
      validators.resetPassword(newPassword, confirmNewPassword)
    );
  }, [newPassword, confirmNewPassword]);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        mb={2}
      >
        <Typography
          variant="h3"
          className={classes.heading}
          style={{ fontWeight: "bold", textAlign: "center" }}
        >
          Reset Password
        </Typography>
      </Box>

      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        mb={2}
      >
        <Box mb={4}>
          <PasswordInput
            label="Current Password"
            value={currentPassword}
            onChange={(event) => setCurrentPassword(event.target.value)}
            error={currentPasswordError !== undefined}
            helperText={currentPasswordError}
          />
        </Box>
        <PasswordInput
          label="New Password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          error={
            displayValidationErrors &&
            validationErrors.newPassword !== undefined
          }
          helperText={displayValidationErrors && validationErrors.newPassword}
        />
        <PasswordInput
          label="Confirm New Password"
          value={confirmNewPassword}
          onChange={(event) => setConfirmNewPassword(event.target.value)}
          error={
            displayValidationErrors &&
            validationErrors.confirmNewPassword !== undefined
          }
          helperText={
            displayValidationErrors && validationErrors.confirmNewPassword
          }
        />
      </Box>

      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        mb={2}
      >
        <Button
          variant="contained"
          color="error"
          onClick={resetPasswordButtonOnClick}
          disabled={
            loading ||
            (displayValidationErrors &&
              Object.keys(validationErrors).length !== 0) ||
            currentPasswordError !== undefined
          }
        >
          {loading ? (
            <BeatLoader color="red" loading={true} size={10} />
          ) : (
            "Reset Password"
          )}
        </Button>
      </Box>
    </Container>
  );
};

export default ResetPassword;

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 25,
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
}));
