import { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BeatLoader } from "react-spinners";

import MuiButtonWithTooltip from "./MuiButtonWithTooltip";
import apiClient from "../api/apiServices";
import { loadLipikarImportZip, getDirectoryStructure } from "../utils/fsUtils";

export default function ImportUploadsDialog({ open, setOpen, refetchAllUploads }) {
  const classes = useStyles();

  const importUploadsResultsContainerRef = useRef(null);
  const abortControllerSignal = useRef(null);
  const fileInputRef = useRef(null);

  const [abortController, setAbortController] = useState(new AbortController());

  const [isImporting, setIsImporting] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [hasAbortedSuccessfully, setHasAbortedSuccessfully] = useState(false);
  const [processingStatus, setProcessingStatus] = useState("");
  const [processingProgressPercentage, setProcessingProgressPercentage] = useState(0.0);
  const [importUploadResults, setImportUploadsResults] = useState([]);
  const [isSelectDirectoryEnabled, setIsSelectDirectoryEnabled] = useState(true);

  const importUploadsFromDirectory = async (directoryStructure) => {
    abortControllerSignal.current = abortController.signal;

    setImportUploadsResults([]);
    setProcessingProgressPercentage(0.0);

    // TODO: validate directoryStructure
    console.log(JSON.stringify(directoryStructure, null, 4));

    // console.log(directoryStructure[0]["content"][2]["content"][0]["name"]);
    // console.log(directoryStructure[0]["content"][2]["content"][0]["content"]);

    const updatedImportUploadResults = [];

    let loopBroken = false;

    for (let i = 0; i < directoryStructure.length; i++) {
      if (abortController.signal.aborted) {
        if (i >= 0) {
          setProcessingStatus(`Cancelled after upload ${directoryStructure[i].name}`);
        } else {
          setProcessingStatus("Cancelled");
        }

        loopBroken = true;
        break;
      }

      const uploadFolder = directoryStructure[i];
      const uploadName = uploadFolder.name;

      let detectionsJsonFile = null;
      let uploadDetailsJsonFile = null;
      const imageFiles = [];

      setProcessingStatus(`Importing Upload ${uploadName} ...`);

      for (const entry of uploadFolder.content) {
        if (entry.name === "detections.json") {
          detectionsJsonFile = entry.content;
        } else if (entry.name === "uploadDetails.json") {
          uploadDetailsJsonFile = entry.content;
        } else if (entry.name === "images") {
          for (const imageFile of entry.content) {
            imageFiles.push(imageFile.content);
          }
        }
      }

      const importUploadResponse = await apiClient.uploads.importSingle(
        detectionsJsonFile,
        uploadDetailsJsonFile,
        imageFiles
      );

      if (!importUploadResponse.success) {
        console.log(importUploadResponse);
        
        if (importUploadResponse.error?.validationErrors) {
          updatedImportUploadResults.push({
            uploadName: uploadName,
            success: false,
            message: importUploadResponse.error.validationErrors,
          });
        }
      } else {
        updatedImportUploadResults.push({
          uploadName: uploadName,
          success: true,
          message: "ok",
        });
      }

      setImportUploadsResults([...updatedImportUploadResults]);

      setProcessingProgressPercentage(100 * parseFloat(((i + 1) / directoryStructure.length).toFixed(4)));
    }

    await refetchAllUploads();

    setHasAbortedSuccessfully(loopBroken);

    if (!loopBroken) {
      setProcessingStatus("All uploads imported.");
    }
  };

  const handleSelectDirectoryButtonClick = async (e) => {
    let selectedDirectoryHandle;
    try {
      selectedDirectoryHandle = await window.showDirectoryPicker();
    } catch (e) {
      return;
    }

    setIsImporting(true);
    const directoryStructure = await getDirectoryStructure(selectedDirectoryHandle);
    await importUploadsFromDirectory(directoryStructure);
    setIsImporting(false);
  };

  const handleFileUpload = async () => {
    const file = fileInputRef.current.files[0];

    setIsImporting(true);
    const directoryStructure = await loadLipikarImportZip(file);
    await importUploadsFromDirectory(directoryStructure);
    setIsImporting(false);
  };

  useEffect(() => {
    setIsSelectDirectoryEnabled(window.showDirectoryPicker !== undefined);
  }, []);

  useEffect(() => {
    if (!importUploadsResultsContainerRef.current) {
      return;
    }

    importUploadsResultsContainerRef.current.scrollTop = importUploadsResultsContainerRef.current.scrollHeight;
  }, [importUploadResults]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        if (isImporting) {
          return;
        }
        setOpen(false);
      }}
    >
      <input type="file" ref={fileInputRef} accept=".zip" style={{ display: "none" }} onChange={handleFileUpload} />
      <DialogTitle>Import Uploads</DialogTitle>

      <DialogContent>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {!isImporting ? (
            <Box>
              <MuiButtonWithTooltip
                tooltipTitle={
                  isSelectDirectoryEnabled
                    ? "Select a directory containing one or more uploads."
                    : "This is not currently supported."
                }
                buttonComponent={
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isSelectDirectoryEnabled}
                    onClick={handleSelectDirectoryButtonClick}
                  >
                    Select Directory
                  </Button>
                }
              />

              <MuiButtonWithTooltip
                tooltipTitle="Upload a zip file containing one or more uploads. Max file size is 20 MB."
                buttonComponent={
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => fileInputRef.current.click()}
                    sx={{ marginLeft: 2 }}
                  >
                    Upload Zip
                  </Button>
                }
              />
            </Box>
          ) : null}

          <div className={classes.importUploadsResultsContainer} ref={importUploadsResultsContainerRef}>
            <ul>
              {importUploadResults.map((item, index) => (
                <li className={classes.importUploadResultsListItem} key={index}>
                  <div className={classes.importUploadResultsListItemSubContainer}>
                    <Typography variant="h6" noWrap mr={2}>
                      {`${item.uploadName}`}
                    </Typography>

                    <Typography variant="p" noWrap color={item.success ? "#2e7d32" : "error"}>
                      {`${item.message}`}
                    </Typography>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <Box width="100%" display="flex" justifyContent="flex-start">
            <Typography variant="p">{processingStatus}</Typography>
          </Box>

          {isImporting ? (
            processingProgressPercentage === 0.0 ? (
              <BeatLoader color="blue" loading={true} size={14} />
            ) : (
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <LinearProgress
                  sx={{ width: "45vw", marginRight: 2 }}
                  color="primary"
                  variant="determinate"
                  value={processingProgressPercentage}
                />
                <p>
                  <strong>{`${processingProgressPercentage.toFixed(2)} %`}</strong>
                </p>
              </Box>
            )
          ) : null}
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Box display="flex" justifyContent="flex-end" width="50%">
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              abortController.abort();
              setIsCancelled(true);
            }}
            disabled={isCancelled || processingProgressPercentage === 100}
          >
            {isCancelled ? (hasAbortedSuccessfully ? "Cancelled" : "Stoping ...") : "Cancel"}
          </Button>

          {processingProgressPercentage === 100 || hasAbortedSuccessfully ? (
            <Button variant="contained" color="primary" onClick={() => setOpen(false)} sx={{ marginLeft: 2 }}>
              Back to History Page
            </Button>
          ) : null}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  importUploadsResultsContainer: {
    width: "80%",
    maxHeight: 200,
    overflowY: "auto",
  },
  importUploadResultsListItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "90%",
    overflowX: "auto",
  },
  importUploadResultsListItemSubContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  dialogActions: {
    "&&": {
      justifyContent: "flex-end",
    },
  },
}));
