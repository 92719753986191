import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./ui/theme";

import Navbar from "./components2/Navbar";
import Register from "./components2/Register";
import Login from "./components2/Login";
import Settings from "./components2/Settings";
import NewUpload from "./components2/NewUpload";
import History from "./components2/History";
import Labelling from "./components2/Labelling";
import ResetPassword from "./components2/ResetPassword";
import UserContext from "./context/userContext";
import UserManual from "./components2/UserManual";
import Team from "./components2/Team";
import apiClient from "./api/apiServices";
import { saveToLocalStorage, deleteFromLocalStorage } from "./localStorage/localStorage";
import getAppSettings from "./config/appSettings";

const appVersion = "Lipikar: 2023-06-05: 1";

export default function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [backendConfig, setBackendConfig] = useState(JSON.parse(localStorage.getItem("backendConfig")));
  const [appSettings, setAppSettings] = useState(getAppSettings());

  const getBackendConfigFromApi = async () => {
    const response = await apiClient.config.getBackendConfig();

    if (!response.success) {
      return;
    }

    saveToLocalStorage("backendConfig", response.result.config);
    setBackendConfig(response.result.config);
  };

  useEffect(() => {
    const savedAppVersion = JSON.parse(localStorage.getItem("appVersion"));
    if (savedAppVersion !== appVersion) {
      localStorage.clear();
      localStorage.setItem("appVersion", JSON.stringify(appVersion));

      if (user !== null) {
        setUser(null);
        window.dispatchEvent(new Event("userChanged"));
        toast("The application has been updated. Please Log In again.");
      }

      return;
    }

    if (user === null) {
      return;
    }

    getBackendConfigFromApi();
  }, [user]);

  useEffect(() => {
    window.addEventListener("userChanged", () => {
      setUser(JSON.parse(localStorage.getItem("user")));
      getBackendConfigFromApi();
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, backendConfig, appSettings, setAppSettings }}>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            <Navbar />
            <Routes>
              <Route path="/auth/register" element={<Register />} />
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/reset-password" element={<ResetPassword />} />
              <Route path="/" element={<Navigate to="/upload-new" />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/upload-new" element={<NewUpload />} />
              <Route path="/labelling/:id" element={<Labelling />} />
              <Route path="/history/:pageNum" element={<History />} />
              <Route path="/user-manual" element={<UserManual />} />
              <Route path="/meet-the-team" element={<Team />} />
            </Routes>
          </div>
        </Router>
        <canvas id="image-manipulation-main-canvas" style={{ display: "none" }}></canvas>
        <canvas id="image-manipulation-canvas2" style={{ display: "none" }}></canvas>
        <canvas id="image-manipulation-canvas3" style={{ display: "none" }}></canvas>
      </ThemeProvider>
    </UserContext.Provider>
  );
}
