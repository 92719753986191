function convertToUnicodeEscape(str) {
  let unicodeString = "";

  for (let i = 0; i < str.length; i++) {
    let charCode = str.charCodeAt(i);

    if (charCode < 128) {
      unicodeString += str.charAt(i);
    } else {
      let hexCode = charCode.toString(16);
      unicodeString += "\\u" + ("0000" + hexCode).slice(-4);
    }
  }

  return unicodeString;
}

function decodeUnicodeEscape(escapedText) {
  return escapedText.replace(/\\u[\dA-Fa-f]{4}/g, function (match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16));
  });
}

function convertToTitleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

function truncate(inputStr, length) {
  if (inputStr.length > length) {
    return inputStr.substring(0, length - 3) + "...";
  }
  return inputStr;
}

function reverseObjectKeyValue(inputObj) {
  // Check if the input is an object
  if (typeof inputObj !== "object" || inputObj === null || Array.isArray(inputObj)) {
    throw new Error("Input must be a non-null object");
  }

  const reversedObj = {};
  for (const key in inputObj) {
    if (inputObj.hasOwnProperty(key)) {
      const value = inputObj[key];
      if (typeof value === "string") {
        reversedObj[value] = key;
      }
    }
  }
  return reversedObj;
}

const keyToLabelMap = {
  urdu_iitd: "Urdu (IITD)",
  kannada_iitd: "Kannada (IITD)",
  tamil_iitd: "Tamil (IITD)",
  hindi_iitd: "Hindi (IITD)",
  english_iitd: "English (IITD)",
  assamese_iitd: "Assamese (IITD)",
  punjabi_iitd: "Punjabi (IITD)",
  gujarati_iitd: "Gujarati (IITD)",
  telugu_iitd: "Telugu (IITD)",
  malayalam_iitd: "Malayalam (IITD)",
  bengali_iitd: "Bengali (IITD)",
  oriya_iitd: "Oriya (IITD)",
  kannada_tesseract: "Kannada (Tesseract)",
  tamil_tesseract: "Tamil (Tesseract)",
  bengali_tesseract: "Bengali (Tesseract)",
  gujarati_tesseract: "Gujarati (Tesseract)",
  malayalam_tesseract: "Malayalam (Tesseract)",
  marathi_tesseract: "Marathi (Tesseract)",
  nepali_tesseract: "Nepali (Tesseract)",
  punjabi_tesseract: "Punjabi (Tesseract)",
  sanskrit_tesseract: "Sanskrit (Tesseract)",
  sindhi_tesseract: "Sindhi (Tesseract)",
  tesseract_line_level_document_parser: "Line-level (Tess)",
  tesseract_word_level_document_parser: "Word-level (Tess)",
  kraken_line_level_document_parser: "Line-level (Kraken)",
  kraken_plus_tess_word_level_document_parser: "Word-level (Kraken + Tess)",
  tesseract_document_parser: "Tesseract Document Parser",
  no_postprocessor: "No Postprocessor",
};

const labelToKeyMap = reverseObjectKeyValue(keyToLabelMap);

function convert_key_to_label(key) {
  return keyToLabelMap[key];
}

function convert_label_to_key(label) {
  return labelToKeyMap[label];
}

function convertArrOfStringsToString(arr) {
  if (arr.length === 1) {
    return arr[0];
  }

  let output = "";
  for (let i = 0; i < arr.length - 1; i++) {
    output += `${arr[i]}, `;
  }
  output += `and ${arr[arr.length - 1]}`;

  return output;
}

function toUpperFirst(str) {
  if (!str) {
    return str;
  }

  str = str.toString();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function toLowerFirst(str) {
  if (!str) {
    return str;
  }

  str = str.toString();
  return str.charAt(0).toLowerCase() + str.slice(1);
}

function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export {
  convertToUnicodeEscape,
  decodeUnicodeEscape,
  truncate,
  convert_key_to_label,
  convert_label_to_key,
  convertArrOfStringsToString,
  toLowerFirst,
  toUpperFirst,
  numberWithCommas,
};
