import { useState, useEffect, useContext, useRef } from "react";
import { Box } from "@mui/material";
import { Stage, Layer, Rect, Line } from "react-konva";
import useImage from "use-image";
import Skeleton from "react-loading-skeleton";

import Rectangle from "./Rectangle";
import Polygon from "./Polygon";
import userContext from "../context/userContext";
import {
  getAverageBboxDimensions,
  getNewDetectionId,
  createNewDetectionForPointerPosAndBboxDimensions,
  insertNewDetection,
  updateDetectionByIndex,
  findDetectionById,
  createNewDetectionForPolygon,
} from "../utils/detectionUtils";
import { getRectForRotatedBbox } from "../utils/mathUtils";
import { getBboxCroppedImageFromImage } from "../utils/imageUtils";

const deltaZoomFactorPerScrollMove = 0.1;

export default function Canvas({
  imageUrl,
  detections,
  setDetections,
  selectedId,
  setSelectedId,
  hasChanged,
  setHasChanged,
  addAnnotationMode,
  setAddAnnotationMode,
  detectionsLevel,
}) {
  const { appSettings } = useContext(userContext);
  const bboxStrokeWidthFactor = appSettings.bboxStrokeWidthFactor;

  const selectedBboxCanvasRef = useRef(null);

  const [image] = useImage(imageUrl);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingBbox, setIsAddingBbox] = useState(false);
  const [stagePointerPosition, setStagePointerPosition] = useState(null);
  const [stageScaleFactor, setStageScaleFactor] = useState(0.5);
  const [bboxStrokeWidth, setBboxStrokeWidth] = useState(1);
  const [newBboxWidth, setNewBboxWidth] = useState(100);
  const [newBboxHeight, setNewBboxHeight] = useState(100);
  const [zoomFactor, setZoomFactor] = useState(1.0);
  const [stageOffset, setStageOffset] = useState([0, 0]);

  const [newPolygonPoints, setNewPolygonPoints] = useState([[0, 0]]);

  const getPointerPosition = (targetStage) => {
    const pointerPos = targetStage.getPointerPosition();

    pointerPos.x -= stageOffset[0];
    pointerPos.y -= stageOffset[1];

    pointerPos.x /= stageScaleFactor * zoomFactor;
    pointerPos.y /= stageScaleFactor * zoomFactor;

    return pointerPos;
  };

  const checkDeselect = (e) => {
    if (addAnnotationMode === "rectangle" || isAddingBbox) {
      return;
    }

    if (addAnnotationMode === "polygon") {
      const pointerPos = getPointerPosition(e.target.getStage());

      const updatedNewPolygonPoints = [...newPolygonPoints];
      updatedNewPolygonPoints[updatedNewPolygonPoints.length - 1] = [
        pointerPos.x,
        pointerPos.y,
      ];
      updatedNewPolygonPoints.push([pointerPos.x, pointerPos.y]);
      setNewPolygonPoints(updatedNewPolygonPoints);

      return;
    }

    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
    }
  };

  const handleDoubleClick = (e) => {
  console.log("mouse clicked double ");
    if (addAnnotationMode === "polygon") {
      const updatedNewPolygonPoints = [...newPolygonPoints];
      updatedNewPolygonPoints.pop();
      updatedNewPolygonPoints.pop();

      const newDetectionId = getNewDetectionId(detections);
      const newDetection = createNewDetectionForPolygon(
        updatedNewPolygonPoints,
        newDetectionId
      );

      const updatedDetections = insertNewDetection(
        [...detections],
        newDetection,
        detectionsLevel
      );

      setDetections(updatedDetections);
      setHasChanged(true);
      setSelectedId(newDetectionId);

      setNewPolygonPoints([]);
      setAddAnnotationMode("");
    }
  };

  const addNewDetectionAtCurrentPointerPosition = (e) => {
    setIsAddingBbox(true);

    const pointerPos = getPointerPosition(e.target.getStage());

    const newDetectionId = getNewDetectionId(detections);
    const newDetection = createNewDetectionForPointerPosAndBboxDimensions(
      [pointerPos.x, pointerPos.y],
      [newBboxWidth, newBboxHeight],
      newDetectionId
    );

    const updatedDetections = insertNewDetection(
      [...detections],
      newDetection,
      detectionsLevel
    );

    setDetections(updatedDetections);
    setHasChanged(true);
    setSelectedId(newDetectionId);

    setAddAnnotationMode("");
    setIsAddingBbox(false);
  };

  const handleMouseMove = (e) => {
    const pointerPos = getPointerPosition(e.target.getStage());
    setStagePointerPosition(pointerPos);

    if (addAnnotationMode === "polygon") {
      const updatedNewPolygonPoints = [...newPolygonPoints];
      updatedNewPolygonPoints[updatedNewPolygonPoints.length - 1] = [
        pointerPos.x,
        pointerPos.y,
      ];

      setNewPolygonPoints(updatedNewPolygonPoints);
    }
  };

  const handleMouseUp = (e) => {
    if (addAnnotationMode === "" || isAddingBbox) {
      return;
    }

    if (addAnnotationMode === "polygon") {
      return;
    }

    addNewDetectionAtCurrentPointerPosition(e);
  };

  const handleMouseLeave = (e) => {
    setStagePointerPosition(null);
  };

  const handleMouseWheel = (e) => {
    e.evt.preventDefault();

    const stage = e.target.getStage();
    const currentStagePointerPostion = stage.getPointerPosition();

    const updatedZoomFactor = Math.max(
      1,
      e.evt.deltaY < 0
        ? zoomFactor * (1 + deltaZoomFactorPerScrollMove)
        : zoomFactor / (1 + deltaZoomFactorPerScrollMove)
    );

    const mousePointsTo = [
      (currentStagePointerPostion.x - stage.x()) / zoomFactor,
      (currentStagePointerPostion.y - stage.y()) / zoomFactor,
    ];

    const updatedStageOffset = [
      -(mousePointsTo[0] - currentStagePointerPostion.x / updatedZoomFactor) *
        updatedZoomFactor,
      -(mousePointsTo[1] - currentStagePointerPostion.y / updatedZoomFactor) *
        updatedZoomFactor,
    ];

    setZoomFactor(updatedZoomFactor);
    // set stage offset clamped by zoom factor

    if (updatedZoomFactor === 1.0) {
      setStageOffset([0, 0]);
    } else {
      setStageOffset(updatedStageOffset);
    }
  };

  useEffect(() => {
    if (image?.width === undefined || image?.height === undefined) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);

    // Computing the scale factor for the Konva stage based on the image dimensions
    const imageWidth = image?.width;
    const imageHeight = image?.height;
    const desiredImageWidth = Math.min(1200, window.innerWidth) * 0.45;
    const desiredImageHeight = window.innerHeight * 0.6;

    setStageScaleFactor(
      Math.min(desiredImageWidth / imageWidth, desiredImageHeight / imageHeight)
    );

    setBboxStrokeWidth(
      Math.max(
        1,
        Math.round(Math.max(imageWidth, imageHeight) * bboxStrokeWidthFactor)
      )
    );
  }, [image]);

  useEffect(() => {
    const averageBboxDimensions = getAverageBboxDimensions(detections);
    setNewBboxWidth(averageBboxDimensions[0]);
    setNewBboxHeight(averageBboxDimensions[1]);
  }, [detections]);

  useEffect(() => {
    if (!image || !selectedBboxCanvasRef.current) {
      return;
    }

    const selectedDetection = findDetectionById(detections, selectedId);
    if (!selectedDetection) {
      return;
    }

    getBboxCroppedImageFromImage(
      imageUrl,
      {
        x_min: selectedDetection.x,
        y_min: selectedDetection.y,
        x_max: selectedDetection.x + selectedDetection.width,
        y_max: selectedDetection.y + selectedDetection.height,
        rotation: selectedDetection.rotation,
      },
      selectedBboxCanvasRef
    );
  }, [selectedId, detections]);

  // const interpolateStagOffsetChange = (newOffset) => {};

  // useEffect(() => {
  //   if (
  //     selectedId === null
  //     // || zoomFactor === 1.0
  //   ) {
  //     return;
  //   }

  //   const csc = [-stageOffset[0] + 137 - stageOffset[1] + 224];

  //   setStageOffset([-csc[0], -csc[1]]);

  //   // const currentStageCenter = [
  //   //   -stageOffset[0] / zoomFactor,
  //   //   -stageOffset[1] / zoomFactor
  //   // ]

  //   return;

  //   const selectedDetection = findDetectionById(detections, selectedId);
  //   const selectedDetectionCentroid = detectionCentroid(selectedDetection);

  //   // setStageOffset([-10, -30]);

  //   // setStageOffset([-selectedDetection.text_bbox.x_min + 100, -selectedDetection.text_bbox.y_min + 100]);

  //   const mousePointTo = {
  //     x: -selectedDetection.text_bbox.x_min / zoomFactor - stageOffset[0] / zoomFactor,
  //     y: -selectedDetection.text_bbox.y_min / zoomFactor - stageOffset[1] / zoomFactor,
  //   };

  //   setZoomFactor(zoomFactor);
  //   // if (zoomFactor === 1.0) {
  //   //   setStageOffset([0, 0]);
  //   // } else {
  //   setStageOffset([
  //     -(mousePointTo.x - selectedDetection.text_bbox.x_min / zoomFactor) * zoomFactor,
  //     -(mousePointTo.y - selectedDetection.text_bbox.y_min / zoomFactor) * zoomFactor,
  //   ]);
  //   // }
  // }, [selectedId]);

  // useEffect(() => {
  //   console.log(newPolygonPoints);
  // }, [newPolygonPoints]);

  useEffect(() => {
    console.log(zoomFactor);
  }, [zoomFactor]);

  if (isLoading || image === undefined) {
    return (
      <Skeleton
        width="100%"
        height="50vh"
        baseColor="#dddddd"
        highlightColor="#eeeeee"
      />
    );
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      p={1}
    >
      <Box
        width="100%"
        height="5vh"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        pl={1}
        mb={1}
      >
        <canvas
          ref={selectedBboxCanvasRef}
          style={{
            maxWidth: "100%",
            maxHeight: "5vh",
          }}
        />
      </Box>
      <Stage
        width={image.width * stageScaleFactor}
        height={image.height * stageScaleFactor}
        onMousemove={handleMouseMove}
        onMouseDown={checkDeselect}
        onTouchStart={checkDeselect}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onWheel={handleMouseWheel}
        onDblClick={handleDoubleClick}
        scale={{
          x: stageScaleFactor * zoomFactor,
          y: stageScaleFactor * zoomFactor,
        }}
        style={{ border: "1px solid black" }}
        x={stageOffset[0]}
        y={stageOffset[1]}
        draggable={true}
      >
        {/* Main Image Layer */}
        <Layer>
          <Rect
            x={0}
            y={0}
            width={image.width}
            height={image.height}
            fillPatternImage={image}
            draggable={false}
            listening={false}
          />
        </Layer>

        {/* Bboxes Layer */}
        <Layer>
          {detections.map((detection, index) => {
            if (detection?.type === "polygon") {
              return (
                <Polygon
                  points={detection.polygon}
                  setPoints={(updatedPoints) => {
                    const updatedDetections = [...detections];
                    updatedDetections[index].polygon = updatedPoints;
                    setDetections(updatedDetections);
                    setHasChanged(true);
                  }}
                  isAddingNew={false}
                  isSelected={
                    addAnnotationMode === "" && detection.id === selectedId
                  }
                  onSelect={() => {
                    console.log("Selecting");

                    if (addAnnotationMode !== "" || isAddingBbox) {
                      return;
                    }

                    console.log("Selecting");

                    setSelectedId(detection.id);
                  }}
                  additionalShapeProps={{
                    ...detection,
                    zoomFactor: zoomFactor,
                    strokeWidth: bboxStrokeWidth !== NaN ? bboxStrokeWidth : 1,
                  }}
                />
              );
            }

            return (
              <Rectangle
                shapeProps={{
                  ...detection,
                  strokeWidth:
                    bboxStrokeWidth !== NaN
                      ? bboxStrokeWidth / zoomFactor
                      : 1 / zoomFactor,
                }}
                isSelected={
                  addAnnotationMode === "" && detection.id === selectedId
                }
                onSelect={() => {
                  if (addAnnotationMode === "rectangle" || isAddingBbox) {
                    return;
                  }

                  setSelectedId(detection.id);
                }}
                onChange={(newAttrs) => {
                  setHasChanged(true);
                  setDetections(
                    updateDetectionByIndex(detections, index, newAttrs)
                  );
                }}
                key={index}
              />
            );
          })}
        </Layer>

        {/* Tool Overlay Layer */}
        <Layer>
          {addAnnotationMode === "rectangle" &&
          stagePointerPosition !== null ? (
            <Rect
              x={stagePointerPosition.x - 0.5 * newBboxWidth}
              y={stagePointerPosition.y - 0.5 * newBboxHeight}
              width={newBboxWidth}
              height={newBboxHeight}
              stroke="red"
              strokeWidth={
                bboxStrokeWidth !== NaN
                  ? bboxStrokeWidth / zoomFactor
                  : 1 / zoomFactor
              }
              dash={[10, 10]}
            />
          ) : addAnnotationMode === "polygon" &&
            stagePointerPosition !== null ? (
            <Polygon
              points={newPolygonPoints}
              setPoints={(points) => {}}
              isAddingNew={addAnnotationMode === "polygon"}
              isSelected={true}
              additionalShapeProps={{
                stroke: "red",
                zoomFactor: zoomFactor,
                strokeWidth: bboxStrokeWidth !== NaN ? bboxStrokeWidth : 1,
              }}
            />
          ) : null}
        </Layer>
      </Stage>
    </Box>
  );
}
