import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Typography, Container, Box, Grid, Tooltip, IconButton, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Undo as UndoIcon,
  Redo as RedoIcon,
  ZoomOutMap as ZoomOutMapIcon,
  ScreenRotationAlt as ScreenRotationAltIcon,
  PhotoSizeSelectLarge as PhotoSizeSelectLargeIcon,
} from "@mui/icons-material";

import { ClipLoader, BeatLoader } from "react-spinners";
import { Navigate } from "react-router-dom";
import userContext from "../context/userContext";
import apiClient from "../api/apiServices";
import TextDisplayer from "./TextDisplayer";
import Canvas from "./Canvas";
import { mediaBaseUrl } from "../api/apiEndpoints";
import DownloadDetectionsDialog from "../components/DownloadDetectionsDialog";
import { rectContainsRect } from "../utils/mathUtils";
import { convert_key_to_label } from "../utils/stringUtils";
import { lineLevelDocumentParsers } from "../api/apiUtils";
import MuiButtonWithTooltip from "../components/MuiButtonWithTooltip";
import Crop169Icon from "@mui/icons-material/Crop169";
import PolylineIcon from "@mui/icons-material/Polyline";

import {
  transformAndUpdateDetectionById,
  addKonvaFieldsToDetections,
  removeKonvaFieldsFromDetections,
} from "../utils/detectionUtils";
import { delay } from "lodash";

const maxUndoLimit = 8;
const transformationModeButtonUi = {
  location: {
    label: "Move",
    color: "primary",
    icon: <ZoomOutMapIcon />,
  },
  rotation: {
    label: "Rotate",
    color: "primary",
    icon: <ScreenRotationAltIcon />,
  },
  scaling: {
    label: "Scale",
    color: "primary",
    icon: <PhotoSizeSelectLargeIcon />,
  },
};
const nextTransformationMode = {
  location: "rotation",
  rotation: "scaling",
  scaling: "location",
};

export default function Labelling() {
  const classes = useStyles();
  const { user, appSettings } = useContext(userContext);
  const { id: uploadId } = useParams();

  const saveButtonRef = useRef(null);
  const resetToSavedButtonRef = useRef(null);
  const resetToOriginalButtonRef = useRef(null);
  const downloadButtonRef = useRef(null);
  const addNewBboxButtonRef = useRef(null);
  const removeSelectedBboxButtonRef = useRef(null);
  // no ref for re-run, we don't want the user to spam it with keyboard shortcuts
  const transformationModeButtonRef = useRef(null);
  const undoButtonRef = useRef(null);
  const redoButtonRef = useRef(null);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const leftArrowButtonRef = useRef(null);
  const rightArrowButtonRef = useRef(null);
  const upArrowButtonRef = useRef(null);
  const downArrowButtonRef = useRef(null);
  const addNewBboxToast = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [uploadDetails, setUploadDetails] = useState(null);
  const [allImagesDetectionDetails, setAllImagesDetectionDetails] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageSavedDetectionDetails, setCurrentImageSavedDetectionDetails] = useState(null);
  const [currentImageDetections, setCurrentImageDetections] = useState([]);

  const [selectedId, setSelectedId] = useState(null);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

  const [addAnnotationMode, setAddAnnotationMode] = useState("");
  const [transformationMode, setTransformationMode] = useState("location");
  const [transformationAmount, setTransformationAmount] = useState(5);

  const [isReRunningOcr, setIsReRunningOcr] = useState(false);

  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const getUploadAndDetections = async () => {
    setIsLoading(true);

    const response1 = await apiClient.uploads.getSingle(uploadId);

    if (!response1.success) {
      toast(response1.error.message);
      console.log(response1);
      setIsLoading(false);
      return;
    }

    setUploadDetails(response1.result.upload);

    const response2 = await apiClient.detections.getAllForUpload(uploadId);

    if (!response2.success) {
      toast(response2.error.message);
      console.log(response2);
      setIsLoading(false);
      return;
    }

    const parsedDetections = [];

    response2.result.detections.forEach((currentDetection) => {
      const imageDetectionDetails = {
        image_filename: currentDetection.image_filename,
        id: currentDetection.id,
        document_parser: JSON.parse(currentDetection.document_parser),
        parsing_postprocessor: currentDetection.parsing_postprocessor,
        text_recognizer: JSON.parse(currentDetection.text_recognizer),
        detections: JSON.parse(currentDetection.detections),
        original_detections: JSON.parse(currentDetection.original_detections),
      };

      imageDetectionDetails.detections = addKonvaFieldsToDetections(imageDetectionDetails.detections);

      parsedDetections.push(imageDetectionDetails);
    });

    setAllImagesDetectionDetails(parsedDetections);
    setCurrentImageIndex(0);
    setCurrentImageSavedDetectionDetails(parsedDetections[0]);
    setCurrentImageDetectionsWithUndoAndRedoSupport(parsedDetections[0].detections);
    setIsLoading(false);
  };

  const saveButtonOnClick = async (event) => {
    // reset text ids to indices ?

    // const updatedDetections2 = [...currentImageDetections]
    // for (const detection of updatedDetections2) {
    //   detection.text = transliterateAsync(detection.text, getGoogleLanguageCode("urdu"), getGoogleLanguageCode("english"))
    //   delay(100)
    // }
    // setCurrentImageDetections(updatedDetections2)

    // return

    setIsSaving(true);

    const updatedDetections = removeKonvaFieldsFromDetections(currentImageDetections);

    const response = await apiClient.detections.update(currentImageSavedDetectionDetails.id, updatedDetections);

    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      setIsSaving(false);
      return;
    }

    const updatedDetectionDetails = {
      image_filename: response.result.detection.image_filename,
      id: response.result.detection.id,
      detections: JSON.parse(response.result.detection.detections),
      original_detections: JSON.parse(response.result.detection.original_detections),
      document_parser: JSON.parse(response.result.detection.document_parser),
      parsing_postprocessor: response.result.detection.parsing_postprocessor,
      text_recognizer: JSON.parse(response.result.detection.text_recognizer),
    };
    updatedDetectionDetails.detections = addKonvaFieldsToDetections(updatedDetectionDetails.detections);

    const updatedAllImagesDetectionDetails = [...allImagesDetectionDetails];
    updatedAllImagesDetectionDetails[currentImageIndex] = updatedDetectionDetails;

    setAllImagesDetectionDetails(updatedAllImagesDetectionDetails);
    setCurrentImageSavedDetectionDetails(updatedAllImagesDetectionDetails[currentImageIndex]);
    setCurrentImageDetectionsWithUndoAndRedoSupport(updatedAllImagesDetectionDetails[currentImageIndex].detections);

    setHasUnsavedChanges(false);
    setIsSaving(false);
  };

  const resetToSavedButtonOnClick = async (event) => {
    setIsSaving(true);
    setSelectedId(null);

    setTimeout(function () {
      setCurrentImageDetectionsWithUndoAndRedoSupport(currentImageSavedDetectionDetails.detections);
      setHasUnsavedChanges(false);
      setIsSaving(false);
    }, 1000);
  };

  const resetToOriginalButtonOnClick = async (event) => {
    setIsSaving(true);
    setSelectedId(null);

    setTimeout(function () {
      const originalDetectionsWithKonvaFields = addKonvaFieldsToDetections(
        currentImageSavedDetectionDetails.original_detections
      );

      setCurrentImageDetectionsWithUndoAndRedoSupport(originalDetectionsWithKonvaFields);
      setHasUnsavedChanges(true);
      setIsSaving(false);
    }, 1000);
  };

  const downloadButtonOnClick = async (event) => {
    setDownloadDialogOpen(true);
  };

  const removeSelectedBbox = () => {
    if (selectedId === null) {
      console.log("no bbox selected");
      return;
    }

    console.log("selectedId");
    console.log(selectedId);
    console.log("removing");

    const updatedCurrentImageDetections = currentImageDetections.filter((detection) => detection.id !== selectedId);

    setCurrentImageDetectionsWithUndoAndRedoSupport(updatedCurrentImageDetections);
    setSelectedId(null);
    setHasUnsavedChanges(true);
  };

  const handleReRunOcrForBboxClick = async (event) => {
    const currentSelectedDetection = currentImageDetections.find((detection) => detection.id === selectedId);

    if (currentSelectedDetection === undefined) {
      window.alert("Selection issue");
      return;
    }

    const reRunDetectionId = selectedId;
    setSelectedId(null);
    setIsReRunningOcr(true);

    const response = await apiClient.customOcr.getDetectionsForImageAndBbox(
      currentImageSavedDetectionDetails.id,
      Math.round(currentSelectedDetection.x),
      Math.round(currentSelectedDetection.y),
      Math.round(currentSelectedDetection.x + currentSelectedDetection.width),
      Math.round(currentSelectedDetection.y + currentSelectedDetection.height),
      Math.round(currentSelectedDetection.rotation ?? 0)
    );

    if (!response.success) {
      toast(response.error.message);
      console.log(response);
      setIsReRunningOcr(false);
      return;
    }

    const newText = response.result.recognized_text;
    const updatedCurrentImageDetections = [...currentImageDetections];
    for (let i = 0; i < updatedCurrentImageDetections.length; i++) {
      if (updatedCurrentImageDetections[i].id === currentSelectedDetection.id) {
        updatedCurrentImageDetections[i].text = newText;
        break;
      }
    }

    setCurrentImageDetectionsWithUndoAndRedoSupport(updatedCurrentImageDetections);
    setSelectedId(reRunDetectionId);
    setIsReRunningOcr(false);
  };

  const handleTransformationModeButtonClick = (event) => {
    setTransformationMode(nextTransformationMode[transformationMode]);
  };

  const handlePreviousPageButtonClick = (event) => {
    setCurrentImageSavedDetectionDetails(allImagesDetectionDetails[currentImageIndex - 1]);
    setCurrentImageDetections(allImagesDetectionDetails[currentImageIndex - 1].detections);
    setCurrentImageIndex(currentImageIndex - 1);

    setSelectedId(null);
    setUndoStack([]);
    setRedoStack([]);
  };

  const handleNextPageButtonClick = (event) => {
    // console.log(allImagesDetectionDetails[currentImageIndex + 1])
    // return;
    setCurrentImageSavedDetectionDetails(allImagesDetectionDetails[currentImageIndex + 1]);
    setCurrentImageDetections(allImagesDetectionDetails[currentImageIndex + 1].detections);
    setCurrentImageIndex(currentImageIndex + 1);

    setSelectedId(null);
    setUndoStack([]);
    setRedoStack([]);
  };

  const setCurrentImageDetectionsWithUndoAndRedoSupport = (updatedDetections) => {
    setRedoStack([]); // clear the redo stack

    // check deep equal ?

    // push update into the undo stack
    const updatedUndoStack = [...undoStack];
    updatedUndoStack.push(currentImageDetections);
    if (updatedUndoStack.length > maxUndoLimit) {
      updatedUndoStack.shift();
    }
    setUndoStack(updatedUndoStack);

    setCurrentImageDetections(updatedDetections); // finally, perform the update
  };

  const undo = () => {
    if (undoStack.length === 0) {
      console.log("Nothing to undo");
      return;
    }

    console.log("Undoing");

    const updatedUndoStack = [...undoStack];
    const updatedRedoStack = [...redoStack];

    const lastCurrentImageDetections = updatedUndoStack.pop();
    updatedRedoStack.push(currentImageDetections);

    setUndoStack(updatedUndoStack);
    setRedoStack(updatedRedoStack);
    setCurrentImageDetections(lastCurrentImageDetections);
  };

  const redo = () => {
    if (redoStack.length === 0) {
      console.log("Nothing to redo");
      return;
    }

    console.log("Redoing");

    const updatedUndoStack = [...undoStack];
    const updatedRedoStack = [...redoStack];

    const lastUndoCurrentImageDetections = updatedRedoStack.pop();
    updatedUndoStack.push(currentImageDetections);

    setUndoStack(updatedUndoStack);
    setRedoStack(updatedRedoStack);
    setCurrentImageDetections(lastUndoCurrentImageDetections);
  };

  const handleLeftArrowButtonClick = (event) => {
    setCurrentImageDetectionsWithUndoAndRedoSupport(
      transformAndUpdateDetectionById(
        currentImageDetections,
        selectedId,
        transformationMode,
        "left",
        transformationAmount
      )
    );
    setHasUnsavedChanges(true);
  };

  const handleRightArrowButtonClick = (event) => {
    setCurrentImageDetectionsWithUndoAndRedoSupport(
      transformAndUpdateDetectionById(
        currentImageDetections,
        selectedId,
        transformationMode,
        "right",
        transformationAmount
      )
    );
    setHasUnsavedChanges(true);
  };

  const handleUpArrowButtonClick = (event) => {
    setCurrentImageDetectionsWithUndoAndRedoSupport(
      transformAndUpdateDetectionById(
        currentImageDetections,
        selectedId,
        transformationMode,
        "up",
        transformationAmount
      )
    );
    setHasUnsavedChanges(true);
  };

  const handleDownArrowButtonClick = (event) => {
    setCurrentImageDetectionsWithUndoAndRedoSupport(
      transformAndUpdateDetectionById(
        currentImageDetections,
        selectedId,
        transformationMode,
        "down",
        transformationAmount
      )
    );
    setHasUnsavedChanges(true);
  };

  const handleKeyDown = (event) => {
    if (event.repeat) {
      return;
    }

    /*
    Shortcuts
      save: Ctrl + S
      resetToSaved: Ctrl + R
      resetToOriginal: Ctrl + T
      download: Ctrl + D
      addNewBbox: Ctrl + A
      removeSelectedBbox: Ctrl + D
      moveToNextTransformationMode: Ctrl + M
      // no shortcut for rerun
      undo: Ctrl + Z
      redo: Ctrl + Y      
      Transform Left: Ctrl + Left Arrow
      Transform Right: Ctrl + Right Arrow
      Transform Up: Ctrl + Up Arrow
      Transform Down: Ctrl + Down Arrow
      prev: Pg Up
      next: Pg Dn

    */

    if (event.ctrlKey) {
      if ("esrtdm".indexOf(event.key) !== -1) {
        event.preventDefault();
      }

      switch (event.keyCode) {
        case 83: {
          // S
          saveButtonRef.current.click();
          break;
        }
        case 82: {
          // R
          resetToSavedButtonRef.current.click();
          break;
        }
        case 69: {
          // E
          resetToOriginalButtonRef.current.click();
          break;
        }
        case 68: {
          // D
          downloadButtonRef.current.click();
          break;
        }
        case 77: {
          // M
          transformationModeButtonRef.current.click();
          break;
        }

        case 90: {
          // Z
          undoButtonRef.current.click();
          break;
        }
        case 89: {
          // Y
          redoButtonRef.current.click();
          break;
        }
        case 37: {
          // Left arrow
          leftArrowButtonRef.current.click();
          break;
        }
        case 38: {
          // Up arrow
          upArrowButtonRef.current.click();
          break;
        }
        case 39: {
          // Right arrow
          rightArrowButtonRef.current.click();
          break;
        }
        case 40: {
          // Down arrow
          downArrowButtonRef.current.click();
          break;
        }
        case 65: {
          // A
          addNewBboxButtonRef.current.click();
          break;
        }
        case 68: {
          // D
          removeSelectedBboxButtonRef.current.click();
          break;
        }
        default: {
          break;
        }
      }
    } else {
      switch (event.keyCode) {
        case 33: {
          // Pg Up
          prevButtonRef.current.click();
          break;
        }
        case 34: {
          // Pg Dn
          nextButtonRef.current.click();
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  useEffect(() => {
    document.title = `Labelling - ${uploadId}`;

    getUploadAndDetections();

    // Handling keyboard shortcuts
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (addAnnotationMode !== "") {
      addNewBboxToast.current = toast("Click anywhere on the image to add a new Annotation.", {
        autoClose: 600_000,
      });
    } else {
      if (addNewBboxToast.current !== null) {
        toast.dismiss(addNewBboxToast.current);
      }
    }
  }, [addAnnotationMode]);

  const numControlButtonBlocks = 4;
  const controlButtonContainerWidth = `${Math.round(100 / numControlButtonBlocks)}%`;

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  if (isLoading) {
    return (
      <Container maxWidth="lg" className={classes.container}>
        <ClipLoader color={"#00FF00"} loading={true} size={25} />
      </Container>
    );
  }

  return (
    <div className={classes.pageContainer}>
      <Container maxWidth="lg" className={classes.container}>
        <DownloadDetectionsDialog
          uploadDetails={uploadDetails}
          open={downloadDialogOpen}
          setOpen={setDownloadDialogOpen}
          allImagesDetectionDetails={allImagesDetectionDetails}
        />

        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mb={2}>
          {isSaving ? (
            <Box mr={2}>
              <ClipLoader color={"#00FF00"} loading={true} size={25} />
            </Box>
          ) : null}
          <Box mr={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={saveButtonOnClick}
              disabled={!hasUnsavedChanges || isSaving}
              ref={saveButtonRef}
            >
              Save
            </Button>
          </Box>

          <Box mr={2}>
            <Button
              variant="contained"
              color="error"
              onClick={resetToSavedButtonOnClick}
              disabled={!hasUnsavedChanges || isSaving}
              ref={resetToSavedButtonRef}
            >
              Reset To Saved
            </Button>
          </Box>
          <Box mr={2}>
            <Button
              variant="contained"
              color="error"
              onClick={resetToOriginalButtonOnClick}
              disabled={isSaving}
              ref={resetToOriginalButtonRef}
            >
              Reset To Original
            </Button>
          </Box>

          <Box mr={2}>
            <Tooltip title={hasUnsavedChanges ? "Please save changes before downloading." : ""}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadButtonOnClick}
                  disabled={hasUnsavedChanges || isSaving}
                  ref={downloadButtonRef}
                >
                  Download
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Box>

        <Grid container spacing={0}>
          <Grid item xs={12} md={6} sx={{ boxShadow: 1 }}>
            <Canvas
              imageUrl={
                currentImageSavedDetectionDetails?.image_filename
                  ? `${mediaBaseUrl}/detection_images/${currentImageSavedDetectionDetails.image_filename}`
                  : undefined
              }
              detections={currentImageDetections}
              setDetections={setCurrentImageDetectionsWithUndoAndRedoSupport}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              hasChanged={hasUnsavedChanges}
              setHasChanged={setHasUnsavedChanges}
              handleKeyDown={handleKeyDown}
              addAnnotationMode={addAnnotationMode}
              setAddAnnotationMode={setAddAnnotationMode}
              detectionsLevel={currentImageSavedDetectionDetails?.document_parser?.detectionLevel}
            />
          </Grid>

          <Grid item xs={12} md={6} sx={{ boxShadow: 1 }}>
            <TextDisplayer
              detections={currentImageDetections}
              setDetections={setCurrentImageDetectionsWithUndoAndRedoSupport}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              hasChanged={hasUnsavedChanges}
              setHasChanged={setHasUnsavedChanges}
              detectionsLevel={currentImageSavedDetectionDetails?.document_parser?.detectionLevel[0]}
              textLanguage={currentImageSavedDetectionDetails?.text_recognizer?.language[0]}
            />
          </Grid>
        </Grid>

        <Box mt={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
          <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" width="100%" mb={2}>
            <Box
              width={controlButtonContainerWidth}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              {addAnnotationMode === "" ? (
                <Box
                  sx={{
                    border: "1px solid #babfb8",
                    paddingLeft: 2,
                    paddingRight: 2,
                    borderRadius: 2,
                  }}
                >
                  <IconButton color="primary" onClick={(event) => setAddAnnotationMode("rectangle")}>
                    <Crop169Icon />
                  </IconButton>
                  <IconButton color="primary" onClick={(event) => setAddAnnotationMode("polygon")}>
                    <PolylineIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button variant="contained" color="error" onClick={(event) => setAddAnnotationMode("")}>
                  Cancel
                </Button>
              )}
            </Box>

            <Box
              width={controlButtonContainerWidth}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="error"
                onClick={(event) => removeSelectedBbox()}
                disabled={selectedId === null}
                ref={removeSelectedBboxButtonRef}
              >
                Remove Annotation
              </Button>
            </Box>

            <Box
              width={controlButtonContainerWidth}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Tooltip
                title={
                  (!user.canCompute && "You do not have compute permission.") ||
                  (["word_level_document_parser", "tesseract_document_parser"].includes(
                    currentImageSavedDetectionDetails?.document_parser
                  ) &&
                    `Re run OCR for Bbox is not supported for pages parsed with the ${convert_key_to_label(
                      currentImageSavedDetectionDetails?.document_parser
                    )}.`)
                }
              >
                <span>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReRunOcrForBboxClick}
                    disabled={
                      selectedId === null ||
                      isReRunningOcr ||
                      !user.canCompute ||
                      ["word-level_document_parser", "tesseract_document_parser"].includes(
                        currentImageSavedDetectionDetails?.document_parser
                      )
                    }
                  >
                    {isReRunningOcr ? <BeatLoader color="blue" loading={true} size={10} /> : "Re run OCR"}
                  </Button>
                </span>
              </Tooltip>
            </Box>

            <Box
              width={controlButtonContainerWidth}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                variant="contained"
                color="primary"
                onClick={() => undo()}
                disabled={isSaving || undoStack.length === 0}
                ref={undoButtonRef}
              >
                <UndoIcon />
              </IconButton>

              <IconButton
                variant="contained"
                color="primary"
                onClick={() => redo()}
                disabled={isSaving || redoStack.length === 0}
                ref={redoButtonRef}
              >
                <RedoIcon />
              </IconButton>

              <MuiButtonWithTooltip
                tooltipTitle={`Current transformation mode is ${transformationMode}. Click to change to ${nextTransformationMode[transformationMode]}.`}
                buttonComponent={
                  <IconButton
                    variant="contained"
                    color={transformationModeButtonUi[transformationMode].color}
                    onClick={handleTransformationModeButtonClick}
                    ref={transformationModeButtonRef}
                  >
                    {transformationModeButtonUi[transformationMode].icon}
                  </IconButton>
                }
              />
            </Box>
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" width="50%" mb={2}>
            <Tooltip
              title={
                currentImageIndex === 0
                  ? "No more pages to turn left."
                  : hasUnsavedChanges
                  ? "You have unsaved changes."
                  : ""
              }
            >
              <span>
                <Button
                  variant="contained"
                  onClick={handlePreviousPageButtonClick}
                  disabled={currentImageIndex === 0 || hasUnsavedChanges}
                  ref={prevButtonRef}
                >
                  Prev
                </Button>
              </span>
            </Tooltip>

            <Typography>
              Page {currentImageIndex + 1} / {allImagesDetectionDetails.length}
            </Typography>

            <Tooltip
              title={
                currentImageIndex === allImagesDetectionDetails.length - 1
                  ? "No more pages to turn right."
                  : hasUnsavedChanges
                  ? "You have unsaved changes."
                  : ""
              }
            >
              <span>
                <Button
                  variant="contained"
                  onClick={handleNextPageButtonClick}
                  disabled={currentImageIndex === allImagesDetectionDetails.length - 1 || hasUnsavedChanges}
                  ref={nextButtonRef}
                >
                  Next
                </Button>
              </span>
            </Tooltip>

            <button onClick={handleLeftArrowButtonClick} ref={leftArrowButtonRef} style={{ display: "none" }}>
              Left
            </button>
            <button onClick={handleRightArrowButtonClick} ref={rightArrowButtonRef} style={{ display: "none" }}>
              Right
            </button>
            <button onClick={handleUpArrowButtonClick} ref={upArrowButtonRef} style={{ display: "none" }}>
              Up
            </button>
            <button onClick={handleDownArrowButtonClick} ref={downArrowButtonRef} style={{ display: "none" }}>
              Down
            </button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    height: "90vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // marginTop: theme.spacing(10),
    marginTop: 25,
  },
}));
