import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  TextField,
  Typography,
  List,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BeatLoader } from "react-spinners";

import { toast } from "react-toastify";
import MergeUploadsListItem from "./MergeUploadsListItem";
import apiClient from "../api/apiServices";

export default function BuyPagesDialog({ open, setOpen }) {
  const classes = useStyles();

  const [isBuying, setIsBuying] = useState(false);

  const [numPages, setNumPages] = useState(100);
  const [totalCost, setTotalCost] = useState(10);

  useEffect(() => {
    setTotalCost(numPages / 10);
  }, [numPages]);

  const handleCreateOrderButtonClick = () => {};

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        if (isBuying) {
          return;
        }
        setOpen(false);
      }}
    >
      <DialogTitle>Buy Pages</DialogTitle>

      <DialogContent>
        <Box
          width="100%"
          maxWidth="md"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          mb={2}
        >
          <Box mr={2}>
            <TextField
              inputProps={{ type: "number", min: 10, max: 10_000 }}
              value={numPages}
              onChange={(event) => {
                let newVal = event.target.value;
                if (newVal < 10) {
                  newVal = 10;
                } else if (newVal > 10_000) {
                  newVal = 10_000;
                }

                setNumPages(newVal);
              }}
            />
          </Box>

          <Typography variant="h5">{`  Pages: ₹ ${totalCost}`}</Typography>
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Box height={100}>
          <Tooltip title="Your account isn't allowed to Buy more Pages. Please contact an Admin for more details.">
            <span>
              <Button variant="contained" color="primary" onClick={handleCreateOrderButtonClick} disabled={true}>
                {isBuying ? <BeatLoader color="blue" loading={true} size={14} /> : "Proceed To Payment"}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  dialogInputTextField: {
    width: "50%",
  },
  dialogActions: {
    "&&": {
      justifyContent: "center",
    },
  },
}));
