import { Box, Typography, Avatar, Link } from "@mui/material";

export default function TeamPageCard({ avatarImageSrc, profileLink, title, descriptionLines, styleOverride }) {
  return (
    <Box
      sx={{
        width: "50%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: 0,
        ...(styleOverride ?? {}),
      }}
    >
      <Box width="33%" height="100%" display="flex" justifyContent="center" alignItems="center" m={0}>
        <Avatar alt={title} src={avatarImageSrc} sx={{ width: "75px", height: "75px" }} />
      </Box>
      <Box width="67%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" m={0}>
        <Link
          target="_blank"
          href={profileLink}
          rel="noreferrer"
          underline="hover"
          variant="h6"
          style={{ fontWeight: "bold" }}
        >
          {title}
        </Link>

        {descriptionLines.map((descriptionLine, index) => (
          <Typography variant="p" key={index}>
            {descriptionLine}
          </Typography>
        ))}
      </Box>
    </Box>
  );
}
