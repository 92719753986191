import { useState, useEffect, useContext } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { TextField, Button, Link, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

import apiServicesClient from "../api/apiServices";
import {
  saveToLocalStorage,
  loadFromLocalStorage,
} from "../localStorage/localStorage";
import PasswordInput from "../components/PasswordInput";
import userContext from "../context/userContext";

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useContext(userContext);

  const [loading, setLoading] = useState(false);
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    document.title = "Lipikar - Login";

    const currentUser = loadFromLocalStorage("user");
    if (currentUser) {
      navigate("/");
    }
  }, []);

  const handleLoginButtonClick = async (event) => {
    event.preventDefault();
    setLoading(true);

    const response = await apiServicesClient.user.login(
      emailOrUsername,
      password
    );

    if (response.success) {
      saveToLocalStorage("user", response.result.user);
      saveToLocalStorage("tokens", response.result.tokens);
      window.dispatchEvent(new Event("userChanged"));
      navigate("/");
      return;
    }

    if (response?.error?.validationErrors) {
      for (const errorField in response.error.validationErrors) {
        response.error.validationErrors[errorField].forEach(
          (validationError) => {
            toast(validationError);
          }
        );
      }
    } else if (response?.error?.message) {
      toast(response.error.message);
    }

    setLoading(false);
  };

  if (user !== null) {
    return <Navigate to="/" />;
  }

  return (
    <div className={classes.container}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        p={3}
        sx={{ boxShadow: 2 }}
        mt={5}
      >
        <Box mb={3}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mb={1}
          >
            <img className={classes.logo} src="/static/iitd-logo.png" />
          </Box>
          <Box width="100%" mr={2}>
            <Typography
              className={classes.heading}
              variant="h3"
              style={{ textAlign: "center" }}
            >
              LIPIKAR
            </Typography>
          </Box>
        </Box>

        <form
          className={classes.formContainer}
          onSubmit={handleLoginButtonClick}
        >
          <TextField
            className={classes.inputTextField}
            type="email"
            label="Username"
            InputLabelProps={{ shrink: !!emailOrUsername }}
            value={emailOrUsername}
            onChange={(event) => setEmailOrUsername(event.target.value)}
          />
          <PasswordInput
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleLoginButtonClick}
            disabled={loading}
            type="submit"
          >
            {loading ? (
              <BeatLoader color="white" loading={true} size={10} />
            ) : (
              "Login"
            )}
          </Button>
        </form>

        <div className={classes.alternateLinkText}>
          <Link href="/auth/register">
            Don't have an account? Register here.
          </Link>
        </div>

        <div>
          <Link href="/user-manual">User Manual</Link>
        </div>
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#64daf8, #f7f3df, #cad3c8)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logo: {
    width: 200,
    height: 200,
  },
  appTitle: {
    margin: 0,
    padding: 0,
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
  alternateLinkText: {
    marginTop: 20,
  },
}));
