import { useContext, useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Box,
  Slider,
  Checkbox, // do not remove this import
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Navigate, useNavigate } from "react-router-dom";

import userContext from "../context/userContext";
import { deleteFromLocalStorage, saveToLocalStorage } from "../localStorage/localStorage";
import apiClient from "../api/apiServices";
import BuyPagesDialog from "../components/BuyPagesDialog";

const Settings = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user, appSettings, setAppSettings } = useContext(userContext);

  // console.log(user);

  const [isLoggingOut, setIsLogginOut] = useState(false);

  const [bboxStrokeWidthFactor, setBboxStrokeWidthFactor] = useState(appSettings.bboxStrokeWidthFactor);
  const [enableMergeBboxesFunctionality, setEnableMergeBboxesFunctionality] = useState(
    appSettings.enableMergeBboxesFunctionality
  );
  const [autoSortWhileMergingBboxes, setAutoSortWhileMergingBboxes] = useState(appSettings.autoSortWhileMergingBboxes);
  const [redirectToHistoryPageOnNewUploadSubmit, setRedirectToHistoryPageOnNewUploadSubmit] = useState(
    appSettings.redirectToHistoryPageOnNewUploadSubmit
  );
  const [showParsingPostprocessorOptionsForNewUpload, setShowParsingPostprocessorOptionsForNewUpload] = useState(
    appSettings.showParsingPostprocessorOptionsForNewUpload
  );
  const [showTemplateImagesOnNewUploadPage, setShowTemplateImagesOnNewUploadPage] = useState(
    appSettings.showTemplateImagesOnNewUploadPage
  );

  const [pagesRemaining, setPagesRemaining] = useState(null);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [buyPagesDialogOpen, setBuyPagesDialogOpen] = useState(false);

  const resetPasswordButtonOnClick = (event) => {
    event.preventDefault();
    navigate("/auth/reset-password");
  };

  const logoutButtonOnClick = async (event) => {
    event.preventDefault();
    setIsLogginOut(true);
    await apiClient.user.logout();

    deleteFromLocalStorage("user");
    deleteFromLocalStorage("tokens");

    window.dispatchEvent(new Event("userChanged"));
    setIsLogginOut(false);
    navigate("/auth/login");
  };

  const saveAppSettingsButtonOnClick = async (event) => {
    const updatedAppSettings = {
      bboxStrokeWidthFactor: bboxStrokeWidthFactor,
      enableMergeBboxesFunctionality: false, //enableMergeBboxesFunctionality,
      autoSortWhileMergingBboxes: false, //autoSortWhileMergingBboxes,
      redirectToHistoryPageOnNewUploadSubmit: redirectToHistoryPageOnNewUploadSubmit,
      showParsingPostprocessorOptionsForNewUpload: showParsingPostprocessorOptionsForNewUpload,
      showTemplateImagesOnNewUploadPage: showTemplateImagesOnNewUploadPage,
    };

    setAppSettings(updatedAppSettings);
    saveToLocalStorage("appSettings", updatedAppSettings);
    setHasUnsavedChanges(false);
  };

  const getAvailableCreditsFromApi = async () => {
    const response = await apiClient.user.getAvailableCredits();
    if (!response.success) {
      console.log(response);
      return;
    }

    setPagesRemaining(Math.floor(response.result.credits));
  };

  useEffect(() => {
    document.title = "Lipikar - Settings";

    getAvailableCreditsFromApi();
  }, []);

  if (user === null) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <div className={classes.pageContainer}>
      <BuyPagesDialog open={buyPagesDialogOpen} setOpen={setBuyPagesDialogOpen} />

      <Container maxWidth="md" className={classes.container}>
        <Box
          width="100%"
          borderRadius={5}
          mb={5}
          p={3}
          sx={{
            boxShadow: 4,
            backgroundImage: "linear-gradient(#f9dcd0, #f6d4c8, #f9dbdb)",
          }}
        >
          <Box width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center" mb={2}>
            <Typography
              variant="h4"
              className={classes.heading}
              style={{ color: "#333", fontWeight: "bold", textAlign: "center" }}
            >
              User
            </Typography>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mb={2}
          >
            <Typography variant="h6">Username: {user?.username}</Typography>
            <Typography variant="h6">Email: {user?.email}</Typography>
            <Typography variant="h6">Full Name: {user?.fullName}</Typography>
            <Typography variant="h6">
              Pages Available: {pagesRemaining ?? "Could not get available pages quota from server."}
            </Typography>
          </Box>

          <Box width="100%" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" mb={2}>
            {user?.email === "abhilakshsinghreen@gmail.com" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setBuyPagesDialogOpen(true);
                }}
              >
                Buy Pages
              </Button>
            )}
            <Button variant="contained" color="error" onClick={resetPasswordButtonOnClick}>
              Reset Password
            </Button>
            <Button variant="contained" color="error" onClick={logoutButtonOnClick} disabled={isLoggingOut}>
              Logout
            </Button>
          </Box>
        </Box>

        <Box
          width="100%"
          bgcolor="#EEE"
          borderRadius={5}
          mb={5}
          p={3}
          sx={{
            boxShadow: 4,
            backgroundImage: "linear-gradient(#e9f4f7, #faf9f4, #cfd4ce)",
          }}
        >
          <Box width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center" mb={2}>
            <Typography
              variant="h4"
              className={classes.heading}
              style={{ color: "#333", fontWeight: "bold", textAlign: "center" }}
            >
              App Settings
            </Typography>
          </Box>

          <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" mb={2}>
            <Typography variant="h6">Bbox Stroke Width Factor</Typography>

            <Box width="50%" ml={4} mr={4}>
              <Slider
                size="small"
                min={0.001}
                max={0.01}
                step={0.0001}
                value={bboxStrokeWidthFactor}
                onChange={(e, newValue) => {
                  setHasUnsavedChanges(true);
                  setBboxStrokeWidthFactor(newValue);
                }}
              />
            </Box>

            <Typography variant="h6">{bboxStrokeWidthFactor}</Typography>
          </Box>

          <Box width="100%" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" mb={2}>
            <Box width="40%">
              <Typography variant="h6">Auto Redirect to History Page after Submitting New Upload:</Typography>
            </Box>

            <Box width="40%">
              <Checkbox
                checked={redirectToHistoryPageOnNewUploadSubmit}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  setRedirectToHistoryPageOnNewUploadSubmit(e.target.checked);
                }}
              />
            </Box>
          </Box>

          {/* <Box width="100%" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" mb={2}>
            <Box width="40%">
              <Typography variant="h6">Show Parsing Postprocessor Options for New Upload:</Typography>
            </Box>

            <Box width="40%">
              <Checkbox
                checked={showParsingPostprocessorOptionsForNewUpload}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  setShowParsingPostprocessorOptionsForNewUpload(e.target.checked);
                }}
              />
            </Box>
          </Box> */}

          {/* <Box width="100%" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" mb={2}>
            <Box width="40%">
              <Typography variant="h6">Show Template Images on New Upload Page:</Typography>
            </Box>

            <Box width="40%">
              <Checkbox
                checked={showTemplateImagesOnNewUploadPage}
                onChange={(e) => {
                  setHasUnsavedChanges(true);
                  setShowTemplateImagesOnNewUploadPage(e.target.checked);
                }}
              />
            </Box>
          </Box> */}

          {/* <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Enable Merge Bboxes Function: </Typography>

          <Box width="50%" ml={4}>
            <Checkbox
              checked={enableMergeBboxesFunctionality}
              onChange={(e) =>
                setEnableMergeBboxesFunctionality(e.target.checked)
              }
            />
          </Box>
        </Box>

        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h6">Auto Sort while Merging Bboxes:</Typography>

          <Box width="50%" ml={4}>
            <Checkbox
              checked={autoSortWhileMergingBboxes}
              onChange={(e) => setAutoSortWhileMergingBboxes(e.target.checked)}
              disabled={!enableMergeBboxesFunctionality}
            />
          </Box>
        </Box> */}

          <Box width="100%" display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={saveAppSettingsButtonOnClick}
              disabled={!hasUnsavedChanges}
            >
              {hasUnsavedChanges ? "Save" : "Saved"}
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Settings;

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  container: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 25,
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
  alternateLinkText: {
    marginTop: 20,
  },
}));
