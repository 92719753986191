import validator from "validator";

const upperCaseRegex = /[A-Z]+/;
const lowerCaseRegex = /[a-z]+/;
const digitsRegex = /[0-9]+/;
const specialCharRegex = /[^A-Za-z0-9]+/;
const alphanumRegex = /^[0-9a-z]+$/;
const uploadFilenameRegex = /^[a-zA-Z0-9 -]+$/;

function checkStrongPassword(password) {
  let strongPasswordErrors = "";

  if (password.length < 8) {
    strongPasswordErrors += "Password must be at least 8 characters long. ";
  }

  if (!/[a-z]/i.test(password) || !/[0-9]/i.test(password)) {
    strongPasswordErrors += "Password must be alphanumeric. ";
  }

  return strongPasswordErrors;
}

const validators = {
  register: (
    email,
    fullName,
    username,
    password,
    confirmPassword,
    phoneNumber,
    organization,
    hasAgreedToTermsAndConditions
  ) => {
    const validationErrors = {};

    if (!validator.isEmail(email)) {
      validationErrors.email = "Invalid Email.";
    }

    if (fullName.trimStart().trimEnd().length < 3) {
      validationErrors.fullName =
        "Full Name must be at least 3 characters long.";
    }

    if (username.trimStart().trimEnd().length < 3) {
      validationErrors.username =
        "Full Name must be at least 3 characters long.";
    }

    const strongPasswordErrors = checkStrongPassword(password);
    if (strongPasswordErrors !== "") {
      validationErrors.password = strongPasswordErrors;
    }

    if (confirmPassword !== password) {
      validationErrors.confirmPassword = "Passwords do not match.";
    }

    if (!validator.isMobilePhone(phoneNumber)) {
      validationErrors.phoneNumber = "Invalid Phone Number.";
    }

    if (organization.trimStart().trimEnd().length < 3) {
      validationErrors.organization =
        "Organization Name must be at least 3 characters long.";
    }

    if (!hasAgreedToTermsAndConditions) {
      validationErrors.hasAgreedToTermsAndConditions =
        "You can only register if you agree to the terms and conditions.";
    }

    return validationErrors;
  },
  resetPassword: (newPassword, confirmNewPassword) => {
    const validationErrors = {};

    const strongPasswordErrors = checkStrongPassword(newPassword);
    if (strongPasswordErrors !== "") {
      validationErrors.newPassword = strongPasswordErrors;
    }

    if (confirmNewPassword !== newPassword) {
      validationErrors.confirmNewPassword = "Passwords do not match.";
    }

    return validationErrors;
  },
  changeUploadFilename: (newFilename) => {
    if (newFilename.length === 0) {
      return "Filename cannot be empty.";
    }

    if (!uploadFilenameRegex.test(newFilename)) {
      return "Filename can only have letters, numbers, hyphens, and spaces.";
    }

    return "";
  },
};

export default validators;
