import {
  centroid2D,
  distance2D,
  moveDetectionLeft,
  moveDetectionRight,
  moveDetectionUp,
  moveDetectionDown,
  rotateDetectionLeft,
  rotateDetectionRight,
  scaleDetectionLeft,
  scaleDetectionRight,
  scaleDetectionUp,
  scaleDetectionDown,
} from "./mathUtils";
import { convertToUnicodeEscape } from "./stringUtils";

function addExhaustivePropsToBbox(bbox) {
  return {
    ...bbox,
    rotation: bbox.rotation ?? 0,
    rotationRadians: bbox.rotation ? (bbox.rotation * Math.PI) / 180 : 0,
    width: bbox.x_max - bbox.x_min,
    height: bbox.y_max - bbox.y_min,
  };
}

function detectionHorizontalComparator(detection1, detection2) {
  return detection1.text_bbox.x_min - detection2.text_bbox.x_min;
}

function detection2dGeometricComparator(detection1, detection2) {
  console.warn("detection2dComparator function has not yet been implemented");
  //pass
}

function getPolygonBoundingBox(polygonPoints) {
  let xMin = 1e6,
    yMin = 1e6,
    xMax = -1,
    yMax = -1;

  for (const point of polygonPoints) {
    xMin = Math.min(xMin, point[0]);
    yMin = Math.min(yMin, point[1]);

    xMax = Math.max(xMax, point[0]);
    yMax = Math.max(yMax, point[1]);
  }

  const width = xMax - xMin;
  const height = yMax - yMin;

  return {
    x_min: xMin,
    y_min: yMin,
    x_max: xMax,
    y_max: yMax,
    width: width,
    height: height,
  };
}

function detection2dIndexBasedComparator(detection1, detection2) {
  return detection1.text_bbox.line_index === detection2.text_bbox.line_index
    ? detection1.text_bbox.word_index - detection2.text_bbox.word_index
    : detection1.text_bbox.line_index - detection2.text_bbox.line_index;
}

function detection2dDistance(detection1, detection2) {
  const detection1MinPoint = [detection1.text_bbox.x_min, detection1.text_bbox.y_min];
  const detection1MaxPoint = [detection1.text_bbox.x_max, detection1.text_bbox.y_max];

  const detection2MinPoint = [detection2.text_bbox.x_min, detection2.text_bbox.y_min];
  const detection2MaxPoint = [detection2.text_bbox.x_max, detection2.text_bbox.y_max];

  const detection1Centroid = centroid2D([detection1MinPoint, detection1MaxPoint]);
  const detection2Centroid = centroid2D([detection2MinPoint, detection2MaxPoint]);

  return distance2D(detection1Centroid, detection2Centroid);
}

function detectionCentroid(detection) {
  return centroid2D([
    [detection.text_bbox.x_min, detection.text_bbox.y_min],
    [detection.text_bbox.x_max, detection.text_bbox.y_max],
  ]);
}

function getAverageBboxDimensions(detections) {
  const numBboxes = detections.length;
  if (numBboxes === 0) {
    return [100, 100];
  }

  let widthSum = 0,
    heightSum = 0;

  for (const detection of detections) {
    widthSum += detection.width;
    heightSum += detection.height;
  }

  return [widthSum / numBboxes, heightSum / numBboxes];
}

function getNearestDetection(detections, targetDetection) {
  let nearestDetection = null;
  let nearestDetectionDistance = Infinity;

  for (const detection of detections) {
    const detectionDistance = detection2dDistance(detection, targetDetection);
    if (detectionDistance < nearestDetectionDistance) {
      nearestDetectionDistance = detectionDistance;
      nearestDetection = detection;
    }
  }

  return nearestDetection;
}

function sortDetectionsHorizontally(detections) {
  detections.sort(detectionHorizontalComparator);

  for (let i = 0; i < detections.length; i++) {
    detections[i].word_index = i;
  }

  return detections;
}

function getNewDetectionId(detections) {
  const detectionIds = detections.map((detection) => parseInt(detection.text_id));
  const newDetectionId = detectionIds.length > 0 ? Math.max(...detectionIds) + 1 : 0;
  return newDetectionId.toString();
}

function createNewDetectionForPointerPosAndBboxDimensions(pointerPos, bboxDimensions, newDetectionId) {
  const newDetectionX = pointerPos[0] - 0.5 * bboxDimensions[0];
  const newDetectionY = pointerPos[1] - 0.5 * bboxDimensions[1];

  const newDetection = {
    // original fields: text, text_bbox, text_id
    text: "$$no_text",
    text_bbox: {
      x_min: Math.round(newDetectionX),
      y_min: Math.round(newDetectionY),
      x_max: Math.round(newDetectionX + bboxDimensions[0]),
      y_max: Math.round(newDetectionY + bboxDimensions[1]),
      line_index: -1,
      word_index: -1,
    },
    text_id: newDetectionId,

    // Konva fields
    x: newDetectionX,
    y: newDetectionY,
    width: bboxDimensions[0],
    height: bboxDimensions[1],
    stroke: "#FF0000",
    id: newDetectionId,
  };

  return newDetection;
}

function createNewDetectionForPolygon(polygonPoints, newDetectionId) {
  const bbox = getPolygonBoundingBox(polygonPoints);

  const newDetection = {
    text: "$$no_text",
    text_bbox: {
      x_min: Math.round(bbox.x_min),
      y_min: Math.round(bbox.y_min),
      x_max: Math.round(bbox.x_max),
      y_max: Math.round(bbox.y_max),
      line_index: -1,
      word_index: -1,
    },
    text_id: newDetectionId,
    polygon: polygonPoints,
    type: "polygon",

    // Konva fields
    x: bbox.x_min,
    y: bbox.y_min,
    width: bbox.width,
    height: bbox.height,
    stroke: "#FF0000",
    id: newDetectionId,
  };

  return newDetection;
}

function getAllDetectionsWithLineIndex(detections, targetLineIndex) {
  return detections.filter((detection) => detection.text_bbox.line_index === targetLineIndex);
}

function removeAllDetectionsWithLineIndex(detections, targetLineIndex) {
  return detections.filter((detection) => detection.text_bbox.line_index !== targetLineIndex);
}

function updateDetectionsWordIndicesToFollowArrayIndices(detections) {
  for (let i = 0; i < detections.length; i++) {
    detections[i].text_bbox.word_index = i;
  }

  return detections;
}

function insertNewDetection(detections, newDetection, detectionsLevel) {
  if (detections.length === 0) {
    detections.push(newDetection);
    return detections;
  }

  const nearestDetection = getNearestDetection(detections, newDetection);

  if (detectionsLevel === "word") {
    newDetection.text_bbox.line_index = nearestDetection.text_bbox.line_index;

    const targetLineIndex = newDetection.text_bbox.line_index;
    let allDetectionsOfTargetLineIndex = getAllDetectionsWithLineIndex(detections, targetLineIndex);
    allDetectionsOfTargetLineIndex.push(newDetection);
    allDetectionsOfTargetLineIndex.sort(detectionHorizontalComparator);
    allDetectionsOfTargetLineIndex = updateDetectionsWordIndicesToFollowArrayIndices(allDetectionsOfTargetLineIndex);

    detections = removeAllDetectionsWithLineIndex(detections, targetLineIndex);
    detections = [...detections, ...allDetectionsOfTargetLineIndex];
  } else if (detectionsLevel === "line") {
    newDetection.text_bbox.line_index = nearestDetection.text_bbox.line_index;

    for (let i = 0; i < detections.length; i++) {
      if (detections[i].text_bbox.line_index >= newDetection.text_bbox.line_index) {
        detections[i].text_bbox.line_index++;
      }
    }

    detections.push(newDetection);
  }

  detections.sort(detection2dIndexBasedComparator);
  return detections;
}

function transformDetection(detection, transformationType, transformationDirection, transformationAmount) {
  if (transformationType === "location" && transformationDirection === "left") {
    detection = moveDetectionLeft(detection, transformationAmount);
  } else if (transformationType === "location" && transformationDirection === "right") {
    detection = moveDetectionRight(detection, transformationAmount);
  } else if (transformationType === "location" && transformationDirection === "up") {
    detection = moveDetectionUp(detection, transformationAmount);
  } else if (transformationType === "location" && transformationDirection === "down") {
    detection = moveDetectionDown(detection, transformationAmount);
  } else if (transformationType === "rotation" && transformationDirection === "left") {
    detection = rotateDetectionLeft(detection, transformationAmount);
  } else if (transformationType === "rotation" && transformationDirection === "right") {
    detection = rotateDetectionRight(detection, transformationAmount);
  } else if (transformationType === "scaling" && transformationDirection === "left") {
    detection = scaleDetectionLeft(detection, transformationAmount);
  } else if (transformationType === "scaling" && transformationDirection === "right") {
    detection = scaleDetectionRight(detection, transformationAmount);
  } else if (transformationType === "scaling" && transformationDirection === "up") {
    detection = scaleDetectionUp(detection, transformationAmount);
  } else if (transformationType === "scaling" && transformationDirection === "down") {
    detection = scaleDetectionDown(detection, transformationAmount);
  } else {
    console.error("Invalid transformation for Detection");
    detection = detection;
  }

  return detection;
}

function findDetectionById(detections, targetId) {
  return detections.find((detection) => detection.id === targetId);
}

function findDetectionIndexById(detections, targetId) {
  return detections.findIndex((detection) => detection.id === targetId);
}

function findDetection2dIndexById(detections2d, targetId) {
  let foundi = -1,
    foundj = -1;

  for (let i = 0; i < detections2d.length; i++) {
    for (let j = 0; j < detections2d[i].length; j++) {
      if (detections2d[i][j].id === targetId) {
        foundi = i;
        foundj = j;
        i = detections2d.length;
        break;
      }
    }
  }

  return [foundi, foundj];
}

function updateDetectionByIndex(detections, targetIndex, updatedDetection) {
  const updatedDetections = [...detections];
  updatedDetections[targetIndex] = updatedDetection;
  return updatedDetections;
}

function updateDetectionById(detections, updatedDetection) {}

function transformAndUpdateDetectionById(
  detections,
  targetId,
  transformationMode,
  transformationDirection,
  transformationAmount
) {
  if (targetId === null) {
    return detections;
  }

  const selectedDetectionIndex = findDetectionIndexById(detections, targetId);
  if (selectedDetectionIndex === -1) {
    return detections;
  }

  const updatedDetection = transformDetection(
    detections[selectedDetectionIndex],
    transformationMode,
    transformationDirection,
    transformationAmount
  );
  return updateDetectionByIndex(detections, selectedDetectionIndex, updatedDetection);
}

function sanitizeDetections(detections) {
  // TODO: implement the function
  return detections;
}

function sanitizeDetections2d(detections2d) {
  detections2d = detections2d.filter((lineDetections) => lineDetections.length > 0);

  for (let i = 0; i < detections2d.length; i++) {
    for (let j = 0; j < detections2d[i].length; j++) {
      // if (detections2d[i][j].text_bbox.line_index !== i || detections2d[i][j].text_bbox.word_index !== j) {
      //   console.log(
      //     `${i} - ${detections2d[i][j].text_bbox.line_index}, ${j} - ${detections2d[i][j].text_bbox.word_index}`
      //   );
      // }

      detections2d[i][j] = {
        ...detections2d[i][j],
        text_bbox: {
          ...detections2d[i][j].text_bbox,
          line_index: i,
          word_index: j,
        },
      };
    }
  }

  return detections2d;
}

function checkIfDetections2dIsSanitized(detections2d, logDiscrepancies = false) {
  let isSanitized = true;

  for (let i = 0; i < detections2d.length; i++) {
    for (let j = 0; j < detections2d[i].length; j++) {
      isSanitized &= detections2d[i][j].text_bbox.line_index === i && detections2d[i][j].text_bbox.word_index === j;
      if (
        (detections2d[i][j].text_bbox.line_index !== i || detections2d[i][j].text_bbox.word_index !== j) &&
        logDiscrepancies
      ) {
        console.log(`Discrepancy at [${i}][${j}]`);
        console.log(`    line_index: ${detections2d[i][j].text_bbox.line_index}`);
        console.log(`    word_index: ${detections2d[i][j].text_bbox.word_index}`);
      }

      detections2d[i][j] = {
        ...detections2d[i][j],
        text_bbox: {
          ...detections2d[i][j].text_bbox,
          line_index: i,
          word_index: j,
        },
      };
    }
  }

  if (logDiscrepancies && isSanitized) {
    console.log("Detections 2D are sanitized.");
  }

  return isSanitized;
}

function moveDetectionToNewLineAboveCurrentLineInDetections2d(detections2d, targetLineIndex, targetWordIndex) {
  let updatedDetections2D = [...detections2d];

  const targetDetection = updatedDetections2D[targetLineIndex][targetWordIndex];

  updatedDetections2D[targetLineIndex] = [
    ...updatedDetections2D[targetLineIndex].slice(0, targetWordIndex),
    ...updatedDetections2D[targetLineIndex].slice(targetWordIndex + 1),
  ];
  updatedDetections2D.splice(targetLineIndex, 0, [targetDetection]);

  updatedDetections2D = sanitizeDetections2d(updatedDetections2D);
  checkIfDetections2dIsSanitized(updatedDetections2D, true);
  return updatedDetections2D;
}

function moveDetectionToNewLineBelowCurrentLineInDetections2d(detections2d, targetLineIndex, targetWordIndex) {
  let updatedDetections2D = [...detections2d];

  const targetDetection = updatedDetections2D[targetLineIndex][targetWordIndex];

  updatedDetections2D[targetLineIndex] = [
    ...updatedDetections2D[targetLineIndex].slice(0, targetWordIndex),
    ...updatedDetections2D[targetLineIndex].slice(targetWordIndex + 1),
  ];
  updatedDetections2D.splice(targetLineIndex + 1, 0, [targetDetection]);

  updatedDetections2D = sanitizeDetections2d(updatedDetections2D);
  checkIfDetections2dIsSanitized(updatedDetections2D, true);
  return updatedDetections2D;
}

function addKonvaFieldsToDetections(detections) {
  detections = detections.map((detection, index) => ({
    // original fields: text, text_bbox, text_id
    ...detection,

    // added fields
    rotation: detection.text_bbox.rotation ?? 0,
    x: detection.text_bbox.x_min,
    y: detection.text_bbox.y_min,
    width: detection.text_bbox.x_max - detection.text_bbox.x_min,
    height: detection.text_bbox.y_max - detection.text_bbox.y_min,
    stroke: detection.class_confidence === -1 ? "#FF0000" : "#00FF00",
    id: detection.text_id.toString(),
  }));

  return detections;
}

function removeKonvaFieldsFromDetections(detections) {
  detections = detections.map((detection, index) => ({
    // original fields: text, text_bbox, text_id
    text_id: detection.text_id,
    text_bbox: {
      x_min: Math.round(detection.x),
      y_min: Math.round(detection.y),
      x_max: Math.round(detection.x + detection.width),
      y_max: Math.round(detection.y + detection.height),
      rotation: detection.rotation,
      line_index: detection.text_bbox.line_index,
      word_index: detection.text_bbox.word_index,
    },
    text: convertToUnicodeEscape(detection.text),
    type: detection?.type === "polygon" ? "polygon" : "rectangle",
    polygon: Array.isArray(detection?.polygon) ? detection.polygon : [],
  }));

  return detections;
}

export {
  addExhaustivePropsToBbox,
  detection2dIndexBasedComparator,
  getAverageBboxDimensions,
  sortDetectionsHorizontally,
  getNewDetectionId,
  createNewDetectionForPointerPosAndBboxDimensions,
  insertNewDetection,
  transformDetection,
  findDetectionById,
  findDetectionIndexById,
  updateDetectionByIndex,
  updateDetectionById,
  transformAndUpdateDetectionById,
  sanitizeDetections,
  sanitizeDetections2d,
  checkIfDetections2dIsSanitized,
  moveDetectionToNewLineAboveCurrentLineInDetections2d,
  moveDetectionToNewLineBelowCurrentLineInDetections2d,
  addKonvaFieldsToDetections,
  removeKonvaFieldsFromDetections,
  findDetection2dIndexById,
  detectionCentroid,
  createNewDetectionForPolygon,
  getPolygonBoundingBox,
};
