import { Box, Checkbox, Grid, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Sort as SortIcon,
} from "@mui/icons-material";

export default function UploadListItemHeader({
  allUploadsSelected,
  selectAllCheckboxOnClick,
  uploadsSortingKey,
  setUploadsSortingKey,
}) {
  const classes = useStyles();

  return (
    <Box width="100%" mb={2}>
      <Box
        className={classes.listHeaderContainer}
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        mb={2}
        bgcolor="#D9D9D9"
        borderRadius={1}
        p={1}
      >
        <Box mr={2}>
          <Checkbox checked={allUploadsSelected} onChange={(e) => selectAllCheckboxOnClick(e.target.checked)} />
        </Box>

        <Grid container spacing={1}>
          <Grid className={classes.gridItem} item xs={2} md={1}>
            <Typography className={classes.listHeaderCell} variant="h5" color="primary">
              Id
            </Typography>
            <IconButton
              onClick={() => {
                if (uploadsSortingKey === "-id") {
                  setUploadsSortingKey("id");
                } else {
                  setUploadsSortingKey("-id");
                }
              }}
            >
              {uploadsSortingKey === "id" ? (
                <ArrowUpwardIcon />
              ) : uploadsSortingKey === "-id" ? (
                <ArrowDownwardIcon />
              ) : (
                <SortIcon />
              )}
            </IconButton>
          </Grid>

          <Grid className={classes.gridItem} item xs={12} md={6}>
            <Typography className={classes.listHeaderCell} variant="h5" color="primary">
              Filename
            </Typography>
            <IconButton
              onClick={() => {
                if (uploadsSortingKey === "-filename") {
                  setUploadsSortingKey("filename");
                } else {
                  setUploadsSortingKey("-filename");
                }
              }}
            >
              {uploadsSortingKey === "filename" ? (
                <ArrowUpwardIcon />
              ) : uploadsSortingKey === "-filename" ? (
                <ArrowDownwardIcon />
              ) : (
                <SortIcon />
              )}
            </IconButton>
          </Grid>

          <Grid className={classes.gridItem} item xs={12} md={2}>
            <Typography className={classes.listHeaderCell} variant="h5" color="primary">
              Num Pages
            </Typography>
            <IconButton
              onClick={() => {
                if (uploadsSortingKey === "-numPages") {
                  setUploadsSortingKey("numPages");
                } else {
                  setUploadsSortingKey("-numPages");
                }
              }}
            >
              {uploadsSortingKey === "numPages" ? (
                <ArrowUpwardIcon />
              ) : uploadsSortingKey === "-numPages" ? (
                <ArrowDownwardIcon />
              ) : (
                <SortIcon />
              )}
            </IconButton>
          </Grid>

          <Grid className={classes.gridItem} item xs={12} md={3}>
            <Typography className={classes.listHeaderCell} variant="h5" color="primary">
              Date and Time
            </Typography>
            <IconButton
              onClick={() => {
                if (uploadsSortingKey === "-created_at") {
                  setUploadsSortingKey("created_at");
                } else {
                  setUploadsSortingKey("-created_at");
                }
              }}
            >
              {uploadsSortingKey === "created_at" ? (
                <ArrowUpwardIcon />
              ) : uploadsSortingKey === "-created_at" ? (
                <ArrowDownwardIcon />
              ) : (
                <SortIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  listHeaderContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "#EEEEEE",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  listHeaderCell: {
    borderRadius: 5,
    "&&": {
      marginRight: 5,
    },
  },
  gridItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));
