function setString(key, string) {}

function getString(key) {}

function saveToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function loadFromLocalStorage(key) {
  const value = JSON.parse(localStorage.getItem(key));
  return value;
}

function deleteFromLocalStorage(key) {
  localStorage.removeItem(key);
}

export { saveToLocalStorage, loadFromLocalStorage, deleteFromLocalStorage };
