import { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BeatLoader } from "react-spinners";

import apiClient from "../api/apiServices";

export default function DeleteUploadsDialog({ open, setOpen, selectedUploadIds, refetchAllUploads }) {
  const classes = useStyles();

  const [abortController, setAbortController] = useState(new AbortController());

  const [isDeleting, setIsDeleting] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [hasAbortedSuccessfully, setHasAbortedSuccessfully] = useState(false);

  const [processingStatus, setProcessingStatus] = useState("");
  const [processingProgressPercentage, setProcessingProgressPercentage] = useState(0.0);

  const deleteUploads = async () => {
    setIsDeleting(true);

    setProcessingProgressPercentage(0.0);

    let loopBroken = false;
    let failedToDeleteCount = 0;

    for (let i = 0; i < selectedUploadIds.length; i++) {
      if (abortController.signal.aborted) {
        if (i >= 0) {
          setProcessingStatus(`Cancelled after deleting ${i} uploads`);
        } else {
          setProcessingStatus("Cancelled");
        }

        loopBroken = true;
        break;
      }

      const deleteUploadResponse = await apiClient.uploads.delete([selectedUploadIds[i]]);
      if (!deleteUploadResponse.success) {
        failedToDeleteCount++;
        console.log(deleteUploadResponse.error);
      }

      setProcessingStatus(`Deleted upload with id: ${selectedUploadIds[i]}`);
      setProcessingProgressPercentage(100 * parseFloat(((i + 1) / selectedUploadIds.length).toFixed(4)));
    }

    await refetchAllUploads();

    setHasAbortedSuccessfully(loopBroken);

    if (!loopBroken) {
      if (failedToDeleteCount > 0) {
        window.alert(`Failed to delete ${failedToDeleteCount} out of ${selectedUploadIds.length} uploads.`);
      }
      setProcessingStatus("Uploads Deleted.");
    }

    setIsDeleting(false);
  };

  useEffect(() => {
    if (!selectedUploadIds || selectedUploadIds.length === 0) {
      return;
    }

    deleteUploads();
  }, [selectedUploadIds]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        if (isDeleting) {
          return;
        }
        setOpen(false);
      }}
    >
      <DialogTitle>Delete Uploads</DialogTitle>

      <DialogContent>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Box width="100%" display="flex" justifyContent="flex-start">
            <Typography variant="p">{processingStatus}</Typography>
          </Box>

          {isDeleting ? (
            processingProgressPercentage === 0.0 ? (
              <BeatLoader color="blue" loading={true} size={14} />
            ) : (
              <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <LinearProgress
                  sx={{ width: "45vw", marginRight: 2 }}
                  color="primary"
                  variant="determinate"
                  value={processingProgressPercentage}
                />
                <p>
                  <strong>{`${processingProgressPercentage.toFixed(2)} %`}</strong>
                </p>
              </Box>
            )
          ) : null}
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Box display="flex" justifyContent="flex-end" width="50%">
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              abortController.abort();
              setIsCancelled(true);
            }}
            disabled={isCancelled || processingProgressPercentage === 100}
          >
            {isCancelled ? (hasAbortedSuccessfully ? "Cancelled" : "Stoping ...") : "Cancel"}
          </Button>

          {processingProgressPercentage === 100 || hasAbortedSuccessfully ? (
            <Button variant="contained" color="primary" onClick={() => setOpen(false)} sx={{ marginLeft: 2 }}>
              Back to History Page
            </Button>
          ) : null}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    "&&": {
      justifyContent: "flex-end",
    },
  },
}));
