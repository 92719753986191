import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, IconButton, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  SaveOutlined as SaveOutlinedIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
} from "@mui/icons-material";

import userContext from "../context/userContext";

const urlsToHideNavbar = ["/auth/login", "/auth/register"];

export default function Navbar() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(userContext);

  if (urlsToHideNavbar.includes(location.pathname)) {
    return null;
  }

  return (
    <AppBar
      position="static"
      sx={{
        height: "10vh",
      }}
    >
      <Toolbar className={classes.toolbar}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="80%">
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <img className={classes.toolbarLogo} src="/static/iitd-logo.png" />
            <Box mr={2}>
              <Typography
                className={classes.heading}
                variant="h4"
                onClick={() => navigate("/upload-new")}
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                LIPIKAR
              </Typography>
            </Box>
            <Box>
              <Box>
                <Typography
                  className={classes.headingSubtitle}
                  // sx={{ fontWeight: "bold" }}
                  variant="p"
                >
                  OCR For Indian Languages
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={classes.headingSubtitle}
                  // sx={{ fontWeight: "bold" }}
                  variant="p"
                >
                  Indian Institute of Technology Delhi
                </Typography>
              </Box>
            </Box>
          </Box>

          {user !== null ? (
            <div className={classes.buttons}>
              <Button component={Link} to="/upload-new" color="inherit" startIcon={<CloudUploadOutlinedIcon />}>
                New
              </Button>
              <Button component={Link} to="/history/1" color="inherit" startIcon={<SaveOutlinedIcon />}>
                History
              </Button>
              <IconButton component={Link} to="/settings" color="inherit">
                <SettingsIcon />
              </IconButton>

              <IconButton component={Link} to="/user-manual" color="inherit">
                <HelpIcon />
              </IconButton>
            </div>
          ) : (
            <div className={classes.buttons}>
              <Button component={Link} to="/auth/login" color="inherit">
                Login
              </Button>
              <Button component={Link} to="/auth/register" color="inherit">
                Register
              </Button>
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  toolbarLogo: {
    width: 60,
    height: 60,
    marginRight: 20,
    marginTop: 5,
    marginBottom: 5,
  },
  heading: {
    background: "linear-gradient(45deg, #FFD700 30%, #FFA500 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  headingSubtitle: {
    // background: "linear-gradient(45deg, #ff6b6b, #ffff8d, #88ff88)",
    // "-webkit-background-clip": "text",
    // "-webkit-text-fill-color": "transparent",
  },
  buttons: {
    "& > *": {
      marginLeft: 50,
    },
  },
  title: {
    flexGrow: 1,
  },
}));
