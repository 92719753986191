import JSZip from "jszip";

function getContentTypeForFilename(fileName) {
  const extension = fileName.split(".").pop().toLowerCase();
  switch (extension) {
    case "json":
      return "application/json";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    default:
      return "application/octet-stream";
  }
}

async function jsZipFileToFileObj(fileName, file) {
  const content = await file.async("arraybuffer");
  const contentType = getContentTypeForFilename(fileName);
  const blob = new Blob([content], { type: contentType });
  const fileObj = new File([blob], fileName, { type: contentType });
  return fileObj;
}

async function loadLipikarImportZip(file) {
  const zip = new JSZip();
  const zipData = await zip.loadAsync(file);
  const zipFiles = zipData.files;

  const uploads = {};

  for (const entry in zipFiles) {
    const entryPath = entry.split("/");

    if (!uploads[entryPath[0]]) {
      uploads[entryPath[0]] = {
        name: entryPath[0],
        type: "folder",
        content: {},
      };
    }

    if (entryPath.length === 1 || entryPath[1] === "") {
      continue;
    }

    if (entryPath[1] === "detections.json" || entryPath[1] === "uploadDetails.json") {
      uploads[entryPath[0]].content[entryPath[1]] = {
        name: entryPath[1],
        type: "file",
        content: await jsZipFileToFileObj(entry, zipFiles[entry]),
      };
    }

    if (entryPath.length === 2 || entryPath[2] === "") {
      continue;
    }

    if (entryPath[1] === "images" && !uploads[entryPath[0]].content.images) {
      uploads[entryPath[0]].content["images"] = {
        name: "images",
        type: "folder",
        content: {},
      };
    }

    uploads[entryPath[0]].content[[entryPath[1]]].content[entryPath[2]] = {
      name: entryPath[2],
      type: "file",
      content: await jsZipFileToFileObj(entryPath[2], zipFiles[entry]),
    };
  }

  const uploadsArray = [];
  for (const uploadName in uploads) {
    const uploadMainFolder = {
      name: uploads[uploadName].name,
      type: "folder",
      content: [],
    };

    if (uploads[uploadName].content["detections.json"]) {
      uploadMainFolder.content.push({
        name: "detections.json",
        type: "file",
        content: uploads[uploadName].content["detections.json"].content,
      });
    }

    if (uploads[uploadName].content["uploadDetails.json"]) {
      uploadMainFolder.content.push({
        name: "uploadDetails.json",
        type: "file",
        content: uploads[uploadName].content["uploadDetails.json"].content,
      });
    }

    uploadMainFolder.content.push({
      name: "images",
      type: "folder",
      content: [],
    });

    if (uploads[uploadName].content["images"]) {
      const imageFolderIndex = uploadMainFolder.content.length - 1;

      for (const imageName in uploads[uploadName].content["images"].content) {
        uploadMainFolder.content[imageFolderIndex].content.push({
          name: uploads[uploadName].content["images"].content[imageName].name,
          type: "file",
          content: uploads[uploadName].content["images"].content[imageName].content,
        });
      }
    }

    uploadsArray.push(uploadMainFolder);
  }

  return uploadsArray;
}

const getDirectoryStructure = async (directoryHandle) => {
  const directoryStructure = [];

  for await (const entry of directoryHandle.values()) {
    const directoryStructureEntry = {
      name: entry.name,
    };

    if (entry.kind === "directory") {
      directoryStructureEntry.type = "folder";
      directoryStructureEntry.content = await getDirectoryStructure(entry);
    } else {
      directoryStructureEntry.type = "file";
      directoryStructureEntry.content = await entry.getFile();
    }

    directoryStructure.push(directoryStructureEntry);
  }

  return directoryStructure;
};

export { loadLipikarImportZip, getDirectoryStructure };
