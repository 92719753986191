import { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Link,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import userContext from "../context/userContext";
import apiServicesClient from "../api/apiServices";
import validators from "../api/validators";
import { getHumanReadableFieldName } from "../api/apiUtils";
import PasswordInput from "../components/PasswordInput";

export default function Register() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user } = useContext(userContext);

  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);

  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [organization, setOrganization] = useState("");
  const [hasAgreedToTermsAndConditions, setHasAgreedToTermsAndConditions] =
    useState(false);

  const handleRegisterButtonClick = async (event) => {
    event.preventDefault();

    if (Object.keys(validationErrors).length > 0) {
      setDisplayValidationErrors(true);
      return;
    }

    setLoading(true);

    const response = await apiServicesClient.user.register(
      email,
      password,
      username,
      fullName,
      phoneNumber,
      organization
    );

    if (!response.success) {
      if (response.error.validationErrors) {
        for (var key in response.error.validationErrors) {
          toast(
            `${getHumanReadableFieldName(key)}: ${
              response.error.validationErrors[key][0]
            }`
          );
        }
      } else {
        toast(response.error.message);
      }

      setLoading(false);
      return;
    }

    toast("You have been registered successfully. Redirecting to login page.");
    setLoading(false);
    setTimeout(() => navigate("/auth/login"), 5000);
  };

  useEffect(() => {
    document.title = "Lipikar - Register";
  }, []);

  useEffect(() => {
    setValidationErrors(
      validators.register(
        email,
        fullName,
        username,
        password,
        confirmPassword,
        phoneNumber,
        organization,
        hasAgreedToTermsAndConditions
      )
    );
  }, [
    email,
    fullName,
    username,
    password,
    confirmPassword,
    phoneNumber,
    organization,
    hasAgreedToTermsAndConditions,
  ]);

  if (user !== null) {
    return <Navigate to="/" />;
  }

  return (
    <div className={classes.container}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        p={3}
        sx={{ boxShadow: 2 }}
        mt={5}
        mb={10}
      >
        <Box mb={3}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mb={1}
          >
            <img className={classes.logo} src="/static/iitd-logo.png" />
          </Box>
          <Box width="100%" mr={2}>
            <Typography
              className={classes.heading}
              variant="h3"
              style={{ textAlign: "center" }}
            >
              LIPIKAR
            </Typography>
          </Box>
        </Box>

        <form
          className={classes.formContainer}
          onSubmit={handleRegisterButtonClick}
        >
          <TextField
            className={classes.inputTextField}
            type="email"
            label="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            error={
              displayValidationErrors && validationErrors.email !== undefined
            }
            helperText={displayValidationErrors && validationErrors.email}
          />

          <TextField
            className={classes.inputTextField}
            type="text"
            label="Full Name"
            value={fullName}
            onChange={(event) => setFullName(event.target.value)}
            error={
              displayValidationErrors && validationErrors.fullName !== undefined
            }
            helperText={displayValidationErrors && validationErrors.fullName}
          />

          <TextField
            className={classes.inputTextField}
            type="text"
            label="Username"
            value={username}
            onChange={(event) => setUsername(event.target.value)}
            error={
              displayValidationErrors && validationErrors.username !== undefined
            }
            helperText={displayValidationErrors && validationErrors.username}
          />

          <PasswordInput
            label="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            error={
              displayValidationErrors && validationErrors.password !== undefined
            }
            helperText={displayValidationErrors && validationErrors.password}
          />

          <PasswordInput
            label="Confirm Password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            error={
              displayValidationErrors &&
              validationErrors.confirmPassword !== undefined
            }
            helperText={
              displayValidationErrors && validationErrors.confirmPassword
            }
          />

          <TextField
            className={classes.inputTextField}
            type="text"
            label="Phone Number"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            error={
              displayValidationErrors &&
              validationErrors.phoneNumber !== undefined
            }
            helperText={displayValidationErrors && validationErrors.phoneNumber}
          />

          <TextField
            className={classes.inputTextField}
            type="text"
            label="Organization"
            value={organization}
            onChange={(event) => setOrganization(event.target.value)}
            error={
              displayValidationErrors &&
              validationErrors.organization !== undefined
            }
            helperText={
              displayValidationErrors && validationErrors.organization
            }
          />

          <Box mb={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              mb={-2}
            >
              <Checkbox
                checked={hasAgreedToTermsAndConditions}
                onChange={(e) =>
                  setHasAgreedToTermsAndConditions(e.target.checked)
                }
              />

              <Box
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb={3}
              >
                <div className={classes.alternateLinkText}>
                  I agree to the{" "}
                  <Link
                    href="/static/Terms%20and%20Conditions.pdf"
                    target="_blank"
                  >
                    Terms and Conditions
                  </Link>
                </div>
              </Box>
            </Box>
            <Typography variant="p" color="error" fontSize={12}>
              {displayValidationErrors &&
                validationErrors.hasAgreedToTermsAndConditions}
            </Typography>
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleRegisterButtonClick}
            disabled={
              loading ||
              (displayValidationErrors &&
                Object.keys(validationErrors).length !== 0)
            }
          >
            {loading ? (
              <BeatLoader color="white" loading={true} size={10} />
            ) : (
              "Register"
            )}
          </Button>
        </form>

        <div className={classes.alternateLinkText}>
          <Link href="/auth/login">Already have an account? Login here.</Link>
        </div>

        <div>
          <Link href="/user-manual">User Manual</Link>
        </div>
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#64daf8, #f7f3df, #cad3c8)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logo: {
    width: 200,
    height: 200,
  },
  appTitle: {
    margin: 0,
    padding: 0,
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
  alternateLinkText: {
    marginTop: 20,
  },
}));
