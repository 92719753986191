const defaultAppSettings = {
  bboxStrokeWidthFactor: 0.0033,
  enableMergeBboxesFunctionality: false,
  autoSortWhileMergingBboxes: false,
  redirectToHistoryPageOnNewUploadSubmit: true,
  showParsingPostprocessorOptionsForNewUpload: false,
  showTemplateImagesOnNewUploadPage: true,
};

function getAppSettings() {
  let loadedAppSettings = JSON.parse(localStorage.getItem("appSettings"));
  if (loadedAppSettings === null) {
    loadedAppSettings = {};
  }

  let numKeysNotFound = 0;

  for (const key of Object.keys(defaultAppSettings)) {
    if (loadedAppSettings[key] === undefined) {
      numKeysNotFound++;
      loadedAppSettings[key] = defaultAppSettings[key];
    }
  }

  if (numKeysNotFound > 0) {
    localStorage.setItem("appSettings", JSON.stringify(loadedAppSettings));
  }

  return loadedAppSettings;
}

export default getAppSettings;
