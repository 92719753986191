import { useState, useEffect, useRef, useContext } from "react";
import { Box, TextField, List, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Splitscreen as SplitscreenIcon,
} from "@mui/icons-material";

import userContext from "../context/userContext";
import MuiButtonWithTooltip from "../components/MuiButtonWithTooltip";
import Select from "../components/Select";
import {
  sortDetectionsHorizontally,
  sanitizeDetections2d,
  checkIfDetections2dIsSanitized,
  findDetection2dIndexById,
} from "../utils/detectionUtils";
import { toLowerFirst, toUpperFirst } from "../utils/stringUtils";
import apiClient from "../api/apiServices";

const bboxTextUpdateDebounceTime = 1000; // time in ms
const autoScrollToSelectedBbox = true;

const parseDetectionsForTextDisplayer = (sortedDetections) => {
  const parsedDetections = [];
  let lastDetection = null;
  sortedDetections.forEach((detection) => {
    if (
      lastDetection === null ||
      // lastDetection.text_bbox.page_index !== detection.text_bbox.page_index ||
      // lastDetection.text_bbox.block_index !== detection.text_bbox.block_index ||
      // lastDetection.text_bbox.par_index !== detection.text_bbox.par_index ||
      lastDetection.text_bbox.line_index !== detection.text_bbox.line_index
    ) {
      parsedDetections.push([]);
    }

    parsedDetections[parsedDetections.length - 1].push(detection);
    lastDetection = detection;
  });

  return parsedDetections;
};

const parseDetectionsForLabelingPage = (detections2D) => {
  const labellingPageDetections = [];

  detections2D.forEach((lineDetections) => {
    lineDetections.forEach((wordDetection) => {
      labellingPageDetections.push(wordDetection);
    });
  });

  labellingPageDetections.sort((a, b) => {
    if (a.text_bbox.line_index < b.text_bbox.line_index) {
      return -1;
    } else if (a.text_bbox.line_index > b.text_bbox.line_index) {
      return 1;
    }

    if (a.text_bbox.word_index < b.text_bbox.word_index) {
      return -1;
    } else if (a.text_bbox.word_index > b.text_bbox.word_index) {
      return 1;
    }

    console.log("Two bboxes found with the same line_index and word_index.");
    return 0;
  });

  return labellingPageDetections;
};

export default function TextDisplayer({
  detections,
  setDetections,
  selectedId,
  setSelectedId,
  hasChanged,
  setHasChanged,
  detectionsLevel,
  textLanguage,
}) {
  const classes = useStyles();
  const { backendConfig } = useContext(userContext);
  const textDisplayerInputfontFamily = textLanguage === "manipuri_meitei" ? "NotoSansMeeteiMayek" : "Roboto";

  const verticalListRef = useRef(null);
  const horizontalListRef = useRef(null);

  const [parsedDetections, setParsedDetections] = useState([]);

  const [selectedDetectionText, setSelectedDetectionText] = useState("");
  const [previousSelectedDetectionId, setPreviousSelectedDetectionId] = useState("-1");

  const [moveSelectedBboxLeftEnabled, setMoveSelectedBboxLeftEnabled] = useState(false);
  const [moveSelectedBboxRightEnabled, setMoveSelectedBboxRightEnabled] = useState(false);
  const [moveSelectedBboxUpEnabled, setMoveSelectedBboxUpEnabled] = useState(false);
  const [moveSelectedBboxDownEnabled, setMoveSelectedBboxDownEnabled] = useState(false);
  const [moveSelectedLineUpEnabled, setMoveSelectedLineUpEnabled] = useState(false);
  const [moveSelectedLineDownEnabled, setMoveSelectedLineDownEnabled] = useState(false);
  const [moveSelectedBboxToNewLineUpEnabled, setMoveSelectedBboxToNewLineUpEnabled] = useState(false);
  const [moveSelectedBboxToNewLineDownEnabled, setMoveSelectedBboxToNewLineDownEnabled] = useState(false);

  const [selectedBboxLineIndex, setSelectedBboxLineIndex] = useState(-1);
  const [selectedBboxWordIndex, setSelectedBboxWordIndex] = useState(-1);

  const [textAlign, setTextAlign] = useState("left");

  useEffect(() => {
    if (selectedId !== null && autoScrollToSelectedBbox) {
      scrollToBbox(selectedId);
    }

    // get previously selected bbox if it hasn't been deleted
    const previousSelectedDetection = detections.find((detection) => detection.id === previousSelectedDetectionId);

    // get currently selected bbox if any is selected
    const currentSelectedDetection = detections.find((detection) => detection.id === selectedId);

    if (previousSelectedDetection !== undefined) {
      const updatedDetections = [...detections];

      for (let i = 0; i < updatedDetections.length; i++) {
        if (updatedDetections[i].id === previousSelectedDetectionId) {
          setHasChanged(hasChanged || updatedDetections[i].text !== selectedDetectionText);

          updatedDetections[i].text = selectedDetectionText;
          break;
        }
      }

      setDetections(updatedDetections);
    }

    if (currentSelectedDetection !== undefined) {
      setSelectedDetectionText(currentSelectedDetection.text);
      setPreviousSelectedDetectionId(selectedId);
    } else {
      setSelectedDetectionText("");
      setPreviousSelectedDetectionId("-1");
    }

    // update line index and word index here also
    if (selectedId == null) {
      setSelectedBboxLineIndex(-1);
      setSelectedBboxWordIndex(-1);
      return;
    }

    for (let i = 0; i < parsedDetections.length; i++) {
      for (let j = 0; j < parsedDetections[i].length; j++) {
        if (parsedDetections[i][j].id === selectedId) {
          setSelectedBboxLineIndex(i);
          setSelectedBboxWordIndex(j);
          i = parsedDetections.length; // to break the outer loop
          break;
        }
      }
    }
  }, [selectedId]);

  const moveSelectedBboxLeft = (e) => {
    let updatedDetections2d = [...parsedDetections];
    const i = selectedBboxLineIndex,
      j = selectedBboxWordIndex;
    const selectedDetectionId = updatedDetections2d[i][j].id;

    const temp = updatedDetections2d[i][j];
    updatedDetections2d[i][j] = updatedDetections2d[i][j - 1];
    updatedDetections2d[i][j - 1] = temp;

    updatedDetections2d = sanitizeDetections2d(updatedDetections2d);
    checkIfDetections2dIsSanitized(updatedDetections2d, true);
    const updatedSelectedBboxIndices = findDetection2dIndexById(updatedDetections2d, selectedDetectionId);
    setDetections(parseDetectionsForLabelingPage(updatedDetections2d));
    setHasChanged(true);
    setSelectedBboxLineIndex(updatedSelectedBboxIndices[0]);
    setSelectedBboxWordIndex(updatedSelectedBboxIndices[1]);
  };

  const moveSelectedBboxRight = (e) => {
    let updatedDetections2d = [...parsedDetections];
    const i = selectedBboxLineIndex,
      j = selectedBboxWordIndex;
    const selectedDetectionId = updatedDetections2d[i][j].id;

    const temp = updatedDetections2d[i][j];
    updatedDetections2d[i][j] = updatedDetections2d[i][j + 1];
    updatedDetections2d[i][j + 1] = temp;

    updatedDetections2d = sanitizeDetections2d(updatedDetections2d);
    checkIfDetections2dIsSanitized(updatedDetections2d, true);
    const updatedSelectedDetectionIndices = findDetection2dIndexById(updatedDetections2d, selectedDetectionId);
    setDetections(parseDetectionsForLabelingPage(updatedDetections2d));
    setHasChanged(true);
    setSelectedBboxLineIndex(updatedSelectedDetectionIndices[0]);
    setSelectedBboxWordIndex(updatedSelectedDetectionIndices[1]);
  };

  const moveSelectedBboxToPreviousLine = (e) => {
    let updatedDetections2d = [...parsedDetections];
    const i = selectedBboxLineIndex,
      j = selectedBboxWordIndex;

    const selectedDetection = updatedDetections2d[i][j];
    updatedDetections2d[i].splice(j, 1);
    updatedDetections2d[i - 1].push(selectedDetection);
    updatedDetections2d[i - 1] = sortDetectionsHorizontally(updatedDetections2d[i - 1]);

    updatedDetections2d = sanitizeDetections2d(updatedDetections2d);
    checkIfDetections2dIsSanitized(updatedDetections2d, true);
    const updatedSelectedDetectionIndices = findDetection2dIndexById(updatedDetections2d, selectedDetection.id);
    setDetections(parseDetectionsForLabelingPage(updatedDetections2d));
    setHasChanged(true);
    setSelectedBboxLineIndex(updatedSelectedDetectionIndices[0]);
    setSelectedBboxWordIndex(updatedSelectedDetectionIndices[1]);
  };

  const moveSelectedBboxToNextLine = (e) => {
    let updatedDetections2d = [...parsedDetections];
    const i = selectedBboxLineIndex,
      j = selectedBboxWordIndex;

    const selectedDetection = updatedDetections2d[i][j];
    updatedDetections2d[i].splice(j, 1);
    updatedDetections2d[i + 1].push(selectedDetection);
    updatedDetections2d[i + 1] = sortDetectionsHorizontally(updatedDetections2d[i + 1]);

    updatedDetections2d = sanitizeDetections2d(updatedDetections2d);
    checkIfDetections2dIsSanitized(updatedDetections2d, true);
    const updatedSelectedDetectionIndices = findDetection2dIndexById(updatedDetections2d, selectedDetection.id);
    setDetections(parseDetectionsForLabelingPage(updatedDetections2d));
    setHasChanged(true);
    setSelectedBboxLineIndex(updatedSelectedDetectionIndices[0]);
    setSelectedBboxWordIndex(updatedSelectedDetectionIndices[1]);
  };

  const moveSelectedLineUp = (e) => {
    let updatedDetections2d = [...parsedDetections];
    const i = selectedBboxLineIndex,
      j = selectedBboxWordIndex;
    const selectedDetectionId = updatedDetections2d[i][j].id;

    const temp = updatedDetections2d[i];
    updatedDetections2d[i] = updatedDetections2d[i - 1];
    updatedDetections2d[i - 1] = temp;

    updatedDetections2d = sanitizeDetections2d(updatedDetections2d);
    checkIfDetections2dIsSanitized(updatedDetections2d, true);
    const updatedSelectedDetectionIndices = findDetection2dIndexById(updatedDetections2d, selectedDetectionId);
    setDetections(parseDetectionsForLabelingPage(updatedDetections2d));
    setHasChanged(true);
    setSelectedBboxLineIndex(updatedSelectedDetectionIndices[0]);
    setSelectedBboxWordIndex(updatedSelectedDetectionIndices[1]);
  };

  const moveSelectedLineDown = (e) => {
    let updatedDetections2d = [...parsedDetections];
    const i = selectedBboxLineIndex,
      j = selectedBboxWordIndex;
    const selectedDetectionId = updatedDetections2d[i][j].id;

    const temp = updatedDetections2d[i];
    updatedDetections2d[i] = updatedDetections2d[i + 1];
    updatedDetections2d[i + 1] = temp;

    updatedDetections2d = sanitizeDetections2d(updatedDetections2d);
    checkIfDetections2dIsSanitized(updatedDetections2d, true);
    const updatedSelectedDetectionIndices = findDetection2dIndexById(updatedDetections2d, selectedDetectionId);
    setDetections(parseDetectionsForLabelingPage(updatedDetections2d));
    setHasChanged(true);
    setSelectedBboxLineIndex(updatedSelectedDetectionIndices[0]);
    setSelectedBboxWordIndex(updatedSelectedDetectionIndices[1]);
  };

  const moveSelectedBboxToNewLineUp = (e) => {
    let updatedDetections2d = [...parsedDetections];
    const i = selectedBboxLineIndex,
      j = selectedBboxWordIndex;
    const selectedDetection = updatedDetections2d[i][j];

    updatedDetections2d[i] = [...updatedDetections2d[i].slice(0, j), ...updatedDetections2d[i].slice(j + 1)];
    updatedDetections2d.splice(i, 0, [selectedDetection]);

    updatedDetections2d = sanitizeDetections2d(updatedDetections2d);
    checkIfDetections2dIsSanitized(updatedDetections2d, true);
    const updatedSelectedDetectionIndices = findDetection2dIndexById(updatedDetections2d, selectedDetection.id);
    setDetections(parseDetectionsForLabelingPage(updatedDetections2d));
    setHasChanged(true);
    setSelectedBboxLineIndex(updatedSelectedDetectionIndices[0]);
    setSelectedBboxWordIndex(updatedSelectedDetectionIndices[1]);
  };

  const moveSelectedBboxToNewLineDown = (e) => {
    let updatedDetections2d = [...parsedDetections];
    const i = selectedBboxLineIndex,
      j = selectedBboxWordIndex;
    const selectedDetection = updatedDetections2d[i][j];

    updatedDetections2d[i] = [...updatedDetections2d[i].slice(0, j), ...updatedDetections2d[i].slice(j + 1)];
    updatedDetections2d.splice(i + 1, 0, [selectedDetection]);

    updatedDetections2d = sanitizeDetections2d(updatedDetections2d);
    checkIfDetections2dIsSanitized(updatedDetections2d, true);
    const updatedSelectedDetectionIndices = findDetection2dIndexById(updatedDetections2d, selectedDetection.id);
    setDetections(parseDetectionsForLabelingPage(updatedDetections2d));
    setHasChanged(true);
    setSelectedBboxLineIndex(updatedSelectedDetectionIndices[0]);
    setSelectedBboxWordIndex(updatedSelectedDetectionIndices[1]);
  };

  const scrollToBbox = (bboxId) => {
    const itemToScrollTo = document.getElementById(`text-displayer-text-field-${bboxId}`);

    if (!itemToScrollTo) {
      return;
    }

    itemToScrollTo.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setTextAlign(textLanguage !== undefined && textAlignRightLanguages.includes(textLanguage) ? "right" : "left");
  }, [textLanguage]);

  useEffect(() => {
    const updatedParsedDetections = parseDetectionsForTextDisplayer(detections);
    setParsedDetections(updatedParsedDetections);
  }, [detections]);

  useEffect(() => {
    if (
      selectedBboxLineIndex < 0 ||
      selectedBboxLineIndex >= parsedDetections.length ||
      selectedBboxWordIndex < 0 ||
      selectedBboxWordIndex >= parsedDetections[selectedBboxLineIndex].length
    ) {
      return;
    }

    setSelectedDetectionText(parsedDetections[selectedBboxLineIndex][selectedBboxWordIndex].text);
  }, [parsedDetections]);

  useEffect(() => {
    if (
      selectedBboxLineIndex < 0 ||
      selectedBboxLineIndex >= parsedDetections.length ||
      selectedBboxWordIndex < 0 ||
      selectedBboxWordIndex >= parsedDetections[selectedBboxLineIndex].length
    ) {
      setMoveSelectedLineUpEnabled(false);
      setMoveSelectedLineDownEnabled(false);
      setMoveSelectedBboxUpEnabled(false);
      setMoveSelectedBboxDownEnabled(false);
      setMoveSelectedBboxLeftEnabled(false);
      setMoveSelectedBboxRightEnabled(false);
      setMoveSelectedBboxToNewLineUpEnabled(false);
      setMoveSelectedBboxToNewLineDownEnabled(false);
      return;
    }

    setMoveSelectedLineUpEnabled(selectedBboxLineIndex !== 0);
    setMoveSelectedLineDownEnabled(selectedBboxLineIndex !== parsedDetections.length - 1);
    setMoveSelectedBboxUpEnabled(selectedBboxLineIndex !== 0);
    setMoveSelectedBboxDownEnabled(selectedBboxLineIndex !== parsedDetections.length - 1);
    setMoveSelectedBboxLeftEnabled(selectedBboxWordIndex !== 0);
    setMoveSelectedBboxRightEnabled(selectedBboxWordIndex !== parsedDetections[selectedBboxLineIndex].length - 1);
    setMoveSelectedBboxToNewLineUpEnabled(parsedDetections[selectedBboxLineIndex].length > 1);
    setMoveSelectedBboxToNewLineDownEnabled(parsedDetections[selectedBboxLineIndex].length > 1);
  }, [parsedDetections, selectedBboxLineIndex, selectedBboxWordIndex]);

  useEffect(() => {
    if (selectedBboxLineIndex == -1 || selectedBboxWordIndex == -1) {
      return;
    }

    if (selectedDetectionText === parsedDetections[selectedBboxLineIndex][selectedBboxWordIndex]["text"]) {
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      console.log("updateing");
      console.log(selectedDetectionText);
      const updatedParsedDetections = [...parsedDetections];
      updatedParsedDetections[selectedBboxLineIndex][selectedBboxWordIndex]["text"] = selectedDetectionText;

      // No need to sanitize here

      setDetections(parseDetectionsForLabelingPage(updatedParsedDetections));
      setHasChanged(true);
    }, bboxTextUpdateDebounceTime);

    return () => clearTimeout(delayDebounceFn);
  }, [selectedDetectionText]);

  return (
    <Box
      width="100%"
      height="60vh"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      p={1}
    >
      <Box
        width="100%"
        height="5vh"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        pl={1}
        mb={1}
      >
        <MuiButtonWithTooltip
          tooltipTitle="Move selected line up"
          buttonComponent={
            <IconButton onClick={moveSelectedLineUp} disabled={!moveSelectedLineUpEnabled}>
              <ArrowUpwardIcon color={moveSelectedLineUpEnabled ? "primary" : "error"} />
            </IconButton>
          }
        />
        <MuiButtonWithTooltip
          tooltipTitle="Move selected line down"
          buttonComponent={
            <IconButton onClick={moveSelectedLineDown} disabled={!moveSelectedLineDownEnabled}>
              <ArrowDownwardIcon color={moveSelectedLineDownEnabled ? "primary" : "error"} />
            </IconButton>
          }
        />

        {detectionsLevel === "word" ? (
          <>
            <MuiButtonWithTooltip
              tooltipTitle="Move selected bbox to previous line"
              buttonComponent={
                <IconButton
                  onClick={moveSelectedBboxToPreviousLine}
                  disabled={detectionsLevel !== "word" || !moveSelectedBboxUpEnabled}
                >
                  <KeyboardArrowUpIcon color={moveSelectedBboxUpEnabled ? "primary" : "error"} />
                </IconButton>
              }
            />
            <MuiButtonWithTooltip
              tooltipTitle="Move selected bbox to next line"
              buttonComponent={
                <IconButton
                  onClick={moveSelectedBboxToNextLine}
                  disabled={detectionsLevel !== "word" || !moveSelectedBboxDownEnabled}
                >
                  <KeyboardArrowDownIcon color={moveSelectedBboxDownEnabled ? "primary" : "error"} />
                </IconButton>
              }
            />
            <MuiButtonWithTooltip
              tooltipTitle="Move selected bbox left in line"
              buttonComponent={
                <IconButton
                  onClick={moveSelectedBboxLeft}
                  disabled={detectionsLevel !== "word" || !moveSelectedBboxLeftEnabled}
                >
                  <KeyboardArrowLeftIcon color={moveSelectedBboxLeftEnabled ? "primary" : "error"} />
                </IconButton>
              }
            />
            <MuiButtonWithTooltip
              tooltipTitle="Move selected bbox right in line"
              buttonComponent={
                <IconButton
                  onClick={moveSelectedBboxRight}
                  disabled={detectionsLevel !== "word" || !moveSelectedBboxRightEnabled}
                >
                  <KeyboardArrowRightIcon color={moveSelectedBboxRightEnabled ? "primary" : "error"} />
                </IconButton>
              }
            />
            <MuiButtonWithTooltip
              tooltipTitle="Put selected bbox in a new line above the current line"
              buttonComponent={
                <IconButton
                  onClick={moveSelectedBboxToNewLineUp}
                  disabled={detectionsLevel !== "word" || !moveSelectedBboxToNewLineUpEnabled}
                >
                  <SplitscreenIcon
                    className={classes.iconCloseMarginRight}
                    color={moveSelectedBboxToNewLineUpEnabled ? "primary" : "error"}
                  />
                  <KeyboardArrowUpIcon color={moveSelectedBboxToNewLineUpEnabled ? "primary" : "error"} />
                </IconButton>
              }
            />
            <MuiButtonWithTooltip
              tooltipTitle="Put selected bbox in a new line below the current line"
              buttonComponent={
                <IconButton
                  onClick={moveSelectedBboxToNewLineDown}
                  disabled={detectionsLevel !== "word" || !moveSelectedBboxToNewLineDownEnabled}
                >
                  <SplitscreenIcon
                    className={classes.iconCloseMarginRight}
                    color={moveSelectedBboxToNewLineDownEnabled ? "primary" : "error"}
                  />
                  <KeyboardArrowDownIcon color={moveSelectedBboxToNewLineDownEnabled ? "primary" : "error"} />
                </IconButton>
              }
            />
          </>
        ) : null}
      </Box>

      {detectionsLevel === "word" ? (
        <List
          ref={verticalListRef}
          style={{
            width: "98%",
            height: "60vh",
            overflow: "auto",
          }}
        >
          {parsedDetections.map((lineDetections, lineIndex) => (
            <div
              style={{
                width: "100%",
                overflowX: "auto",
                whiteSpace: "nowrap",
                marginBottom: 25,
              }}
              key={lineIndex}
            >
              <ul
                ref={horizontalListRef}
                style={{
                  listStyleType: "none",
                  margin: 0,
                  padding: 0,
                }}
              >
                {lineDetections.map((wordDetection, wordIndex) => (
                  <li
                    style={{
                      display: "inline-block",
                      marginRight: 10,
                    }}
                    key={wordIndex}
                  >
                    <TextField
                      className={classes.wordLevelTextDisplayerTextField}
                      size="small"
                      type="text"
                      id={`text-displayer-text-field-${wordDetection.id}`}
                      value={selectedId === wordDetection.id ? selectedDetectionText : wordDetection["text"]}
                      onChange={(event) => setSelectedDetectionText(event.target.value)}
                      onFocus={() => {
                        setSelectedId(wordDetection.id);
                        setSelectedBboxLineIndex(lineIndex);
                        setSelectedBboxWordIndex(wordIndex);
                      }}
                      inputProps={{
                        style: { fontFamily: textDisplayerInputfontFamily },
                      }}
                      sx={{
                        bgcolor: selectedId === wordDetection.id ? "#FFD580" : "#FFFFFF",
                        input: {
                          textAlign: textAlign,
                        },
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </List>
      ) : (
        <List
          ref={verticalListRef}
          style={{
            width: "98%",
            height: "60vh",
            overflow: "auto",
          }}
        >
          {parsedDetections.map((lineDetections, lineIndex) => (
            <div
              style={{
                width: "100%",
                overflowX: "auto",
                whiteSpace: "nowrap",
                marginBottom: 25,
              }}
              key={lineIndex}
            >
              <TextField
                className={classes.lineLevelTextDisplayerTextField}
                size="small"
                type="text"
                id={`text-displayer-text-field-${lineDetections[0].id}`}
                value={selectedId === lineDetections[0].id ? selectedDetectionText : lineDetections[0]["text"]}
                onChange={(event) => setSelectedDetectionText(event.target.value)}
                onFocus={() => {
                  setSelectedId(lineDetections[0].id);
                  setSelectedBboxLineIndex(lineIndex);
                  setSelectedBboxWordIndex(0);
                }}
                inputProps={{
                  style: { fontFamily: textDisplayerInputfontFamily },
                }}
                sx={{
                  bgcolor: selectedId === lineDetections[0].id ? "#FFD580" : "#FFFFFF",
                  input: {
                    textAlign: textAlign,
                  },
                }}
              />
            </div>
          ))}
        </List>
      )}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  wordLevelTextDisplayerTextField: {
    width: 100,
  },
  lineLevelTextDisplayerTextField: {
    width: "80%",
  },
  iconCloseMarginRight: {
    marginRight: -8,
  },
}));

const textAlignRightLanguages = ["urdu"];
